export const gdp22 = {
  "Argentina": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 1635,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1753,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 2198,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 2380,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 2578,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 2873,
     "1876": 2877,
     "1877": 3108,
     "1878": 2887,
     "1879": 2936,
     "1880": 2818,
     "1881": 2801,
     "1882": 3426,
     "1883": 3716,
     "1884": 3842,
     "1885": 4302,
     "1886": 4156,
     "1887": 4233,
     "1888": 4649,
     "1889": 4725,
     "1890": 4245,
     "1891": 4002,
     "1892": 4684,
     "1893": 4858,
     "1894": 5476,
     "1895": 5933,
     "1896": 6299,
     "1897": 4958,
     "1898": 5224,
     "1899": 5963,
     "1900": 5051,
     "1901": 5060,
     "1902": 4772,
     "1903": 5256,
     "1904": 5605,
     "1905": 6110,
     "1906": 6180,
     "1907": 6077,
     "1908": 6424,
     "1909": 6497,
     "1910": 6713,
     "1911": 6580,
     "1912": 6857,
     "1913": 6670,
     "1914": 5800,
     "1915": 5698,
     "1916": 5430,
     "1917": 4901,
     "1918": 5705,
     "1919": 5810,
     "1920": 6101,
     "1921": 6097,
     "1922": 6386,
     "1923": 6847,
     "1924": 7123,
     "1925": 6885,
     "1926": 7016,
     "1927": 7300,
     "1928": 7538,
     "1929": 7671,
     "1930": 7166,
     "1931": 6520,
     "1932": 6186,
     "1933": 6361,
     "1934": 6754,
     "1935": 6938,
     "1936": 6872,
     "1937": 7246,
     "1938": 7152,
     "1939": 7286,
     "1940": 7310,
     "1941": 7560,
     "1942": 7525,
     "1943": 7346,
     "1944": 8043,
     "1945": 7652,
     "1946": 8195,
     "1947": 8940,
     "1948": 9225,
     "1949": 8866,
     "1950": 8759,
     "1951": 8911,
     "1952": 8286,
     "1953": 8562,
     "1954": 8747,
     "1955": 9199,
     "1956": 9284,
     "1957": 9592,
     "1958": 10009,
     "1959": 9207,
     "1960": 9765,
     "1961": 10297,
     "1962": 9972,
     "1963": 9583,
     "1964": 10409,
     "1965": 11190,
     "1966": 11102,
     "1967": 11240,
     "1968": 11554,
     "1969": 12361,
     "1970": 12826,
     "1971": 13227,
     "1972": 13410,
     "1973": 13986,
     "1974": 14639,
     "1975": 14268,
     "1976": 13991,
     "1977": 14587,
     "1978": 13714,
     "1979": 14451,
     "1980": 14414,
     "1981": 13361,
     "1982": 12727,
     "1983": 12976,
     "1984": 13044,
     "1985": 12006,
     "1986": 12689,
     "1987": 12819,
     "1988": 12391,
     "1989": 11453,
     "1990": 11300,
     "1991": 12311,
     "1992": 13394,
     "1993": 14049,
     "1994": 14682,
     "1995": 14091,
     "1996": 14700,
     "1997": 15708,
     "1998": 16127,
     "1999": 15409,
     "2000": 15119,
     "2001": 14298,
     "2002": 12609,
     "2003": 13582,
     "2004": 14650,
     "2005": 15776,
     "2006": 16876,
     "2007": 18226,
     "2008": 18779,
     "2009": 17490,
     "2010": 19068,
     "2011": 20003,
     "2012": 19599,
     "2013": 19873,
     "2014": 19183,
     "2015": 19502,
     "2016": 18875
  },
  "Australia": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 941,
     "1821": 950,
     "1822": 977,
     "1823": 1011,
     "1824": 1067,
     "1825": 1118,
     "1826": 1140,
     "1827": 1174,
     "1828": 1204,
     "1829": 1271,
     "1830": 1542,
     "1831": 1593,
     "1832": 1629,
     "1833": 1635,
     "1834": 1719,
     "1835": 2081,
     "1836": 2045,
     "1837": 2143,
     "1838": 2117,
     "1839": 1975,
     "1840": 2496,
     "1841": 2129,
     "1842": 1942,
     "1843": 2252,
     "1844": 2559,
     "1845": 2633,
     "1846": 2911,
     "1847": 3367,
     "1848": 3853,
     "1849": 3807,
     "1850": 3589,
     "1851": 4259,
     "1852": 4995,
     "1853": 5469,
     "1854": 4789,
     "1855": 4545,
     "1856": 5546,
     "1857": 4894,
     "1858": 4174,
     "1859": 5388,
     "1860": 5259,
     "1861": 5179,
     "1862": 4977,
     "1863": 4961,
     "1864": 5261,
     "1865": 5018,
     "1866": 5166,
     "1867": 5648,
     "1868": 5735,
     "1869": 5621,
     "1870": 5947,
     "1871": 5993,
     "1872": 6456,
     "1873": 6947,
     "1874": 6967,
     "1875": 7518,
     "1876": 7281,
     "1877": 7333,
     "1878": 7771,
     "1879": 7640,
     "1880": 7786,
     "1881": 8094,
     "1882": 7381,
     "1883": 8131,
     "1884": 7819,
     "1885": 8035,
     "1886": 7866,
     "1887": 8415,
     "1888": 8183,
     "1889": 8624,
     "1890": 8099,
     "1891": 8478,
     "1892": 7259,
     "1893": 6737,
     "1894": 6841,
     "1895": 6336,
     "1896": 6695,
     "1897": 6214,
     "1898": 7070,
     "1899": 6976,
     "1900": 7292,
     "1901": 6974,
     "1902": 6945,
     "1903": 7406,
     "1904": 7803,
     "1905": 7780,
     "1906": 8189,
     "1907": 8387,
     "1908": 8527,
     "1909": 9049,
     "1910": 9465,
     "1911": 9273,
     "1912": 9263,
     "1913": 9369,
     "1914": 9133,
     "1915": 8896,
     "1916": 8863,
     "1917": 8705,
     "1918": 8360,
     "1919": 8567,
     "1920": 8658,
     "1921": 8922,
     "1922": 9200,
     "1923": 9433,
     "1924": 9843,
     "1925": 10089,
     "1926": 10124,
     "1927": 10072,
     "1928": 9905,
     "1929": 9562,
     "1930": 8554,
     "1931": 7910,
     "1932": 8292,
     "1933": 8798,
     "1934": 9193,
     "1935": 9661,
     "1936": 10021,
     "1937": 10439,
     "1938": 10695,
     "1939": 10621,
     "1940": 11203,
     "1941": 12333,
     "1942": 13636,
     "1943": 13995,
     "1944": 13376,
     "1945": 12567,
     "1946": 11982,
     "1947": 12108,
     "1948": 12659,
     "1949": 13148,
     "1950": 13466,
     "1951": 13640,
     "1952": 13477,
     "1953": 13636,
     "1954": 14154,
     "1955": 14585,
     "1956": 14732,
     "1957": 14699,
     "1958": 15090,
     "1959": 15677,
     "1960": 15972,
     "1961": 15721,
     "1962": 16400,
     "1963": 17078,
     "1964": 17894,
     "1965": 18444,
     "1966": 18607,
     "1967": 19500,
     "1968": 20255,
     "1969": 21005,
     "1970": 21845,
     "1971": 22328,
     "1972": 22537,
     "1973": 23398,
     "1974": 23592,
     "1975": 23928,
     "1976": 24634,
     "1977": 24612,
     "1978": 25016,
     "1979": 26016,
     "1980": 26184,
     "1981": 26636,
     "1982": 26184,
     "1983": 25870,
     "1984": 27366,
     "1985": 28413,
     "1986": 28628,
     "1987": 29602,
     "1988": 30436,
     "1989": 31240,
     "1990": 31200,
     "1991": 30426,
     "1992": 31024,
     "1993": 32020,
     "1994": 33180,
     "1995": 33908,
     "1996": 34850,
     "1997": 35853,
     "1998": 37235,
     "1999": 38311,
     "2000": 39200,
     "2001": 39673,
     "2002": 40792,
     "2003": 41544,
     "2004": 42711,
     "2005": 43501,
     "2006": 44001,
     "2007": 45145,
     "2008": 45260,
     "2009": 45247,
     "2010": 45684,
     "2011": 46132,
     "2012": 46999,
     "2013": 47250,
     "2014": 47867,
     "2015": 48357,
     "2016": 48845
  },
  "Austria": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 2112,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 2425,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 2626,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 2861,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 3082,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 3229,
     "1871": 3431,
     "1872": 3427,
     "1873": 3317,
     "1874": 3435,
     "1875": 3421,
     "1876": 3468,
     "1877": 3555,
     "1878": 3642,
     "1879": 3585,
     "1880": 3604,
     "1881": 3719,
     "1882": 3710,
     "1883": 3830,
     "1884": 3898,
     "1885": 3841,
     "1886": 3932,
     "1887": 4169,
     "1888": 4125,
     "1889": 4052,
     "1890": 4236,
     "1891": 4345,
     "1892": 4395,
     "1893": 4378,
     "1894": 4585,
     "1895": 4660,
     "1896": 4683,
     "1897": 4734,
     "1898": 4951,
     "1899": 5004,
     "1900": 4997,
     "1901": 4965,
     "1902": 5107,
     "1903": 5099,
     "1904": 5125,
     "1905": 5357,
     "1906": 5507,
     "1907": 5788,
     "1908": 5756,
     "1909": 5680,
     "1910": 5705,
     "1911": 5835,
     "1912": 6077,
     "1913": 6009,
     "1914": 4986,
     "1915": 4600,
     "1916": 4556,
     "1917": 4484,
     "1918": 4430,
     "1919": 3917,
     "1920": 4183,
     "1921": 4595,
     "1922": 4989,
     "1923": 4928,
     "1924": 5484,
     "1925": 5838,
     "1926": 5918,
     "1927": 6078,
     "1928": 6341,
     "1929": 6413,
     "1930": 6217,
     "1931": 5700,
     "1932": 5097,
     "1933": 4912,
     "1934": 4944,
     "1935": 5040,
     "1936": 5192,
     "1937": 5471,
     "1938": 6172,
     "1939": 7102,
     "1940": 6865,
     "1941": 7312,
     "1942": 6906,
     "1943": 7047,
     "1944": 7199,
     "1945": 2990,
     "1946": 3392,
     "1947": 3756,
     "1948": 4793,
     "1949": 5710,
     "1950": 6426,
     "1951": 6865,
     "1952": 6878,
     "1953": 7173,
     "1954": 7897,
     "1955": 8762,
     "1956": 9357,
     "1957": 9911,
     "1958": 10241,
     "1959": 10492,
     "1960": 11302,
     "1961": 11837,
     "1962": 12049,
     "1963": 12460,
     "1964": 13120,
     "1965": 13410,
     "1966": 14065,
     "1967": 14385,
     "1968": 14947,
     "1969": 15832,
     "1970": 16900,
     "1971": 17685,
     "1972": 18674,
     "1973": 19479,
     "1974": 20213,
     "1975": 20193,
     "1976": 21154,
     "1977": 22135,
     "1978": 22073,
     "1979": 23317,
     "1980": 23856,
     "1981": 23785,
     "1982": 24206,
     "1983": 24957,
     "1984": 25035,
     "1985": 25578,
     "1986": 26148,
     "1987": 26550,
     "1988": 27315,
     "1989": 28365,
     "1990": 29292,
     "1991": 29999,
     "1992": 30292,
     "1993": 30202,
     "1994": 30808,
     "1995": 31582,
     "1996": 32296,
     "1997": 32971,
     "1998": 34107,
     "1999": 35263,
     "2000": 36363,
     "2001": 36713,
     "2002": 37138,
     "2003": 37237,
     "2004": 38008,
     "2005": 38558,
     "2006": 39653,
     "2007": 40956,
     "2008": 41460,
     "2009": 39781,
     "2010": 40450,
     "2011": 41446,
     "2012": 41565,
     "2013": 41375,
     "2014": 41338,
     "2015": 41294,
     "2016": 41445
  },
  "Belgium": {
     "1": 1050,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 2407,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 2480,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 2607,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 2371,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 2255,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 2233,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 2426,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 2779,
     "1847": 2890,
     "1848": 2877,
     "1849": 2937,
     "1850": 3029,
     "1851": 3093,
     "1852": 3156,
     "1853": 3218,
     "1854": 3395,
     "1855": 3397,
     "1856": 3559,
     "1857": 3628,
     "1858": 3628,
     "1859": 3605,
     "1860": 3761,
     "1861": 3790,
     "1862": 3861,
     "1863": 3943,
     "1864": 4045,
     "1865": 4016,
     "1866": 4105,
     "1867": 4097,
     "1868": 4215,
     "1869": 4368,
     "1870": 4415,
     "1871": 4400,
     "1872": 4632,
     "1873": 4626,
     "1874": 4740,
     "1875": 4693,
     "1876": 4716,
     "1877": 4732,
     "1878": 4827,
     "1879": 4831,
     "1880": 5028,
     "1881": 5036,
     "1882": 5144,
     "1883": 5158,
     "1884": 5145,
     "1885": 5147,
     "1886": 5173,
     "1887": 5331,
     "1888": 5327,
     "1889": 5542,
     "1890": 5623,
     "1891": 5570,
     "1892": 5646,
     "1893": 5668,
     "1894": 5689,
     "1895": 5760,
     "1896": 5826,
     "1897": 5883,
     "1898": 5930,
     "1899": 5998,
     "1900": 6121,
     "1901": 6101,
     "1902": 6134,
     "1903": 6188,
     "1904": 6268,
     "1905": 6368,
     "1906": 6426,
     "1907": 6450,
     "1908": 6451,
     "1909": 6514,
     "1910": 6666,
     "1911": 6805,
     "1912": 6900,
     "1913": 6922,
     "1914": 6436,
     "1915": 6329,
     "1916": 6694,
     "1917": 5773,
     "1918": 4694,
     "1919": 5560,
     "1920": 6499,
     "1921": 6654,
     "1922": 7240,
     "1923": 7436,
     "1924": 7608,
     "1925": 7654,
     "1926": 7847,
     "1927": 8076,
     "1928": 8431,
     "1929": 8291,
     "1930": 8167,
     "1931": 7973,
     "1932": 7558,
     "1933": 7678,
     "1934": 7585,
     "1935": 8029,
     "1936": 8060,
     "1937": 8138,
     "1938": 7927,
     "1939": 8448,
     "1940": 7483,
     "1941": 7149,
     "1942": 6556,
     "1943": 6409,
     "1944": 6746,
     "1945": 7108,
     "1946": 7503,
     "1947": 7875,
     "1948": 8241,
     "1949": 8519,
     "1950": 8960,
     "1951": 9427,
     "1952": 9298,
     "1953": 9544,
     "1954": 9890,
     "1955": 10302,
     "1956": 10536,
     "1957": 10654,
     "1958": 10567,
     "1959": 10840,
     "1960": 11404,
     "1961": 11898,
     "1962": 12440,
     "1963": 12898,
     "1964": 13682,
     "1965": 14041,
     "1966": 14396,
     "1967": 14882,
     "1968": 15446,
     "1969": 16434,
     "1970": 17406,
     "1971": 17995,
     "1972": 18869,
     "1973": 19964,
     "1974": 20740,
     "1975": 20408,
     "1976": 21526,
     "1977": 21637,
     "1978": 22234,
     "1979": 22737,
     "1980": 23733,
     "1981": 23423,
     "1982": 23744,
     "1983": 23743,
     "1984": 24332,
     "1985": 24569,
     "1986": 24927,
     "1987": 25494,
     "1988": 26660,
     "1989": 27467,
     "1990": 28210,
     "1991": 28621,
     "1992": 28941,
     "1993": 28551,
     "1994": 29382,
     "1995": 30020,
     "1996": 30439,
     "1997": 31492,
     "1998": 32045,
     "1999": 33111,
     "2000": 34231,
     "2001": 34391,
     "2002": 34846,
     "2003": 34969,
     "2004": 36084,
     "2005": 36638,
     "2006": 37306,
     "2007": 38292,
     "2008": 38274,
     "2009": 37100,
     "2010": 37791,
     "2011": 38130,
     "2012": 37906,
     "2013": 37737,
     "2014": 38228,
     "2015": 38569,
     "2016": 38766
  },
  "Brazil": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 1123,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1123,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 1123,
     "1851": 1200,
     "1852": 1168,
     "1853": 1096,
     "1854": 1058,
     "1855": 1069,
     "1856": 1107,
     "1857": 1185,
     "1858": 1261,
     "1859": 1251,
     "1860": 1284,
     "1861": 1273,
     "1862": 1212,
     "1863": 1240,
     "1864": 1257,
     "1865": 1350,
     "1866": 1399,
     "1867": 1516,
     "1868": 1516,
     "1869": 1516,
     "1870": 1405,
     "1871": 1405,
     "1872": 1454,
     "1873": 1411,
     "1874": 1432,
     "1875": 1454,
     "1876": 1399,
     "1877": 1360,
     "1878": 1427,
     "1879": 1438,
     "1880": 1372,
     "1881": 1383,
     "1882": 1417,
     "1883": 1378,
     "1884": 1471,
     "1885": 1366,
     "1886": 1411,
     "1887": 1317,
     "1888": 1267,
     "1889": 1284,
     "1890": 1405,
     "1891": 1493,
     "1892": 1294,
     "1893": 1102,
     "1894": 1102,
     "1895": 1306,
     "1896": 1185,
     "1897": 1168,
     "1898": 1195,
     "1899": 1173,
     "1900": 1133,
     "1901": 1218,
     "1902": 1193,
     "1903": 1192,
     "1904": 1190,
     "1905": 1199,
     "1906": 1286,
     "1907": 1261,
     "1908": 1225,
     "1909": 1296,
     "1910": 1283,
     "1911": 1395,
     "1912": 1351,
     "1913": 1354,
     "1914": 1302,
     "1915": 1332,
     "1916": 1342,
     "1917": 1405,
     "1918": 1348,
     "1919": 1494,
     "1920": 1608,
     "1921": 1608,
     "1922": 1684,
     "1923": 1745,
     "1924": 1709,
     "1925": 1682,
     "1926": 1683,
     "1927": 1769,
     "1928": 1933,
     "1929": 1899,
     "1930": 1750,
     "1931": 1676,
     "1932": 1700,
     "1933": 1796,
     "1934": 1907,
     "1935": 1920,
     "1936": 2062,
     "1937": 2086,
     "1938": 2130,
     "1939": 2108,
     "1940": 2086,
     "1941": 2182,
     "1942": 2052,
     "1943": 2284,
     "1944": 2314,
     "1945": 2320,
     "1946": 2506,
     "1947": 2534,
     "1948": 2715,
     "1949": 2856,
     "1950": 2898,
     "1951": 2954,
     "1952": 3080,
     "1953": 3132,
     "1954": 3280,
     "1955": 3465,
     "1956": 3462,
     "1957": 3619,
     "1958": 3892,
     "1959": 4148,
     "1960": 4404,
     "1961": 4644,
     "1962": 4807,
     "1963": 4695,
     "1964": 4713,
     "1965": 4686,
     "1966": 4855,
     "1967": 4916,
     "1968": 5248,
     "1969": 5589,
     "1970": 6005,
     "1971": 6510,
     "1972": 7099,
     "1973": 7885,
     "1974": 8313,
     "1975": 8526,
     "1976": 9171,
     "1977": 9391,
     "1978": 9619,
     "1979": 10022,
     "1980": 10687,
     "1981": 9986,
     "1982": 9829,
     "1983": 9332,
     "1984": 9636,
     "1985": 10185,
     "1986": 10729,
     "1987": 10885,
     "1988": 10662,
     "1989": 10797,
     "1990": 10161,
     "1991": 10094,
     "1992": 9876,
     "1993": 10197,
     "1994": 10625,
     "1995": 10905,
     "1996": 10979,
     "1997": 11184,
     "1998": 11057,
     "1999": 10948,
     "2000": 11263,
     "2001": 11260,
     "2002": 11448,
     "2003": 11428,
     "2004": 11937,
     "2005": 12174,
     "2006": 12515,
     "2007": 13132,
     "2008": 13660,
     "2009": 13510,
     "2010": 14392,
     "2011": 14831,
     "2012": 14985,
     "2013": 15306,
     "2014": 15258,
     "2015": 15826,
     "2016": 13873
  },
  "Canada": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1530,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 1691,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 1965,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 2249,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 2453,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 2866,
     "1871": 2968,
     "1872": 2884,
     "1873": 3115,
     "1874": 3135,
     "1875": 3017,
     "1876": 2775,
     "1877": 2922,
     "1878": 2769,
     "1879": 2986,
     "1880": 3071,
     "1881": 3450,
     "1882": 3567,
     "1883": 3536,
     "1884": 3773,
     "1885": 3506,
     "1886": 3510,
     "1887": 3586,
     "1888": 3804,
     "1889": 3787,
     "1890": 4023,
     "1891": 4074,
     "1892": 4010,
     "1893": 3947,
     "1894": 4094,
     "1895": 4010,
     "1896": 3871,
     "1897": 4248,
     "1898": 4370,
     "1899": 4728,
     "1900": 4924,
     "1901": 5238,
     "1902": 5634,
     "1903": 5638,
     "1904": 5547,
     "1905": 6024,
     "1906": 6505,
     "1907": 6554,
     "1908": 6042,
     "1909": 6511,
     "1910": 6877,
     "1911": 7125,
     "1912": 7403,
     "1913": 7521,
     "1914": 6808,
     "1915": 7159,
     "1916": 7858,
     "1917": 8120,
     "1918": 7511,
     "1919": 6798,
     "1920": 6531,
     "1921": 5678,
     "1922": 6416,
     "1923": 6725,
     "1924": 6727,
     "1925": 7341,
     "1926": 7606,
     "1927": 8198,
     "1928": 8747,
     "1929": 8568,
     "1930": 8137,
     "1931": 6772,
     "1932": 6210,
     "1933": 5701,
     "1934": 6243,
     "1935": 6682,
     "1936": 6976,
     "1937": 7566,
     "1938": 7689,
     "1939": 8065,
     "1940": 9080,
     "1941": 10234,
     "1942": 11896,
     "1943": 12284,
     "1944": 12588,
     "1945": 12065,
     "1946": 11723,
     "1947": 11989,
     "1948": 11950,
     "1949": 11948,
     "1950": 12333,
     "1951": 12742,
     "1952": 13249,
     "1953": 13504,
     "1954": 13023,
     "1955": 13872,
     "1956": 14634,
     "1957": 14557,
     "1958": 14434,
     "1959": 14675,
     "1960": 14804,
     "1961": 14940,
     "1962": 15691,
     "1963": 16180,
     "1964": 16913,
     "1965": 17714,
     "1966": 18514,
     "1967": 18738,
     "1968": 19416,
     "1969": 20148,
     "1970": 20382,
     "1971": 21247,
     "1972": 22111,
     "1973": 23405,
     "1974": 24026,
     "1975": 24214,
     "1976": 25206,
     "1977": 25747,
     "1978": 26521,
     "1979": 27350,
     "1980": 27359,
     "1981": 27861,
     "1982": 26689,
     "1983": 27191,
     "1984": 28476,
     "1985": 29738,
     "1986": 30212,
     "1987": 31034,
     "1988": 32125,
     "1989": 32320,
     "1990": 31921,
     "1991": 30846,
     "1992": 30748,
     "1993": 31204,
     "1994": 32258,
     "1995": 32779,
     "1996": 32961,
     "1997": 34029,
     "1998": 35050,
     "1999": 36564,
     "2000": 38108,
     "2001": 38376,
     "2002": 39106,
     "2003": 39441,
     "2004": 40280,
     "2005": 41177,
     "2006": 41834,
     "2007": 42282,
     "2008": 42253,
     "2009": 40541,
     "2010": 41326,
     "2011": 42197,
     "2012": 42445,
     "2013": 42994,
     "2014": 43607,
     "2015": 43619,
     "2016": 43745
  },
  "Chile": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 744,
     "1811": 711,
     "1812": 699,
     "1813": 705,
     "1814": 709,
     "1815": 712,
     "1816": 689,
     "1817": 681,
     "1818": 718,
     "1819": 687,
     "1820": 718,
     "1821": 702,
     "1822": 724,
     "1823": 696,
     "1824": 685,
     "1825": 744,
     "1826": 771,
     "1827": 788,
     "1828": 794,
     "1829": 814,
     "1830": 806,
     "1831": 810,
     "1832": 834,
     "1833": 838,
     "1834": 857,
     "1835": 875,
     "1836": 865,
     "1837": 897,
     "1838": 901,
     "1839": 892,
     "1840": 942,
     "1841": 942,
     "1842": 950,
     "1843": 968,
     "1844": 974,
     "1845": 996,
     "1846": 1021,
     "1847": 1031,
     "1848": 1071,
     "1849": 1132,
     "1850": 1178,
     "1851": 1214,
     "1852": 1224,
     "1853": 1190,
     "1854": 1209,
     "1855": 1243,
     "1856": 1244,
     "1857": 1274,
     "1858": 1320,
     "1859": 1360,
     "1860": 1384,
     "1861": 1373,
     "1862": 1354,
     "1863": 1392,
     "1864": 1454,
     "1865": 1492,
     "1866": 1526,
     "1867": 1452,
     "1868": 1494,
     "1869": 1613,
     "1870": 1629,
     "1871": 1601,
     "1872": 1665,
     "1873": 1766,
     "1874": 1704,
     "1875": 1787,
     "1876": 1733,
     "1877": 1664,
     "1878": 1703,
     "1879": 1935,
     "1880": 2108,
     "1881": 2170,
     "1882": 2349,
     "1883": 2340,
     "1884": 2357,
     "1885": 2221,
     "1886": 2290,
     "1887": 2427,
     "1888": 2282,
     "1889": 2362,
     "1890": 2535,
     "1891": 2649,
     "1892": 2620,
     "1893": 2689,
     "1894": 2596,
     "1895": 2882,
     "1896": 2946,
     "1897": 2800,
     "1898": 3147,
     "1899": 3023,
     "1900": 2953,
     "1901": 2979,
     "1902": 3142,
     "1903": 2949,
     "1904": 3180,
     "1905": 3026,
     "1906": 3198,
     "1907": 3322,
     "1908": 3506,
     "1909": 3525,
     "1910": 3911,
     "1911": 3907,
     "1912": 4192,
     "1913": 4217,
     "1914": 3550,
     "1915": 3324,
     "1916": 4012,
     "1917": 4029,
     "1918": 4013,
     "1919": 3360,
     "1920": 3705,
     "1921": 3250,
     "1922": 3327,
     "1923": 3936,
     "1924": 4167,
     "1925": 4276,
     "1926": 4101,
     "1927": 3964,
     "1928": 4891,
     "1929": 4953,
     "1930": 4252,
     "1931": 3404,
     "1932": 2573,
     "1933": 3155,
     "1934": 3722,
     "1935": 3933,
     "1936": 3998,
     "1937": 4433,
     "1938": 4422,
     "1939": 4452,
     "1940": 4515,
     "1941": 4439,
     "1942": 4501,
     "1943": 4543,
     "1944": 4543,
     "1945": 4844,
     "1946": 5162,
     "1947": 4523,
     "1948": 5180,
     "1949": 4980,
     "1950": 5128,
     "1951": 5234,
     "1952": 5452,
     "1953": 5739,
     "1954": 5439,
     "1955": 5530,
     "1956": 5480,
     "1957": 5893,
     "1958": 6067,
     "1959": 5589,
     "1960": 5913,
     "1961": 6038,
     "1962": 6166,
     "1963": 6396,
     "1964": 6383,
     "1965": 6286,
     "1966": 6841,
     "1967": 6918,
     "1968": 7022,
     "1969": 7140,
     "1970": 7146,
     "1971": 7651,
     "1972": 7430,
     "1973": 6899,
     "1974": 6851,
     "1975": 5870,
     "1976": 5989,
     "1977": 6486,
     "1978": 6921,
     "1979": 7390,
     "1980": 7869,
     "1981": 8221,
     "1982": 6990,
     "1983": 6687,
     "1984": 6970,
     "1985": 6998,
     "1986": 7260,
     "1987": 7605,
     "1988": 8022,
     "1989": 8725,
     "1990": 8898,
     "1991": 9433,
     "1992": 10402,
     "1993": 10935,
     "1994": 11362,
     "1995": 12357,
     "1996": 13089,
     "1997": 13765,
     "1998": 14019,
     "1999": 13729,
     "2000": 14159,
     "2001": 14473,
     "2002": 14626,
     "2003": 15006,
     "2004": 15744,
     "2005": 16480,
     "2006": 17346,
     "2007": 18012,
     "2008": 18440,
     "2009": 17949,
     "2010": 18787,
     "2011": 19705,
     "2012": 20531,
     "2013": 21135,
     "2014": 21335,
     "2015": 21589,
     "2016": 21696
  },
  "China": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 1083,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 791,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 718,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 854,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 684,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 754,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 727,
     "1888": 0,
     "1889": 0,
     "1890": 769,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 776,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 722,
     "1912": 0,
     "1913": 786,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 800,
     "1930": 807,
     "1931": 810,
     "1932": 829,
     "1933": 736,
     "1934": 747,
     "1935": 804,
     "1936": 850,
     "1937": 825,
     "1938": 800,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 637,
     "1951": 758,
     "1952": 835,
     "1953": 924,
     "1954": 836,
     "1955": 892,
     "1956": 949,
     "1957": 953,
     "1958": 935,
     "1959": 891,
     "1960": 843,
     "1961": 697,
     "1962": 738,
     "1963": 825,
     "1964": 920,
     "1965": 999,
     "1966": 1006,
     "1967": 987,
     "1968": 939,
     "1969": 1008,
     "1970": 1115,
     "1971": 1154,
     "1972": 1142,
     "1973": 1207,
     "1974": 1211,
     "1975": 1272,
     "1976": 1211,
     "1977": 1262,
     "1978": 1392,
     "1979": 1483,
     "1980": 1539,
     "1981": 1565,
     "1982": 1697,
     "1983": 1776,
     "1984": 1949,
     "1985": 2089,
     "1986": 2193,
     "1987": 2339,
     "1988": 2429,
     "1989": 2410,
     "1990": 2379,
     "1991": 2460,
     "1992": 2631,
     "1993": 2889,
     "1994": 3107,
     "1995": 3367,
     "1996": 3590,
     "1997": 3708,
     "1998": 3747,
     "1999": 3926,
     "2000": 4202,
     "2001": 4485,
     "2002": 4850,
     "2003": 5183,
     "2004": 5627,
     "2005": 6212,
     "2006": 6890,
     "2007": 7563,
     "2008": 7930,
     "2009": 8548,
     "2010": 9555,
     "2011": 10221,
     "2012": 10680,
     "2013": 11328,
     "2014": 11944,
     "2015": 12244,
     "2016": 12569
  },
  "Colombia": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 937,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 845,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 780,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 908,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1072,
     "1871": 1078,
     "1872": 1083,
     "1873": 1088,
     "1874": 1094,
     "1875": 1099,
     "1876": 1105,
     "1877": 1110,
     "1878": 1116,
     "1879": 1121,
     "1880": 1127,
     "1881": 1133,
     "1882": 1138,
     "1883": 1144,
     "1884": 1150,
     "1885": 1155,
     "1886": 1161,
     "1887": 1155,
     "1888": 1150,
     "1889": 1144,
     "1890": 1138,
     "1891": 1132,
     "1892": 1127,
     "1893": 1121,
     "1894": 1116,
     "1895": 1110,
     "1896": 1104,
     "1897": 1099,
     "1898": 1093,
     "1899": 1088,
     "1900": 1083,
     "1901": 1077,
     "1902": 1072,
     "1903": 1066,
     "1904": 1061,
     "1905": 1056,
     "1906": 1065,
     "1907": 1114,
     "1908": 1154,
     "1909": 1192,
     "1910": 1236,
     "1911": 1272,
     "1912": 1310,
     "1913": 1341,
     "1914": 1365,
     "1915": 1401,
     "1916": 1445,
     "1917": 1475,
     "1918": 1527,
     "1919": 1621,
     "1920": 1698,
     "1921": 1760,
     "1922": 1842,
     "1923": 1921,
     "1924": 1990,
     "1925": 1990,
     "1926": 2126,
     "1927": 2258,
     "1928": 2363,
     "1929": 2386,
     "1930": 2338,
     "1931": 2296,
     "1932": 2396,
     "1933": 2501,
     "1934": 2420,
     "1935": 2659,
     "1936": 2767,
     "1937": 2777,
     "1938": 2923,
     "1939": 3021,
     "1940": 3006,
     "1941": 2977,
     "1942": 2905,
     "1943": 2842,
     "1944": 2954,
     "1945": 3012,
     "1946": 3199,
     "1947": 3239,
     "1948": 3252,
     "1949": 3342,
     "1950": 3414,
     "1951": 3410,
     "1952": 3512,
     "1953": 3611,
     "1954": 3741,
     "1955": 3763,
     "1956": 3792,
     "1957": 3806,
     "1958": 3779,
     "1959": 3923,
     "1960": 3960,
     "1961": 4028,
     "1962": 4114,
     "1963": 4119,
     "1964": 4242,
     "1965": 4265,
     "1966": 4362,
     "1967": 4416,
     "1968": 4558,
     "1969": 4721,
     "1970": 4908,
     "1971": 5066,
     "1972": 5322,
     "1973": 5550,
     "1974": 5738,
     "1975": 5742,
     "1976": 5889,
     "1977": 6016,
     "1978": 6410,
     "1979": 6625,
     "1980": 6752,
     "1981": 6748,
     "1982": 6665,
     "1983": 6622,
     "1984": 6707,
     "1985": 6775,
     "1986": 7035,
     "1987": 7260,
     "1988": 7406,
     "1989": 7496,
     "1990": 7654,
     "1991": 7676,
     "1992": 7853,
     "1993": 8146,
     "1994": 8413,
     "1995": 8703,
     "1996": 8740,
     "1997": 8922,
     "1998": 8836,
     "1999": 8358,
     "2000": 8475,
     "2001": 8508,
     "2002": 8612,
     "2003": 8838,
     "2004": 9196,
     "2005": 9511,
     "2006": 10028,
     "2007": 10592,
     "2008": 10839,
     "2009": 10889,
     "2010": 11189,
     "2011": 11788,
     "2012": 12123,
     "2013": 12568,
     "2014": 12972,
     "2015": 13218,
     "2016": 13326
  },
  "Czechoslovakia": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1592,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 2023,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 2182,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 2822,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 3242,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 3733,
     "1911": 0,
     "1912": 0,
     "1913": 3929,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 3625,
     "1921": 3909,
     "1922": 3761,
     "1923": 4032,
     "1924": 4411,
     "1925": 4886,
     "1926": 4829,
     "1927": 5159,
     "1928": 5582,
     "1929": 5704,
     "1930": 5486,
     "1931": 5266,
     "1932": 5024,
     "1933": 4784,
     "1934": 4580,
     "1935": 4518,
     "1936": 4872,
     "1937": 5403,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 5791,
     "1949": 6111,
     "1950": 6563,
     "1951": 6607,
     "1952": 6745,
     "1953": 6645,
     "1954": 6846,
     "1955": 7353,
     "1956": 7706,
     "1957": 8099,
     "1958": 8643,
     "1959": 8962,
     "1960": 9578,
     "1961": 9869,
     "1962": 9944,
     "1963": 9693,
     "1964": 10073,
     "1965": 10373,
     "1966": 10763,
     "1967": 11183,
     "1968": 11667,
     "1969": 11913,
     "1970": 12124,
     "1971": 12484,
     "1972": 12859,
     "1973": 13202,
     "1974": 13580,
     "1975": 13873,
     "1976": 13989,
     "1977": 14519,
     "1978": 14599,
     "1979": 14633,
     "1980": 14966,
     "1981": 14835,
     "1982": 15085,
     "1983": 15275,
     "1984": 15598,
     "1985": 15688,
     "1986": 15950,
     "1987": 16000,
     "1988": 16328,
     "1989": 16439,
     "1990": 15961,
     "1991": 13947,
     "1992": 13601,
     "1993": 13654,
     "1994": 14093,
     "1995": 14916,
     "1996": 15701,
     "1997": 15822,
     "1998": 16017,
     "1999": 16205,
     "2000": 16743,
     "2001": 17286,
     "2002": 17791,
     "2003": 18518,
     "2004": 19412,
     "2005": 20713,
     "2006": 22253,
     "2007": 23873,
     "2008": 24826,
     "2009": 24028,
     "2010": 24764,
     "2011": 25289,
     "2012": 25279,
     "2013": 25329,
     "2014": 25976,
     "2015": 27041,
     "2016": 27738
  },
  "Cuba": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 429,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 700,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 765,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 844,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 1147,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 1162,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 1294,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 1554,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 0,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 1846,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 2531,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 2448,
     "1903": 2561,
     "1904": 2636,
     "1905": 2749,
     "1906": 2786,
     "1907": 3012,
     "1908": 2674,
     "1909": 3012,
     "1910": 3012,
     "1911": 2749,
     "1912": 2824,
     "1913": 3088,
     "1914": 3163,
     "1915": 3125,
     "1916": 3276,
     "1917": 3314,
     "1918": 3351,
     "1919": 3464,
     "1920": 3464,
     "1921": 3351,
     "1922": 3314,
     "1923": 3276,
     "1924": 3389,
     "1925": 3728,
     "1926": 3540,
     "1927": 3502,
     "1928": 3314,
     "1929": 3653,
     "1930": 3464,
     "1931": 2561,
     "1932": 2372,
     "1933": 2259,
     "1934": 2448,
     "1935": 2636,
     "1936": 2786,
     "1937": 2975,
     "1938": 3012,
     "1939": 2975,
     "1940": 3012,
     "1941": 3201,
     "1942": 3201,
     "1943": 3012,
     "1944": 3314,
     "1945": 3314,
     "1946": 3502,
     "1947": 3728,
     "1948": 3653,
     "1949": 3615,
     "1950": 3841,
     "1951": 3878,
     "1952": 4142,
     "1953": 3803,
     "1954": 3803,
     "1955": 3841,
     "1956": 3991,
     "1957": 4255,
     "1958": 4255,
     "1959": 4378,
     "1960": 4360,
     "1961": 4468,
     "1962": 4332,
     "1963": 4309,
     "1964": 4598,
     "1965": 4495,
     "1966": 4637,
     "1967": 5082,
     "1968": 4703,
     "1969": 4569,
     "1970": 4335,
     "1971": 4604,
     "1972": 4738,
     "1973": 5076,
     "1974": 5254,
     "1975": 5436,
     "1976": 5513,
     "1977": 5698,
     "1978": 5990,
     "1979": 6092,
     "1980": 5979,
     "1981": 6438,
     "1982": 6534,
     "1983": 6852,
     "1984": 7356,
     "1985": 7455,
     "1986": 7392,
     "1987": 7117,
     "1988": 7272,
     "1989": 7268,
     "1990": 6864,
     "1991": 5696,
     "1992": 4777,
     "1993": 3668,
     "1994": 3637,
     "1995": 3810,
     "1996": 4251,
     "1997": 4401,
     "1998": 4310,
     "1999": 4492,
     "2000": 4882,
     "2001": 5061,
     "2002": 5133,
     "2003": 5201,
     "2004": 5170,
     "2005": 5248,
     "2006": 5923,
     "2007": 6054,
     "2008": 6336,
     "2009": 6476,
     "2010": 6697,
     "2011": 6928,
     "2012": 7138,
     "2013": 7379,
     "2014": 7628,
     "2015": 7889,
     "2016": 0
  },
  "Germany": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 2315,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 1631,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 1914,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 1897,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 2122,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 1993,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 2884,
     "1851": 2844,
     "1852": 2880,
     "1853": 2855,
     "1854": 2916,
     "1855": 2869,
     "1856": 3094,
     "1857": 3221,
     "1858": 3180,
     "1859": 3162,
     "1860": 3312,
     "1861": 3198,
     "1862": 3323,
     "1863": 3533,
     "1864": 3596,
     "1865": 3575,
     "1866": 3578,
     "1867": 3567,
     "1868": 3760,
     "1869": 3758,
     "1870": 3715,
     "1871": 3670,
     "1872": 3902,
     "1873": 4037,
     "1874": 4291,
     "1875": 4267,
     "1876": 4183,
     "1877": 4106,
     "1878": 4248,
     "1879": 4098,
     "1880": 4023,
     "1881": 4091,
     "1882": 4130,
     "1883": 4330,
     "1884": 4401,
     "1885": 4477,
     "1886": 4467,
     "1887": 4596,
     "1888": 4729,
     "1889": 4806,
     "1890": 4904,
     "1891": 4841,
     "1892": 4988,
     "1893": 5182,
     "1894": 5247,
     "1895": 5426,
     "1896": 5534,
     "1897": 5605,
     "1898": 5753,
     "1899": 5869,
     "1900": 6029,
     "1901": 5800,
     "1902": 5845,
     "1903": 6077,
     "1904": 6229,
     "1905": 6270,
     "1906": 6368,
     "1907": 6555,
     "1908": 6574,
     "1909": 6615,
     "1910": 6763,
     "1911": 6885,
     "1912": 7118,
     "1913": 7369,
     "1914": 6180,
     "1915": 5856,
     "1916": 5928,
     "1917": 5963,
     "1918": 6026,
     "1919": 5225,
     "1920": 5647,
     "1921": 6217,
     "1922": 6729,
     "1923": 5554,
     "1924": 6462,
     "1925": 7134,
     "1926": 7282,
     "1927": 7961,
     "1928": 8262,
     "1929": 8184,
     "1930": 8027,
     "1931": 7376,
     "1932": 6791,
     "1933": 7183,
     "1934": 7794,
     "1935": 8322,
     "1936": 8991,
     "1937": 9464,
     "1938": 10088,
     "1939": 10921,
     "1940": 10914,
     "1941": 11537,
     "1942": 11595,
     "1943": 11898,
     "1944": 12290,
     "1945": 9119,
     "1946": 4478,
     "1947": 4921,
     "1948": 5724,
     "1949": 6631,
     "1950": 7840,
     "1951": 8496,
     "1952": 9197,
     "1953": 9909,
     "1954": 10599,
     "1955": 11710,
     "1956": 12477,
     "1957": 13115,
     "1958": 13609,
     "1959": 14498,
     "1960": 15565,
     "1961": 16064,
     "1962": 16609,
     "1963": 16940,
     "1964": 17822,
     "1965": 18556,
     "1966": 18964,
     "1967": 18982,
     "1968": 19927,
     "1969": 21090,
     "1970": 21896,
     "1971": 22376,
     "1972": 23192,
     "1973": 24173,
     "1974": 24368,
     "1975": 24323,
     "1976": 25623,
     "1977": 26406,
     "1978": 27181,
     "1979": 28268,
     "1980": 28512,
     "1981": 28581,
     "1982": 28361,
     "1983": 28946,
     "1984": 29863,
     "1985": 30584,
     "1986": 31249,
     "1987": 31718,
     "1988": 32645,
     "1989": 33449,
     "1990": 32178,
     "1991": 33545,
     "1992": 33931,
     "1993": 33387,
     "1994": 34089,
     "1995": 34579,
     "1996": 34761,
     "1997": 35352,
     "1998": 36047,
     "1999": 36739,
     "2000": 37776,
     "2001": 38352,
     "2002": 38288,
     "2003": 37995,
     "2004": 38448,
     "2005": 38741,
     "2006": 40220,
     "2007": 41587,
     "2008": 42117,
     "2009": 39852,
     "2010": 41576,
     "2011": 43189,
     "2012": 43320,
     "2013": 43413,
     "2014": 43922,
     "2015": 44293,
     "2016": 44689
  },
  "Denmark": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 2288,
     "1821": 2373,
     "1822": 2384,
     "1823": 2350,
     "1824": 2386,
     "1825": 2375,
     "1826": 2379,
     "1827": 2424,
     "1828": 2437,
     "1829": 2378,
     "1830": 2390,
     "1831": 2369,
     "1832": 2433,
     "1833": 2401,
     "1834": 2510,
     "1835": 2474,
     "1836": 2457,
     "1837": 2501,
     "1838": 2498,
     "1839": 2506,
     "1840": 2566,
     "1841": 2540,
     "1842": 2528,
     "1843": 2651,
     "1844": 2743,
     "1845": 2787,
     "1846": 2818,
     "1847": 2780,
     "1848": 2897,
     "1849": 3039,
     "1850": 3175,
     "1851": 2986,
     "1852": 3059,
     "1853": 3040,
     "1854": 3021,
     "1855": 3311,
     "1856": 3083,
     "1857": 3093,
     "1858": 3033,
     "1859": 3195,
     "1860": 3129,
     "1861": 3139,
     "1862": 3196,
     "1863": 3359,
     "1864": 3293,
     "1865": 3369,
     "1866": 3341,
     "1867": 3306,
     "1868": 3330,
     "1869": 3486,
     "1870": 3599,
     "1871": 3581,
     "1872": 3750,
     "1873": 3695,
     "1874": 3767,
     "1875": 3794,
     "1876": 3828,
     "1877": 3677,
     "1878": 3777,
     "1879": 3861,
     "1880": 3920,
     "1881": 3922,
     "1882": 4026,
     "1883": 4131,
     "1884": 4106,
     "1885": 4086,
     "1886": 4198,
     "1887": 4302,
     "1888": 4292,
     "1889": 4312,
     "1890": 4534,
     "1891": 4591,
     "1892": 4668,
     "1893": 4723,
     "1894": 4775,
     "1895": 4978,
     "1896": 5095,
     "1897": 5144,
     "1898": 5156,
     "1899": 5304,
     "1900": 5420,
     "1901": 5578,
     "1902": 5644,
     "1903": 5912,
     "1904": 5975,
     "1905": 6012,
     "1906": 6112,
     "1907": 6264,
     "1908": 6382,
     "1909": 6545,
     "1910": 6657,
     "1911": 6930,
     "1912": 6850,
     "1913": 7029,
     "1914": 7386,
     "1915": 6788,
     "1916": 6992,
     "1917": 6498,
     "1918": 6214,
     "1919": 6935,
     "1920": 7173,
     "1921": 6875,
     "1922": 7486,
     "1923": 8191,
     "1924": 8136,
     "1925": 7867,
     "1926": 8261,
     "1927": 8369,
     "1928": 8599,
     "1929": 9119,
     "1930": 9596,
     "1931": 9630,
     "1932": 9288,
     "1933": 9506,
     "1934": 9706,
     "1935": 9846,
     "1936": 10017,
     "1937": 10185,
     "1938": 10354,
     "1939": 10768,
     "1940": 9193,
     "1941": 8218,
     "1942": 8317,
     "1943": 9128,
     "1944": 9960,
     "1945": 9103,
     "1946": 10380,
     "1947": 10843,
     "1948": 11020,
     "1949": 11669,
     "1950": 12475,
     "1951": 12464,
     "1952": 12497,
     "1953": 13102,
     "1954": 13245,
     "1955": 13288,
     "1956": 13366,
     "1957": 14312,
     "1958": 14546,
     "1959": 15519,
     "1960": 15833,
     "1961": 16732,
     "1962": 17514,
     "1963": 17486,
     "1964": 18973,
     "1965": 19681,
     "1966": 20052,
     "1967": 20549,
     "1968": 21268,
     "1969": 22515,
     "1970": 22793,
     "1971": 23239,
     "1972": 24326,
     "1973": 25057,
     "1974": 24708,
     "1975": 24474,
     "1976": 25993,
     "1977": 26333,
     "1978": 26639,
     "1979": 27515,
     "1980": 27361,
     "1981": 27125,
     "1982": 27964,
     "1983": 28688,
     "1984": 29963,
     "1985": 31236,
     "1986": 32330,
     "1987": 32384,
     "1988": 32746,
     "1989": 32812,
     "1990": 33155,
     "1991": 33530,
     "1992": 34074,
     "1993": 33964,
     "1994": 35654,
     "1995": 36543,
     "1996": 37391,
     "1997": 38450,
     "1998": 39160,
     "1999": 40181,
     "2000": 41548,
     "2001": 41740,
     "2002": 41801,
     "2003": 41850,
     "2004": 42856,
     "2005": 43736,
     "2006": 45299,
     "2007": 45508,
     "2008": 45010,
     "2009": 42573,
     "2010": 43178,
     "2011": 43575,
     "2012": 43510,
     "2013": 43733,
     "2014": 44241,
     "2015": 44635,
     "2016": 44836
  },
  "Egypt": {
     "1": 1225,
     "730": 1278,
     "1000": 1050,
     "1150": 1155,
     "1280": 1173,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 1068,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 1278,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 1190,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1599,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1999,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 2799,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 3219,
     "1951": 2411,
     "1952": 2399,
     "1953": 2384,
     "1954": 2370,
     "1955": 2358,
     "1956": 2412,
     "1957": 2467,
     "1958": 2524,
     "1959": 2582,
     "1960": 2643,
     "1961": 2748,
     "1962": 2863,
     "1963": 2986,
     "1964": 3109,
     "1965": 3234,
     "1966": 3177,
     "1967": 3069,
     "1968": 3054,
     "1969": 3202,
     "1970": 3343,
     "1971": 3420,
     "1972": 3422,
     "1973": 3450,
     "1974": 3509,
     "1975": 3787,
     "1976": 4282,
     "1977": 4710,
     "1978": 4915,
     "1979": 5144,
     "1980": 5516,
     "1981": 5533,
     "1982": 5924,
     "1983": 6189,
     "1984": 6370,
     "1985": 6586,
     "1986": 6558,
     "1987": 6571,
     "1988": 6691,
     "1989": 6738,
     "1990": 6725,
     "1991": 6822,
     "1992": 6909,
     "1993": 7059,
     "1994": 7197,
     "1995": 7399,
     "1996": 7642,
     "1997": 7882,
     "1998": 8091,
     "1999": 8332,
     "2000": 8632,
     "2001": 8833,
     "2002": 8904,
     "2003": 8960,
     "2004": 9094,
     "2005": 9291,
     "2006": 9618,
     "2007": 10070,
     "2008": 10437,
     "2009": 10669,
     "2010": 10985,
     "2011": 10737,
     "2012": 10767,
     "2013": 10775,
     "2014": 11016,
     "2015": 11159,
     "2016": 11351
  },
  "Spain": {
     "1": 973,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 1509,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 1585,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 1430,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 1477,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 1589,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 1558,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 1200,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 1422,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 1369,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 1600,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 2017,
     "1851": 2036,
     "1852": 2113,
     "1853": 2113,
     "1854": 2134,
     "1855": 2238,
     "1856": 2129,
     "1857": 2061,
     "1858": 2100,
     "1859": 2202,
     "1860": 2284,
     "1861": 2286,
     "1862": 2286,
     "1863": 2318,
     "1864": 2302,
     "1865": 2206,
     "1866": 2339,
     "1867": 2303,
     "1868": 2012,
     "1869": 2082,
     "1870": 2140,
     "1871": 2305,
     "1872": 2657,
     "1873": 2871,
     "1874": 2621,
     "1875": 2694,
     "1876": 2776,
     "1877": 3078,
     "1878": 2952,
     "1879": 2741,
     "1880": 2982,
     "1881": 3003,
     "1882": 3014,
     "1883": 3056,
     "1884": 3065,
     "1885": 2950,
     "1886": 2881,
     "1887": 2818,
     "1888": 2929,
     "1889": 2921,
     "1890": 2914,
     "1891": 2969,
     "1892": 3218,
     "1893": 3080,
     "1894": 3115,
     "1895": 3062,
     "1896": 2758,
     "1897": 2921,
     "1898": 3109,
     "1899": 3119,
     "1900": 3166,
     "1901": 3413,
     "1902": 3241,
     "1903": 3209,
     "1904": 3148,
     "1905": 3102,
     "1906": 3283,
     "1907": 3346,
     "1908": 3451,
     "1909": 3523,
     "1910": 3340,
     "1911": 3582,
     "1912": 3449,
     "1913": 3629,
     "1914": 3504,
     "1915": 3517,
     "1916": 3640,
     "1917": 3566,
     "1918": 3525,
     "1919": 3579,
     "1920": 3837,
     "1921": 3930,
     "1922": 4055,
     "1923": 4062,
     "1924": 4163,
     "1925": 4407,
     "1926": 4319,
     "1927": 4684,
     "1928": 4622,
     "1929": 4938,
     "1930": 4642,
     "1931": 4480,
     "1932": 4562,
     "1933": 4366,
     "1934": 4475,
     "1935": 4504,
     "1936": 3402,
     "1937": 3139,
     "1938": 3119,
     "1939": 3413,
     "1940": 3752,
     "1941": 3776,
     "1942": 4015,
     "1943": 4201,
     "1944": 4349,
     "1945": 3972,
     "1946": 4109,
     "1947": 4157,
     "1948": 4115,
     "1949": 4064,
     "1950": 4098,
     "1951": 4485,
     "1952": 4856,
     "1953": 4812,
     "1954": 5120,
     "1955": 5256,
     "1956": 5644,
     "1957": 5797,
     "1958": 6123,
     "1959": 6017,
     "1960": 5960,
     "1961": 6611,
     "1962": 7197,
     "1963": 7854,
     "1964": 8218,
     "1965": 8778,
     "1966": 9324,
     "1967": 9719,
     "1968": 10183,
     "1969": 11012,
     "1970": 11253,
     "1971": 11705,
     "1972": 12773,
     "1973": 13770,
     "1974": 14658,
     "1975": 14933,
     "1976": 15443,
     "1977": 15784,
     "1978": 16058,
     "1979": 16186,
     "1980": 16574,
     "1981": 16440,
     "1982": 16618,
     "1983": 16846,
     "1984": 16928,
     "1985": 17381,
     "1986": 18028,
     "1987": 19248,
     "1988": 20436,
     "1989": 21722,
     "1990": 22735,
     "1991": 23425,
     "1992": 23671,
     "1993": 23166,
     "1994": 23690,
     "1995": 24465,
     "1996": 25017,
     "1997": 25856,
     "1998": 26949,
     "1999": 28127,
     "2000": 29481,
     "2001": 30501,
     "2002": 30902,
     "2003": 31315,
     "2004": 31823,
     "2005": 32406,
     "2006": 33211,
     "2007": 33801,
     "2008": 33643,
     "2009": 32181,
     "2010": 32042,
     "2011": 31600,
     "2012": 30699,
     "2013": 30280,
     "2014": 30819,
     "2015": 31847,
     "2016": 30110
  },
  "Finland": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 1209,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 1266,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 1352,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 1509,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 1232,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1334,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 1543,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 1546,
     "1861": 1551,
     "1862": 1451,
     "1863": 1550,
     "1864": 1568,
     "1865": 1540,
     "1866": 1551,
     "1867": 1434,
     "1868": 1624,
     "1869": 1783,
     "1870": 1845,
     "1871": 1824,
     "1872": 1854,
     "1873": 1932,
     "1874": 1949,
     "1875": 1961,
     "1876": 2039,
     "1877": 1961,
     "1878": 1899,
     "1879": 1890,
     "1880": 1870,
     "1881": 1797,
     "1882": 1947,
     "1883": 1991,
     "1884": 1974,
     "1885": 1993,
     "1886": 2065,
     "1887": 2065,
     "1888": 2108,
     "1889": 2148,
     "1890": 2236,
     "1891": 2186,
     "1892": 2072,
     "1893": 2171,
     "1894": 2266,
     "1895": 2416,
     "1896": 2542,
     "1897": 2630,
     "1898": 2701,
     "1899": 2602,
     "1900": 2701,
     "1901": 2649,
     "1902": 2576,
     "1903": 2729,
     "1904": 2802,
     "1905": 2820,
     "1906": 2905,
     "1907": 2970,
     "1908": 2961,
     "1909": 3051,
     "1910": 3086,
     "1911": 3139,
     "1912": 3274,
     "1913": 3417,
     "1914": 3239,
     "1915": 3046,
     "1916": 3065,
     "1917": 2559,
     "1918": 2218,
     "1919": 2685,
     "1920": 2988,
     "1921": 3051,
     "1922": 3332,
     "1923": 3540,
     "1924": 3601,
     "1925": 3769,
     "1926": 3872,
     "1927": 4140,
     "1928": 4383,
     "1929": 4398,
     "1930": 4316,
     "1931": 4178,
     "1932": 4128,
     "1933": 4374,
     "1934": 4838,
     "1935": 5007,
     "1936": 5308,
     "1937": 5571,
     "1938": 5811,
     "1939": 5517,
     "1940": 5214,
     "1941": 5379,
     "1942": 5387,
     "1943": 5985,
     "1944": 5965,
     "1945": 5585,
     "1946": 5963,
     "1947": 6017,
     "1948": 6407,
     "1949": 6708,
     "1950": 6886,
     "1951": 7401,
     "1952": 7568,
     "1953": 7531,
     "1954": 8097,
     "1955": 8413,
     "1956": 8573,
     "1957": 8888,
     "1958": 8863,
     "1959": 9315,
     "1960": 10087,
     "1961": 10779,
     "1962": 11040,
     "1963": 11323,
     "1964": 11829,
     "1965": 12417,
     "1966": 12668,
     "1967": 12865,
     "1968": 13102,
     "1969": 14372,
     "1970": 15505,
     "1971": 15809,
     "1972": 16914,
     "1973": 17946,
     "1974": 18394,
     "1975": 18523,
     "1976": 18389,
     "1977": 18383,
     "1978": 18713,
     "1979": 19964,
     "1980": 20964,
     "1981": 21264,
     "1982": 21831,
     "1983": 22288,
     "1984": 22838,
     "1985": 23511,
     "1986": 23992,
     "1987": 24903,
     "1988": 26046,
     "1989": 27434,
     "1990": 27306,
     "1991": 25551,
     "1992": 24563,
     "1993": 24265,
     "1994": 25112,
     "1995": 26069,
     "1996": 26935,
     "1997": 28534,
     "1998": 30003,
     "1999": 31263,
     "2000": 32957,
     "2001": 33730,
     "2002": 34214,
     "2003": 34813,
     "2004": 36075,
     "2005": 36951,
     "2006": 38302,
     "2007": 40117,
     "2008": 40219,
     "2009": 36717,
     "2010": 37643,
     "2011": 38432,
     "2012": 37704,
     "2013": 37246,
     "2014": 36858,
     "2015": 36836,
     "2016": 37239
  },
  "France": {
     "1": 1050,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 1364,
     "1281": 1329,
     "1282": 1294,
     "1283": 1276,
     "1284": 1259,
     "1285": 1294,
     "1286": 1294,
     "1287": 1311,
     "1288": 1346,
     "1289": 1364,
     "1290": 1364,
     "1291": 1364,
     "1292": 1381,
     "1293": 1399,
     "1294": 1311,
     "1295": 1259,
     "1296": 1294,
     "1297": 1329,
     "1298": 1381,
     "1299": 1434,
     "1300": 1469,
     "1301": 1504,
     "1302": 1556,
     "1303": 1591,
     "1304": 1608,
     "1305": 1661,
     "1306": 1766,
     "1307": 1801,
     "1308": 1836,
     "1309": 1853,
     "1310": 1871,
     "1311": 1853,
     "1312": 1888,
     "1313": 1818,
     "1314": 1801,
     "1315": 1748,
     "1316": 1731,
     "1317": 1748,
     "1318": 1766,
     "1319": 1766,
     "1320": 1661,
     "1321": 1661,
     "1322": 1661,
     "1323": 1696,
     "1324": 1713,
     "1325": 1783,
     "1326": 1783,
     "1327": 1748,
     "1328": 1731,
     "1329": 1696,
     "1330": 1661,
     "1331": 1626,
     "1332": 1626,
     "1333": 1643,
     "1334": 1731,
     "1335": 1748,
     "1336": 1766,
     "1337": 1801,
     "1338": 1766,
     "1339": 1748,
     "1340": 1731,
     "1341": 1731,
     "1342": 1713,
     "1343": 1731,
     "1344": 1731,
     "1345": 1731,
     "1346": 1783,
     "1347": 1853,
     "1348": 1906,
     "1349": 1906,
     "1350": 1958,
     "1351": 1958,
     "1352": 1941,
     "1353": 1941,
     "1354": 2011,
     "1355": 2011,
     "1356": 1976,
     "1357": 1958,
     "1358": 1958,
     "1359": 1923,
     "1360": 1906,
     "1361": 1906,
     "1362": 1923,
     "1363": 1923,
     "1364": 1941,
     "1365": 1941,
     "1366": 1923,
     "1367": 1923,
     "1368": 1941,
     "1369": 1958,
     "1370": 1976,
     "1371": 1958,
     "1372": 1958,
     "1373": 1941,
     "1374": 1923,
     "1375": 1923,
     "1376": 1906,
     "1377": 1888,
     "1378": 1888,
     "1379": 1888,
     "1380": 1871,
     "1381": 1853,
     "1382": 1836,
     "1383": 1818,
     "1384": 1818,
     "1385": 1818,
     "1386": 1836,
     "1387": 1853,
     "1388": 1871,
     "1389": 1906,
     "1390": 1941,
     "1391": 1941,
     "1392": 1941,
     "1393": 1923,
     "1394": 1923,
     "1395": 1906,
     "1396": 1941,
     "1397": 1923,
     "1398": 1923,
     "1399": 1906,
     "1400": 1853,
     "1401": 1836,
     "1402": 1818,
     "1403": 1801,
     "1404": 1783,
     "1405": 1801,
     "1406": 1801,
     "1407": 1818,
     "1408": 1818,
     "1409": 1818,
     "1410": 1818,
     "1411": 1818,
     "1412": 1801,
     "1413": 1801,
     "1414": 1801,
     "1415": 1801,
     "1416": 1801,
     "1417": 1783,
     "1418": 1783,
     "1419": 1783,
     "1420": 1783,
     "1421": 1783,
     "1422": 1801,
     "1423": 1801,
     "1424": 1801,
     "1425": 1801,
     "1426": 1818,
     "1427": 1801,
     "1428": 1818,
     "1429": 1818,
     "1430": 1818,
     "1431": 1836,
     "1432": 1836,
     "1433": 1836,
     "1434": 1836,
     "1435": 1836,
     "1436": 1836,
     "1437": 1853,
     "1438": 1853,
     "1439": 1853,
     "1440": 1853,
     "1441": 1853,
     "1442": 1853,
     "1443": 1871,
     "1444": 1871,
     "1445": 1871,
     "1446": 1871,
     "1447": 1888,
     "1448": 1871,
     "1449": 1871,
     "1450": 1871,
     "1451": 1871,
     "1452": 1853,
     "1453": 1871,
     "1454": 1853,
     "1455": 1853,
     "1456": 1836,
     "1457": 1836,
     "1458": 1818,
     "1459": 1818,
     "1460": 1801,
     "1461": 1783,
     "1462": 1783,
     "1463": 1766,
     "1464": 1766,
     "1465": 1766,
     "1466": 1766,
     "1467": 1766,
     "1468": 1766,
     "1469": 1783,
     "1470": 1783,
     "1471": 1801,
     "1472": 1836,
     "1473": 1836,
     "1474": 1818,
     "1475": 1801,
     "1476": 1766,
     "1477": 1731,
     "1478": 1731,
     "1479": 1713,
     "1480": 1731,
     "1481": 1731,
     "1482": 1766,
     "1483": 1766,
     "1484": 1783,
     "1485": 1783,
     "1486": 1783,
     "1487": 1801,
     "1488": 1801,
     "1489": 1801,
     "1490": 1801,
     "1491": 1801,
     "1492": 1801,
     "1493": 1801,
     "1494": 1783,
     "1495": 1783,
     "1496": 1783,
     "1497": 1783,
     "1498": 1783,
     "1499": 1766,
     "1500": 1748,
     "1501": 1766,
     "1502": 1748,
     "1503": 1731,
     "1504": 1731,
     "1505": 1731,
     "1506": 1713,
     "1507": 1713,
     "1508": 1731,
     "1509": 1713,
     "1510": 1713,
     "1511": 1713,
     "1512": 1713,
     "1513": 1696,
     "1514": 1696,
     "1515": 1696,
     "1516": 1696,
     "1517": 1678,
     "1518": 1696,
     "1519": 1696,
     "1520": 1696,
     "1521": 1696,
     "1522": 1696,
     "1523": 1696,
     "1524": 1696,
     "1525": 1696,
     "1526": 1696,
     "1527": 1696,
     "1528": 1696,
     "1529": 1696,
     "1530": 1696,
     "1531": 1696,
     "1532": 1696,
     "1533": 1696,
     "1534": 1696,
     "1535": 1696,
     "1536": 1696,
     "1537": 1696,
     "1538": 1661,
     "1539": 1643,
     "1540": 1643,
     "1541": 1626,
     "1542": 1626,
     "1543": 1626,
     "1544": 1626,
     "1545": 1608,
     "1546": 1608,
     "1547": 1608,
     "1548": 1591,
     "1549": 1556,
     "1550": 1538,
     "1551": 1521,
     "1552": 1504,
     "1553": 1504,
     "1554": 1504,
     "1555": 1486,
     "1556": 1486,
     "1557": 1486,
     "1558": 1486,
     "1559": 1486,
     "1560": 1486,
     "1561": 1504,
     "1562": 1504,
     "1563": 1521,
     "1564": 1521,
     "1565": 1521,
     "1566": 1521,
     "1567": 1504,
     "1568": 1504,
     "1569": 1521,
     "1570": 1538,
     "1571": 1556,
     "1572": 1573,
     "1573": 1556,
     "1574": 1591,
     "1575": 1591,
     "1576": 1591,
     "1577": 1591,
     "1578": 1591,
     "1579": 1573,
     "1580": 1573,
     "1581": 1573,
     "1582": 1591,
     "1583": 1573,
     "1584": 1608,
     "1585": 1608,
     "1586": 1608,
     "1587": 1608,
     "1588": 1626,
     "1589": 1608,
     "1590": 1608,
     "1591": 1626,
     "1592": 1626,
     "1593": 1643,
     "1594": 1678,
     "1595": 1696,
     "1596": 1678,
     "1597": 1678,
     "1598": 1678,
     "1599": 1661,
     "1600": 1661,
     "1601": 1678,
     "1602": 1678,
     "1603": 1678,
     "1604": 1661,
     "1605": 1661,
     "1606": 1678,
     "1607": 1678,
     "1608": 1678,
     "1609": 1678,
     "1610": 1678,
     "1611": 1696,
     "1612": 1696,
     "1613": 1696,
     "1614": 1696,
     "1615": 1696,
     "1616": 1696,
     "1617": 1696,
     "1618": 1696,
     "1619": 1696,
     "1620": 1696,
     "1621": 1696,
     "1622": 1696,
     "1623": 1696,
     "1624": 1696,
     "1625": 1696,
     "1626": 1696,
     "1627": 1696,
     "1628": 1696,
     "1629": 1696,
     "1630": 1696,
     "1631": 1696,
     "1632": 1696,
     "1633": 1713,
     "1634": 1713,
     "1635": 1713,
     "1636": 1713,
     "1637": 1713,
     "1638": 1713,
     "1639": 1713,
     "1640": 1713,
     "1641": 1713,
     "1642": 1748,
     "1643": 1783,
     "1644": 1801,
     "1645": 1801,
     "1646": 1801,
     "1647": 1766,
     "1648": 1731,
     "1649": 1713,
     "1650": 1713,
     "1651": 1713,
     "1652": 1713,
     "1653": 1713,
     "1654": 1713,
     "1655": 1713,
     "1656": 1731,
     "1657": 1731,
     "1658": 1731,
     "1659": 1731,
     "1660": 1731,
     "1661": 1731,
     "1662": 1748,
     "1663": 1766,
     "1664": 1766,
     "1665": 1783,
     "1666": 1783,
     "1667": 1783,
     "1668": 1801,
     "1669": 1801,
     "1670": 1801,
     "1671": 1801,
     "1672": 1801,
     "1673": 1801,
     "1674": 1801,
     "1675": 1801,
     "1676": 1801,
     "1677": 1801,
     "1678": 1783,
     "1679": 1766,
     "1680": 1748,
     "1681": 1748,
     "1682": 1748,
     "1683": 1748,
     "1684": 1766,
     "1685": 1766,
     "1686": 1766,
     "1687": 1766,
     "1688": 1766,
     "1689": 1766,
     "1690": 1766,
     "1691": 1766,
     "1692": 1766,
     "1693": 1766,
     "1694": 1748,
     "1695": 1748,
     "1696": 1748,
     "1697": 1731,
     "1698": 1731,
     "1699": 1731,
     "1700": 1748,
     "1701": 1661,
     "1702": 1661,
     "1703": 1661,
     "1704": 1661,
     "1705": 1643,
     "1706": 1661,
     "1707": 1661,
     "1708": 1661,
     "1709": 1678,
     "1710": 1696,
     "1711": 1696,
     "1712": 1713,
     "1713": 1713,
     "1714": 1713,
     "1715": 1713,
     "1716": 1713,
     "1717": 1731,
     "1718": 1748,
     "1719": 1766,
     "1720": 1783,
     "1721": 1801,
     "1722": 1801,
     "1723": 1783,
     "1724": 1766,
     "1725": 1766,
     "1726": 1766,
     "1727": 1766,
     "1728": 1766,
     "1729": 1783,
     "1730": 1783,
     "1731": 1783,
     "1732": 1783,
     "1733": 1783,
     "1734": 1783,
     "1735": 1766,
     "1736": 1766,
     "1737": 1748,
     "1738": 1731,
     "1739": 1731,
     "1740": 1713,
     "1741": 1713,
     "1742": 1713,
     "1743": 1713,
     "1744": 1713,
     "1745": 1731,
     "1746": 1731,
     "1747": 1731,
     "1748": 1731,
     "1749": 1731,
     "1750": 1731,
     "1751": 1713,
     "1752": 1713,
     "1753": 1713,
     "1754": 1713,
     "1755": 1713,
     "1756": 1713,
     "1757": 1696,
     "1758": 1696,
     "1759": 1696,
     "1760": 1696,
     "1761": 1713,
     "1762": 1731,
     "1763": 1731,
     "1764": 1748,
     "1765": 1766,
     "1766": 1766,
     "1767": 1766,
     "1768": 1748,
     "1769": 1748,
     "1770": 1748,
     "1771": 1748,
     "1772": 1748,
     "1773": 1766,
     "1774": 1766,
     "1775": 1766,
     "1776": 1783,
     "1777": 1783,
     "1778": 1766,
     "1779": 1766,
     "1780": 1766,
     "1781": 1783,
     "1782": 1783,
     "1783": 1836,
     "1784": 1853,
     "1785": 1836,
     "1786": 1818,
     "1787": 1923,
     "1788": 1871,
     "1789": 1836,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1867,
     "1821": 2014,
     "1822": 1935,
     "1823": 1996,
     "1824": 2050,
     "1825": 1959,
     "1826": 2011,
     "1827": 1969,
     "1828": 1957,
     "1829": 2008,
     "1830": 1959,
     "1831": 1986,
     "1832": 2159,
     "1833": 2118,
     "1834": 2121,
     "1835": 2192,
     "1836": 2157,
     "1837": 2187,
     "1838": 2282,
     "1839": 2197,
     "1840": 2349,
     "1841": 2395,
     "1842": 2332,
     "1843": 2446,
     "1844": 2523,
     "1845": 2435,
     "1846": 2432,
     "1847": 2690,
     "1848": 2516,
     "1849": 2586,
     "1850": 2627,
     "1851": 2579,
     "1852": 2738,
     "1853": 2596,
     "1854": 2711,
     "1855": 2661,
     "1856": 2773,
     "1857": 2927,
     "1858": 3126,
     "1859": 2918,
     "1860": 3113,
     "1861": 2911,
     "1862": 3149,
     "1863": 3246,
     "1864": 3270,
     "1865": 3165,
     "1866": 3182,
     "1867": 2982,
     "1868": 3260,
     "1869": 3301,
     "1870": 3086,
     "1871": 3125,
     "1872": 3419,
     "1873": 3162,
     "1874": 3549,
     "1875": 3651,
     "1876": 3337,
     "1877": 3500,
     "1878": 3440,
     "1879": 3213,
     "1880": 3488,
     "1881": 3609,
     "1882": 3765,
     "1883": 3764,
     "1884": 3707,
     "1885": 3631,
     "1886": 3680,
     "1887": 3701,
     "1888": 3733,
     "1889": 3821,
     "1890": 3909,
     "1891": 4001,
     "1892": 4101,
     "1893": 4170,
     "1894": 4320,
     "1895": 4227,
     "1896": 4418,
     "1897": 4342,
     "1898": 4540,
     "1899": 4790,
     "1900": 4731,
     "1901": 4650,
     "1902": 4566,
     "1903": 4657,
     "1904": 4683,
     "1905": 4761,
     "1906": 4842,
     "1907": 5051,
     "1908": 5009,
     "1909": 5210,
     "1910": 4878,
     "1911": 5346,
     "1912": 5782,
     "1913": 5733,
     "1914": 5324,
     "1915": 5343,
     "1916": 5698,
     "1917": 4901,
     "1918": 3941,
     "1919": 4625,
     "1920": 5309,
     "1921": 5058,
     "1922": 5939,
     "1923": 6175,
     "1924": 6876,
     "1925": 6854,
     "1926": 6991,
     "1927": 6834,
     "1928": 7290,
     "1929": 7748,
     "1930": 7455,
     "1931": 6967,
     "1932": 6513,
     "1933": 6974,
     "1934": 6896,
     "1935": 6722,
     "1936": 6982,
     "1937": 7381,
     "1938": 7347,
     "1939": 7885,
     "1940": 6650,
     "1941": 5444,
     "1942": 4905,
     "1943": 4705,
     "1944": 3984,
     "1945": 4233,
     "1946": 6342,
     "1947": 6807,
     "1948": 7228,
     "1949": 8137,
     "1950": 8531,
     "1951": 8984,
     "1952": 9154,
     "1953": 9351,
     "1954": 9731,
     "1955": 10198,
     "1956": 10608,
     "1957": 11124,
     "1958": 11277,
     "1959": 11481,
     "1960": 12170,
     "1961": 12698,
     "1962": 13271,
     "1963": 13757,
     "1964": 14509,
     "1965": 15078,
     "1966": 15701,
     "1967": 16298,
     "1968": 16890,
     "1969": 17908,
     "1970": 18771,
     "1971": 19486,
     "1972": 20175,
     "1973": 21097,
     "1974": 21571,
     "1975": 21316,
     "1976": 22154,
     "1977": 22888,
     "1978": 23427,
     "1979": 24075,
     "1980": 24292,
     "1981": 24413,
     "1982": 24893,
     "1983": 25080,
     "1984": 25305,
     "1985": 25549,
     "1986": 26047,
     "1987": 26581,
     "1988": 27621,
     "1989": 28460,
     "1990": 29031,
     "1991": 29158,
     "1992": 29449,
     "1993": 29113,
     "1994": 29656,
     "1995": 30135,
     "1996": 30415,
     "1997": 30987,
     "1998": 31941,
     "1999": 32872,
     "2000": 33967,
     "2001": 34434,
     "2002": 34618,
     "2003": 34707,
     "2004": 35465,
     "2005": 35817,
     "2006": 36439,
     "2007": 37068,
     "2008": 36928,
     "2009": 35642,
     "2010": 36141,
     "2011": 36691,
     "2012": 36571,
     "2013": 36632,
     "2014": 36527,
     "2015": 36827,
     "2016": 37124
  },
  "United Kingdom": {
     "1": 0,
     "730": 0,
     "1000": 1128,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 2365,
     "1701": 2561,
     "1702": 2477,
     "1703": 2299,
     "1704": 2723,
     "1705": 2579,
     "1706": 2177,
     "1707": 2437,
     "1708": 2585,
     "1709": 2234,
     "1710": 2025,
     "1711": 2211,
     "1712": 2213,
     "1713": 2114,
     "1714": 2317,
     "1715": 2250,
     "1716": 2321,
     "1717": 2435,
     "1718": 2555,
     "1719": 2493,
     "1720": 2661,
     "1721": 2560,
     "1722": 2585,
     "1723": 2513,
     "1724": 2480,
     "1725": 2535,
     "1726": 2436,
     "1727": 2407,
     "1728": 2504,
     "1729": 2404,
     "1730": 2489,
     "1731": 2451,
     "1732": 2603,
     "1733": 2646,
     "1734": 2610,
     "1735": 2525,
     "1736": 2691,
     "1737": 2532,
     "1738": 2567,
     "1739": 2534,
     "1740": 2478,
     "1741": 2544,
     "1742": 2630,
     "1743": 2569,
     "1744": 2516,
     "1745": 2527,
     "1746": 2590,
     "1747": 2696,
     "1748": 2661,
     "1749": 2628,
     "1750": 2649,
     "1751": 2575,
     "1752": 2686,
     "1753": 2690,
     "1754": 2596,
     "1755": 2657,
     "1756": 2592,
     "1757": 2730,
     "1758": 2761,
     "1759": 2795,
     "1760": 2859,
     "1761": 2953,
     "1762": 2860,
     "1763": 2857,
     "1764": 2842,
     "1765": 2798,
     "1766": 2822,
     "1767": 2835,
     "1768": 2854,
     "1769": 2996,
     "1770": 2894,
     "1771": 2930,
     "1772": 2834,
     "1773": 2863,
     "1774": 2779,
     "1775": 2839,
     "1776": 2903,
     "1777": 2959,
     "1778": 2908,
     "1779": 2874,
     "1780": 2989,
     "1781": 3041,
     "1782": 2988,
     "1783": 2967,
     "1784": 2951,
     "1785": 2904,
     "1786": 2923,
     "1787": 2841,
     "1788": 2806,
     "1789": 2831,
     "1790": 3012,
     "1791": 2987,
     "1792": 3072,
     "1793": 2991,
     "1794": 2869,
     "1795": 3145,
     "1796": 3169,
     "1797": 3106,
     "1798": 3100,
     "1799": 3144,
     "1800": 3277,
     "1801": 3285,
     "1802": 3295,
     "1803": 3188,
     "1804": 3142,
     "1805": 3283,
     "1806": 3223,
     "1807": 3387,
     "1808": 3187,
     "1809": 3244,
     "1810": 3410,
     "1811": 3331,
     "1812": 3145,
     "1813": 3264,
     "1814": 3142,
     "1815": 3421,
     "1816": 3188,
     "1817": 3190,
     "1818": 3139,
     "1819": 3043,
     "1820": 3241,
     "1821": 3243,
     "1822": 3251,
     "1823": 3294,
     "1824": 3424,
     "1825": 3453,
     "1826": 3226,
     "1827": 3430,
     "1828": 3393,
     "1829": 3375,
     "1830": 3481,
     "1831": 3476,
     "1832": 3542,
     "1833": 3529,
     "1834": 3569,
     "1835": 3786,
     "1836": 3861,
     "1837": 3782,
     "1838": 3885,
     "1839": 3806,
     "1840": 3940,
     "1841": 3818,
     "1842": 3734,
     "1843": 3873,
     "1844": 4163,
     "1845": 4301,
     "1846": 4250,
     "1847": 4152,
     "1848": 4276,
     "1849": 4336,
     "1850": 4248,
     "1851": 4390,
     "1852": 4535,
     "1853": 4642,
     "1854": 4814,
     "1855": 4656,
     "1856": 4915,
     "1857": 4921,
     "1858": 4784,
     "1859": 4933,
     "1860": 4988,
     "1861": 4926,
     "1862": 4672,
     "1863": 5064,
     "1864": 5153,
     "1865": 5229,
     "1866": 5345,
     "1867": 5319,
     "1868": 5526,
     "1869": 5525,
     "1870": 5716,
     "1871": 5684,
     "1872": 5657,
     "1873": 5729,
     "1874": 5760,
     "1875": 5834,
     "1876": 5823,
     "1877": 5808,
     "1878": 5764,
     "1879": 5674,
     "1880": 5879,
     "1881": 6027,
     "1882": 6147,
     "1883": 6142,
     "1884": 6099,
     "1885": 6012,
     "1886": 6050,
     "1887": 6235,
     "1888": 6457,
     "1889": 6743,
     "1890": 6711,
     "1891": 6648,
     "1892": 6426,
     "1893": 6360,
     "1894": 6718,
     "1895": 6859,
     "1896": 7070,
     "1897": 7088,
     "1898": 7354,
     "1899": 7578,
     "1900": 7446,
     "1901": 7370,
     "1902": 7486,
     "1903": 7337,
     "1904": 7311,
     "1905": 7456,
     "1906": 7631,
     "1907": 7702,
     "1908": 7316,
     "1909": 7410,
     "1910": 7567,
     "1911": 7721,
     "1912": 7800,
     "1913": 8052,
     "1914": 7973,
     "1915": 8471,
     "1916": 8532,
     "1917": 8496,
     "1918": 8462,
     "1919": 7456,
     "1920": 6881,
     "1921": 6660,
     "1922": 7247,
     "1923": 7439,
     "1924": 7691,
     "1925": 8041,
     "1926": 7715,
     "1927": 8307,
     "1928": 8373,
     "1929": 8601,
     "1930": 8504,
     "1931": 8031,
     "1932": 8047,
     "1933": 8248,
     "1934": 8764,
     "1935": 9064,
     "1936": 9433,
     "1937": 9718,
     "1938": 9794,
     "1939": 9788,
     "1940": 10716,
     "1941": 11694,
     "1942": 11939,
     "1943": 12103,
     "1944": 11574,
     "1945": 11028,
     "1946": 10543,
     "1947": 10322,
     "1948": 10543,
     "1949": 10871,
     "1950": 10846,
     "1951": 11134,
     "1952": 11083,
     "1953": 11481,
     "1954": 11909,
     "1955": 12298,
     "1956": 12392,
     "1957": 12530,
     "1958": 12450,
     "1959": 12878,
     "1960": 13512,
     "1961": 13843,
     "1962": 13856,
     "1963": 14300,
     "1964": 14954,
     "1965": 15241,
     "1966": 15450,
     "1967": 15706,
     "1968": 16270,
     "1969": 16492,
     "1970": 16829,
     "1971": 17101,
     "1972": 17652,
     "1973": 18795,
     "1974": 18535,
     "1975": 18517,
     "1976": 18935,
     "1977": 19355,
     "1978": 20049,
     "1979": 20580,
     "1980": 20211,
     "1981": 19924,
     "1982": 20248,
     "1983": 20951,
     "1984": 21444,
     "1985": 22139,
     "1986": 23041,
     "1987": 24059,
     "1988": 25179,
     "1989": 25654,
     "1990": 25679,
     "1991": 25314,
     "1992": 25336,
     "1993": 25909,
     "1994": 26847,
     "1995": 27447,
     "1996": 28075,
     "1997": 28879,
     "1998": 29714,
     "1999": 30587,
     "2000": 31619,
     "2001": 32356,
     "2002": 32992,
     "2003": 33977,
     "2004": 34638,
     "2005": 35424,
     "2006": 36045,
     "2007": 36679,
     "2008": 36163,
     "2009": 34338,
     "2010": 34722,
     "2011": 34971,
     "2012": 35185,
     "2013": 35618,
     "2014": 36436,
     "2015": 36941,
     "2016": 37334
  },
  "Greece": {
     "1": 1400,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 1854,
     "1834": 1974,
     "1835": 2579,
     "1836": 2495,
     "1837": 2558,
     "1838": 2692,
     "1839": 2438,
     "1840": 2332,
     "1841": 2314,
     "1842": 2364,
     "1843": 1994,
     "1844": 1933,
     "1845": 1862,
     "1846": 2041,
     "1847": 1739,
     "1848": 1875,
     "1849": 1920,
     "1850": 1940,
     "1851": 2376,
     "1852": 2006,
     "1853": 2199,
     "1854": 2304,
     "1855": 2422,
     "1856": 2121,
     "1857": 2749,
     "1858": 2651,
     "1859": 2608,
     "1860": 2564,
     "1861": 2729,
     "1862": 2754,
     "1863": 2461,
     "1864": 2237,
     "1865": 2391,
     "1866": 2282,
     "1867": 2410,
     "1868": 2438,
     "1869": 2405,
     "1870": 2339,
     "1871": 2244,
     "1872": 2179,
     "1873": 2569,
     "1874": 2431,
     "1875": 2328,
     "1876": 2318,
     "1877": 2177,
     "1878": 2396,
     "1879": 2489,
     "1880": 2448,
     "1881": 2378,
     "1882": 2532,
     "1883": 2524,
     "1884": 2837,
     "1885": 3062,
     "1886": 2928,
     "1887": 3029,
     "1888": 3098,
     "1889": 2885,
     "1890": 2724,
     "1891": 2375,
     "1892": 2477,
     "1893": 2637,
     "1894": 2696,
     "1895": 2594,
     "1896": 2848,
     "1897": 2419,
     "1898": 2612,
     "1899": 2666,
     "1900": 2381,
     "1901": 2282,
     "1902": 2924,
     "1903": 2858,
     "1904": 3014,
     "1905": 3090,
     "1906": 3053,
     "1907": 3095,
     "1908": 3079,
     "1909": 3139,
     "1910": 3127,
     "1911": 3900,
     "1912": 3784,
     "1913": 2264,
     "1914": 2890,
     "1915": 2199,
     "1916": 1870,
     "1917": 1632,
     "1918": 2751,
     "1919": 2451,
     "1920": 2756,
     "1921": 3690,
     "1922": 3777,
     "1923": 3824,
     "1924": 3957,
     "1925": 4116,
     "1926": 4193,
     "1927": 4271,
     "1928": 4298,
     "1929": 4505,
     "1930": 4344,
     "1931": 4106,
     "1932": 4403,
     "1933": 4607,
     "1934": 4652,
     "1935": 4771,
     "1936": 4723,
     "1937": 5327,
     "1938": 5150,
     "1939": 5074,
     "1940": 4276,
     "1941": 3605,
     "1942": 3038,
     "1943": 2553,
     "1944": 2147,
     "1945": 1804,
     "1946": 2667,
     "1947": 3391,
     "1948": 3460,
     "1949": 3595,
     "1950": 3684,
     "1951": 3966,
     "1952": 3950,
     "1953": 4443,
     "1954": 4537,
     "1955": 4836,
     "1956": 5206,
     "1957": 5500,
     "1958": 5700,
     "1959": 5849,
     "1960": 6052,
     "1961": 6527,
     "1962": 6732,
     "1963": 7389,
     "1964": 7967,
     "1965": 8674,
     "1966": 9136,
     "1967": 9524,
     "1968": 10130,
     "1969": 11093,
     "1970": 11948,
     "1971": 12743,
     "1972": 14236,
     "1973": 14727,
     "1974": 14139,
     "1975": 14855,
     "1976": 15591,
     "1977": 15881,
     "1978": 16726,
     "1979": 17129,
     "1980": 17259,
     "1981": 17114,
     "1982": 17081,
     "1983": 17056,
     "1984": 17443,
     "1985": 17921,
     "1986": 18161,
     "1987": 18035,
     "1988": 18822,
     "1989": 19452,
     "1990": 19267,
     "1991": 19628,
     "1992": 19615,
     "1993": 19188,
     "1994": 19474,
     "1995": 19790,
     "1996": 20267,
     "1997": 21071,
     "1998": 21771,
     "1999": 22354,
     "2000": 23136,
     "2001": 23967,
     "2002": 24816,
     "2003": 26191,
     "2004": 27448,
     "2005": 27532,
     "2006": 29001,
     "2007": 29874,
     "2008": 29695,
     "2009": 28343,
     "2010": 26756,
     "2011": 24349,
     "2012": 22693,
     "2013": 22118,
     "2014": 22344,
     "2015": 22442,
     "2016": 22574
  },
  "China, Hong Kong SAR": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 961,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1067,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 1999,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 3466,
     "1951": 3133,
     "1952": 3266,
     "1953": 3381,
     "1954": 3502,
     "1955": 3635,
     "1956": 3779,
     "1957": 3928,
     "1958": 4076,
     "1959": 4210,
     "1960": 4321,
     "1961": 4391,
     "1962": 4908,
     "1963": 5420,
     "1964": 5659,
     "1965": 6297,
     "1966": 6295,
     "1967": 6347,
     "1968": 6536,
     "1969": 7248,
     "1970": 7855,
     "1971": 8311,
     "1972": 9029,
     "1973": 9951,
     "1974": 9953,
     "1975": 9729,
     "1976": 10980,
     "1977": 11877,
     "1978": 12474,
     "1979": 13506,
     "1980": 14510,
     "1981": 15542,
     "1982": 15734,
     "1983": 16433,
     "1984": 17871,
     "1985": 17735,
     "1986": 19382,
     "1987": 21584,
     "1988": 22984,
     "1989": 23250,
     "1990": 23745,
     "1991": 24829,
     "1992": 26052,
     "1993": 27159,
     "1994": 28228,
     "1995": 28203,
     "1996": 28708,
     "1997": 29941,
     "1998": 27897,
     "1999": 28359,
     "2000": 30196,
     "2001": 30284,
     "2002": 30804,
     "2003": 31567,
     "2004": 34142,
     "2005": 36450,
     "2006": 38640,
     "2007": 40937,
     "2008": 41652,
     "2009": 40442,
     "2010": 42836,
     "2011": 44532,
     "2012": 44904,
     "2013": 46040,
     "2014": 47038,
     "2015": 47777,
     "2016": 48330
  },
  "Hungary": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 2656,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 3583,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 4094,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 4868,
     "1911": 0,
     "1912": 0,
     "1913": 5105,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 4158,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 4653,
     "1925": 5546,
     "1926": 5261,
     "1927": 5444,
     "1928": 5876,
     "1929": 6024,
     "1930": 5849,
     "1931": 5519,
     "1932": 5335,
     "1933": 5776,
     "1934": 5766,
     "1935": 6013,
     "1936": 6371,
     "1937": 6188,
     "1938": 6461,
     "1939": 6905,
     "1940": 6391,
     "1941": 6390,
     "1942": 6674,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 4187,
     "1947": 4316,
     "1948": 5353,
     "1949": 5728,
     "1950": 6034,
     "1951": 6558,
     "1952": 6721,
     "1953": 6778,
     "1954": 6935,
     "1955": 7471,
     "1956": 7071,
     "1957": 7712,
     "1958": 8193,
     "1959": 8478,
     "1960": 8879,
     "1961": 9286,
     "1962": 9640,
     "1963": 10141,
     "1964": 10677,
     "1965": 10730,
     "1966": 11305,
     "1967": 11908,
     "1968": 12006,
     "1969": 12318,
     "1970": 12234,
     "1971": 12746,
     "1972": 12983,
     "1973": 13615,
     "1974": 13908,
     "1975": 14125,
     "1976": 14090,
     "1977": 14907,
     "1978": 15216,
     "1979": 15211,
     "1980": 15345,
     "1981": 15453,
     "1982": 16019,
     "1983": 15878,
     "1984": 16326,
     "1985": 15954,
     "1986": 16301,
     "1987": 16581,
     "1988": 17108,
     "1989": 16797,
     "1990": 15716,
     "1991": 13847,
     "1992": 13423,
     "1993": 13357,
     "1994": 13764,
     "1995": 13989,
     "1996": 14014,
     "1997": 14507,
     "1998": 15154,
     "1999": 15684,
     "2000": 16386,
     "2001": 17043,
     "2002": 17861,
     "2003": 18598,
     "2004": 19573,
     "2005": 20471,
     "2006": 21293,
     "2007": 21422,
     "2008": 21650,
     "2009": 20261,
     "2010": 20444,
     "2011": 20886,
     "2012": 20631,
     "2013": 21126,
     "2014": 22040,
     "2015": 22788,
     "2016": 23279
  },
  "Indonesia": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 875,
     "1816": 950,
     "1817": 938,
     "1818": 918,
     "1819": 920,
     "1820": 912,
     "1821": 921,
     "1822": 920,
     "1823": 921,
     "1824": 875,
     "1825": 900,
     "1826": 954,
     "1827": 961,
     "1828": 944,
     "1829": 918,
     "1830": 906,
     "1831": 893,
     "1832": 893,
     "1833": 925,
     "1834": 954,
     "1835": 923,
     "1836": 904,
     "1837": 949,
     "1838": 946,
     "1839": 928,
     "1840": 972,
     "1841": 966,
     "1842": 967,
     "1843": 947,
     "1844": 917,
     "1845": 862,
     "1846": 835,
     "1847": 823,
     "1848": 853,
     "1849": 822,
     "1850": 797,
     "1851": 820,
     "1852": 789,
     "1853": 818,
     "1854": 783,
     "1855": 822,
     "1856": 878,
     "1857": 841,
     "1858": 897,
     "1859": 898,
     "1860": 902,
     "1861": 908,
     "1862": 848,
     "1863": 852,
     "1864": 841,
     "1865": 864,
     "1866": 881,
     "1867": 910,
     "1868": 880,
     "1869": 867,
     "1870": 892,
     "1871": 893,
     "1872": 914,
     "1873": 896,
     "1874": 883,
     "1875": 916,
     "1876": 910,
     "1877": 924,
     "1878": 951,
     "1879": 946,
     "1880": 1147,
     "1881": 1208,
     "1882": 1160,
     "1883": 1133,
     "1884": 1220,
     "1885": 1219,
     "1886": 1199,
     "1887": 1200,
     "1888": 1189,
     "1889": 1177,
     "1890": 1134,
     "1891": 1154,
     "1892": 1189,
     "1893": 1215,
     "1894": 1219,
     "1895": 1208,
     "1896": 1203,
     "1897": 1203,
     "1898": 1194,
     "1899": 1242,
     "1900": 1267,
     "1901": 1235,
     "1902": 1196,
     "1903": 1251,
     "1904": 1253,
     "1905": 1255,
     "1906": 1275,
     "1907": 1293,
     "1908": 1278,
     "1909": 1326,
     "1910": 1392,
     "1911": 1442,
     "1912": 1449,
     "1913": 1498,
     "1914": 1481,
     "1915": 1484,
     "1916": 1480,
     "1917": 1467,
     "1918": 1496,
     "1919": 1617,
     "1920": 1551,
     "1921": 1536,
     "1922": 1561,
     "1923": 1574,
     "1924": 1636,
     "1925": 1648,
     "1926": 1725,
     "1927": 1812,
     "1928": 1856,
     "1929": 1875,
     "1930": 1876,
     "1931": 1728,
     "1932": 1631,
     "1933": 1618,
     "1934": 1614,
     "1935": 1628,
     "1936": 1699,
     "1937": 1867,
     "1938": 1823,
     "1939": 1805,
     "1940": 1944,
     "1941": 1991,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 1276,
     "1950": 1410,
     "1951": 1494,
     "1952": 1543,
     "1953": 1597,
     "1954": 1672,
     "1955": 1692,
     "1956": 1688,
     "1957": 1776,
     "1958": 1685,
     "1959": 1730,
     "1960": 1776,
     "1961": 1860,
     "1962": 1821,
     "1963": 1716,
     "1964": 1743,
     "1965": 1725,
     "1966": 1690,
     "1967": 1619,
     "1968": 1740,
     "1969": 1915,
     "1970": 2074,
     "1971": 2143,
     "1972": 2331,
     "1973": 2616,
     "1974": 2685,
     "1975": 2627,
     "1976": 2793,
     "1977": 2941,
     "1978": 3004,
     "1979": 3094,
     "1980": 3283,
     "1981": 3435,
     "1982": 3239,
     "1983": 3297,
     "1984": 3451,
     "1985": 3461,
     "1986": 3600,
     "1987": 3711,
     "1988": 3854,
     "1989": 4126,
     "1990": 4414,
     "1991": 4726,
     "1992": 4949,
     "1993": 5255,
     "1994": 5557,
     "1995": 5914,
     "1996": 6269,
     "1997": 6458,
     "1998": 5523,
     "1999": 5481,
     "2000": 5664,
     "2001": 5786,
     "2002": 5962,
     "2003": 6161,
     "2004": 6383,
     "2005": 6663,
     "2006": 6940,
     "2007": 7290,
     "2008": 7739,
     "2009": 8009,
     "2010": 8425,
     "2011": 8849,
     "2012": 9285,
     "2013": 9703,
     "2014": 10090,
     "2015": 10484,
     "2016": 10911
  },
  "India": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 1055,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 993,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 970,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 892,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 862,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 821,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 782,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 789,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 788,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 790,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 747,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 710,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 733,
     "1885": 754,
     "1886": 729,
     "1887": 761,
     "1888": 766,
     "1889": 744,
     "1890": 777,
     "1891": 705,
     "1892": 760,
     "1893": 778,
     "1894": 788,
     "1895": 768,
     "1896": 710,
     "1897": 838,
     "1898": 838,
     "1899": 772,
     "1900": 797,
     "1901": 809,
     "1902": 871,
     "1903": 878,
     "1904": 877,
     "1905": 856,
     "1906": 875,
     "1907": 817,
     "1908": 824,
     "1909": 931,
     "1910": 927,
     "1911": 919,
     "1912": 917,
     "1913": 895,
     "1914": 943,
     "1915": 919,
     "1916": 945,
     "1917": 927,
     "1918": 808,
     "1919": 919,
     "1920": 845,
     "1921": 904,
     "1922": 933,
     "1923": 893,
     "1924": 928,
     "1925": 929,
     "1926": 949,
     "1927": 940,
     "1928": 940,
     "1929": 969,
     "1930": 966,
     "1931": 946,
     "1932": 944,
     "1933": 931,
     "1934": 927,
     "1935": 906,
     "1936": 928,
     "1937": 900,
     "1938": 890,
     "1939": 897,
     "1940": 913,
     "1941": 919,
     "1942": 904,
     "1943": 929,
     "1944": 909,
     "1945": 884,
     "1946": 828,
     "1947": 822,
     "1948": 821,
     "1949": 831,
     "1950": 824,
     "1951": 829,
     "1952": 838,
     "1953": 874,
     "1954": 894,
     "1955": 899,
     "1956": 933,
     "1957": 904,
     "1958": 952,
     "1959": 954,
     "1960": 1002,
     "1961": 1009,
     "1962": 1009,
     "1963": 1037,
     "1964": 1093,
     "1965": 1026,
     "1966": 1014,
     "1967": 1074,
     "1968": 1076,
     "1969": 1124,
     "1970": 1155,
     "1971": 1140,
     "1972": 1110,
     "1973": 1135,
     "1974": 1122,
     "1975": 1194,
     "1976": 1184,
     "1977": 1247,
     "1978": 1285,
     "1979": 1192,
     "1980": 1249,
     "1981": 1300,
     "1982": 1312,
     "1983": 1388,
     "1984": 1410,
     "1985": 1436,
     "1986": 1466,
     "1987": 1497,
     "1988": 1618,
     "1989": 1690,
     "1990": 1742,
     "1991": 1736,
     "1992": 1797,
     "1993": 1865,
     "1994": 1949,
     "1995": 2053,
     "1996": 2177,
     "1997": 2229,
     "1998": 2336,
     "1999": 2443,
     "2000": 2505,
     "2001": 2604,
     "2002": 2657,
     "2003": 2834,
     "2004": 3025,
     "2005": 3226,
     "2006": 3477,
     "2007": 3740,
     "2008": 3928,
     "2009": 4198,
     "2010": 4487,
     "2011": 4768,
     "2012": 4932,
     "2013": 5172,
     "2014": 5458,
     "2015": 5794,
     "2016": 6125
  },
  "Ireland": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1775,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 3590,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 5533,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 5122,
     "1922": 5255,
     "1923": 5207,
     "1924": 5195,
     "1925": 5203,
     "1926": 5200,
     "1927": 5365,
     "1928": 5535,
     "1929": 5711,
     "1930": 5859,
     "1931": 6010,
     "1932": 5834,
     "1933": 5663,
     "1934": 5828,
     "1935": 5998,
     "1936": 6173,
     "1937": 5979,
     "1938": 6173,
     "1939": 6172,
     "1940": 6172,
     "1941": 6172,
     "1942": 6172,
     "1943": 6172,
     "1944": 6172,
     "1945": 6105,
     "1946": 6172,
     "1947": 6253,
     "1948": 6533,
     "1949": 6884,
     "1950": 6983,
     "1951": 7167,
     "1952": 7366,
     "1953": 7577,
     "1954": 7673,
     "1955": 7928,
     "1956": 7881,
     "1957": 7916,
     "1958": 7825,
     "1959": 8167,
     "1960": 8660,
     "1961": 9117,
     "1962": 9376,
     "1963": 9750,
     "1964": 10083,
     "1965": 10216,
     "1966": 10274,
     "1967": 10823,
     "1968": 11668,
     "1969": 12315,
     "1970": 12537,
     "1971": 12849,
     "1972": 13474,
     "1973": 13887,
     "1974": 14242,
     "1975": 14796,
     "1976": 14768,
     "1977": 15765,
     "1978": 16684,
     "1979": 16920,
     "1980": 17272,
     "1981": 17627,
     "1982": 17839,
     "1983": 17675,
     "1984": 18313,
     "1985": 18820,
     "1986": 18736,
     "1987": 19613,
     "1988": 20697,
     "1989": 22002,
     "1990": 23899,
     "1991": 24153,
     "1992": 24847,
     "1993": 25295,
     "1994": 26681,
     "1995": 29090,
     "1996": 31485,
     "1997": 34521,
     "1998": 36974,
     "1999": 40531,
     "2000": 43959,
     "2001": 45883,
     "2002": 47632,
     "2003": 48584,
     "2004": 50912,
     "2005": 52687,
     "2006": 54287,
     "2007": 54749,
     "2008": 51298,
     "2009": 48462,
     "2010": 49178,
     "2011": 48980,
     "2012": 48333,
     "2013": 48743,
     "2014": 52651,
     "2015": 54278,
     "2016": 56597
  },
  "Iran (Islamic Republic of)": {
     "1": 1225,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1531,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1670,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 2227,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 4788,
     "1951": 4659,
     "1952": 4535,
     "1953": 4420,
     "1954": 4303,
     "1955": 4188,
     "1956": 4437,
     "1957": 4919,
     "1958": 5339,
     "1959": 5633,
     "1960": 6003,
     "1961": 6326,
     "1962": 6264,
     "1963": 6754,
     "1964": 7032,
     "1965": 7664,
     "1966": 8184,
     "1967": 8842,
     "1968": 9883,
     "1969": 10849,
     "1970": 11661,
     "1971": 12741,
     "1972": 14402,
     "1973": 15205,
     "1974": 16084,
     "1975": 16375,
     "1976": 18627,
     "1977": 17822,
     "1978": 15210,
     "1979": 13409,
     "1980": 11061,
     "1981": 10279,
     "1982": 11413,
     "1983": 12414,
     "1984": 12139,
     "1985": 11967,
     "1986": 10449,
     "1987": 9976,
     "1988": 9167,
     "1989": 9260,
     "1990": 9816,
     "1991": 10852,
     "1992": 11653,
     "1993": 11150,
     "1994": 10812,
     "1995": 10849,
     "1996": 11537,
     "1997": 11766,
     "1998": 11945,
     "1999": 11985,
     "2000": 12193,
     "2001": 12546,
     "2002": 13293,
     "2003": 14225,
     "2004": 14938,
     "2005": 15374,
     "2006": 16200,
     "2007": 17200,
     "2008": 17045,
     "2009": 17479,
     "2010": 18229,
     "2011": 18024,
     "2012": 16745,
     "2013": 16248,
     "2014": 16493,
     "2015": 16253,
     "2016": 16783
  },
  "Iraq": {
     "1": 1225,
     "730": 1610,
     "1000": 1435,
     "1150": 1190,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 3847,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 4197,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 5596,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 9542,
     "1951": 10111,
     "1952": 10888,
     "1953": 14893,
     "1954": 17231,
     "1955": 16078,
     "1956": 16714,
     "1957": 16088,
     "1958": 17441,
     "1959": 17650,
     "1960": 19133,
     "1961": 20714,
     "1962": 21102,
     "1963": 20089,
     "1964": 21794,
     "1965": 23000,
     "1966": 23424,
     "1967": 22132,
     "1968": 25210,
     "1969": 25212,
     "1970": 24293,
     "1971": 24950,
     "1972": 23243,
     "1973": 26256,
     "1974": 26757,
     "1975": 30187,
     "1976": 35139,
     "1977": 34921,
     "1978": 39828,
     "1979": 47258,
     "1980": 44613,
     "1981": 35264,
     "1982": 33810,
     "1983": 29861,
     "1984": 28931,
     "1985": 27509,
     "1986": 26295,
     "1987": 26561,
     "1988": 20340,
     "1989": 17982,
     "1990": 17198,
     "1991": 6212,
     "1992": 7802,
     "1993": 7575,
     "1994": 6983,
     "1995": 6161,
     "1996": 6724,
     "1997": 6265,
     "1998": 6983,
     "1999": 7349,
     "2000": 7920,
     "2001": 8389,
     "2002": 8730,
     "2003": 6644,
     "2004": 9923,
     "2005": 9829,
     "2006": 10122,
     "2007": 10056,
     "2008": 10608,
     "2009": 10671,
     "2010": 11024,
     "2011": 11484,
     "2012": 12652,
     "2013": 13158,
     "2014": 12817,
     "2015": 13014,
     "2016": 13898
  },
  "Italy": {
     "1": 1546,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 3302,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 3089,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 3568,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 3125,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 2974,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 2778,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 2849,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 3009,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 3125,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 2778,
     "1801": 2743,
     "1802": 2832,
     "1803": 2911,
     "1804": 3036,
     "1805": 2938,
     "1806": 3009,
     "1807": 3018,
     "1808": 3187,
     "1809": 3204,
     "1810": 2956,
     "1811": 2858,
     "1812": 2920,
     "1813": 3000,
     "1814": 3036,
     "1815": 2903,
     "1816": 2858,
     "1817": 2885,
     "1818": 3107,
     "1819": 3080,
     "1820": 3080,
     "1821": 3027,
     "1822": 3062,
     "1823": 3089,
     "1824": 3169,
     "1825": 3125,
     "1826": 3213,
     "1827": 3160,
     "1828": 3125,
     "1829": 3116,
     "1830": 3071,
     "1831": 3178,
     "1832": 3204,
     "1833": 3222,
     "1834": 3249,
     "1835": 3338,
     "1836": 3098,
     "1837": 3018,
     "1838": 3062,
     "1839": 3036,
     "1840": 3133,
     "1841": 3196,
     "1842": 3222,
     "1843": 3062,
     "1844": 3062,
     "1845": 3089,
     "1846": 3062,
     "1847": 2991,
     "1848": 3071,
     "1849": 3054,
     "1850": 3018,
     "1851": 3018,
     "1852": 2974,
     "1853": 2911,
     "1854": 2716,
     "1855": 2743,
     "1856": 2752,
     "1857": 2974,
     "1858": 3089,
     "1859": 2991,
     "1860": 2974,
     "1861": 2956,
     "1862": 2994,
     "1863": 3066,
     "1864": 3071,
     "1865": 3256,
     "1866": 3250,
     "1867": 2968,
     "1868": 3028,
     "1869": 3067,
     "1870": 3143,
     "1871": 3073,
     "1872": 3005,
     "1873": 2989,
     "1874": 3144,
     "1875": 3160,
     "1876": 3082,
     "1877": 3102,
     "1878": 3180,
     "1879": 3189,
     "1880": 3239,
     "1881": 3337,
     "1882": 3378,
     "1883": 3408,
     "1884": 3357,
     "1885": 3406,
     "1886": 3481,
     "1887": 3569,
     "1888": 3550,
     "1889": 3442,
     "1890": 3444,
     "1891": 3490,
     "1892": 3494,
     "1893": 3549,
     "1894": 3569,
     "1895": 3598,
     "1896": 3652,
     "1897": 3658,
     "1898": 3643,
     "1899": 3683,
     "1900": 3781,
     "1901": 3843,
     "1902": 3904,
     "1903": 3939,
     "1904": 4008,
     "1905": 4091,
     "1906": 4229,
     "1907": 4304,
     "1908": 4395,
     "1909": 4430,
     "1910": 4435,
     "1911": 4483,
     "1912": 4487,
     "1913": 4698,
     "1914": 4442,
     "1915": 4219,
     "1916": 4566,
     "1917": 4581,
     "1918": 4465,
     "1919": 4286,
     "1920": 4388,
     "1921": 4239,
     "1922": 4548,
     "1923": 4920,
     "1924": 5008,
     "1925": 5305,
     "1926": 5300,
     "1927": 5159,
     "1928": 5435,
     "1929": 5663,
     "1930": 5363,
     "1931": 5257,
     "1932": 5331,
     "1933": 5229,
     "1934": 5174,
     "1935": 5410,
     "1936": 5178,
     "1937": 5651,
     "1938": 5769,
     "1939": 6076,
     "1940": 5905,
     "1941": 5753,
     "1942": 5398,
     "1943": 4553,
     "1944": 3663,
     "1945": 3279,
     "1946": 4406,
     "1947": 5209,
     "1948": 5575,
     "1949": 6010,
     "1950": 6465,
     "1951": 6902,
     "1952": 7380,
     "1953": 7864,
     "1954": 8214,
     "1955": 8632,
     "1956": 8970,
     "1957": 9449,
     "1958": 9896,
     "1959": 10437,
     "1960": 10922,
     "1961": 11766,
     "1962": 12604,
     "1963": 13408,
     "1964": 13822,
     "1965": 14028,
     "1966": 14663,
     "1967": 15607,
     "1968": 16810,
     "1969": 17661,
     "1970": 17942,
     "1971": 18165,
     "1972": 18573,
     "1973": 19632,
     "1974": 20393,
     "1975": 19831,
     "1976": 21019,
     "1977": 21541,
     "1978": 22273,
     "1979": 23483,
     "1980": 24276,
     "1981": 24369,
     "1982": 24466,
     "1983": 24723,
     "1984": 25328,
     "1985": 26024,
     "1986": 26762,
     "1987": 27593,
     "1988": 28658,
     "1989": 29482,
     "1990": 30117,
     "1991": 30559,
     "1992": 30793,
     "1993": 30512,
     "1994": 31162,
     "1995": 32061,
     "1996": 32464,
     "1997": 33043,
     "1998": 33567,
     "1999": 34085,
     "2000": 35333,
     "2001": 35939,
     "2002": 35975,
     "2003": 35870,
     "2004": 36202,
     "2005": 36367,
     "2006": 36985,
     "2007": 37341,
     "2008": 36705,
     "2009": 34535,
     "2010": 35010,
     "2011": 35151,
     "2012": 34068,
     "2013": 33094,
     "2014": 32829,
     "2015": 33118,
     "2016": 33419
  },
  "Jamaica": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1364,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 1058,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 1045,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1032,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 1054,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 1076,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 1131,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 1189,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 1316,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 1362,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 1408,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 1893,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 1889,
     "1943": 2238,
     "1944": 0,
     "1945": 0,
     "1946": 3210,
     "1947": 2944,
     "1948": 0,
     "1949": 0,
     "1950": 2582,
     "1951": 2748,
     "1952": 2927,
     "1953": 3291,
     "1954": 3616,
     "1955": 3932,
     "1956": 4262,
     "1957": 4804,
     "1958": 4784,
     "1959": 4946,
     "1960": 5164,
     "1961": 5259,
     "1962": 5298,
     "1963": 5366,
     "1964": 5652,
     "1965": 5974,
     "1966": 6090,
     "1967": 6186,
     "1968": 6391,
     "1969": 6774,
     "1970": 7490,
     "1971": 7402,
     "1972": 7508,
     "1973": 8039,
     "1974": 7607,
     "1975": 7483,
     "1976": 6936,
     "1977": 6716,
     "1978": 6694,
     "1979": 6492,
     "1980": 6075,
     "1981": 6155,
     "1982": 6128,
     "1983": 6248,
     "1984": 6178,
     "1985": 5878,
     "1986": 5964,
     "1987": 6414,
     "1988": 6589,
     "1989": 7017,
     "1990": 7369,
     "1991": 7397,
     "1992": 7538,
     "1993": 7612,
     "1994": 7660,
     "1995": 7750,
     "1996": 7668,
     "1997": 7453,
     "1998": 7279,
     "1999": 7269,
     "2000": 7251,
     "2001": 7275,
     "2002": 7256,
     "2003": 7455,
     "2004": 7487,
     "2005": 7489,
     "2006": 7642,
     "2007": 7689,
     "2008": 7566,
     "2009": 7252,
     "2010": 7093,
     "2011": 7141,
     "2012": 7053,
     "2013": 7017,
     "2014": 7005,
     "2015": 7024,
     "2016": 7084
  },
  "Jordan": {
     "1": 1225,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1132,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1440,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 2058,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 3422,
     "1951": 3488,
     "1952": 3551,
     "1953": 3614,
     "1954": 3677,
     "1955": 3344,
     "1956": 4402,
     "1957": 4330,
     "1958": 4568,
     "1959": 4706,
     "1960": 4794,
     "1961": 5525,
     "1962": 5390,
     "1963": 5451,
     "1964": 6133,
     "1965": 6550,
     "1966": 6456,
     "1967": 6294,
     "1968": 5501,
     "1969": 5706,
     "1970": 4929,
     "1971": 4868,
     "1972": 4846,
     "1973": 4915,
     "1974": 5157,
     "1975": 5315,
     "1976": 6371,
     "1977": 6548,
     "1978": 7651,
     "1979": 8064,
     "1980": 9218,
     "1981": 9264,
     "1982": 9554,
     "1983": 9375,
     "1984": 9808,
     "1985": 9783,
     "1986": 10292,
     "1987": 10213,
     "1988": 9774,
     "1989": 8443,
     "1990": 7803,
     "1991": 7525,
     "1992": 8134,
     "1993": 8041,
     "1994": 8040,
     "1995": 8211,
     "1996": 8141,
     "1997": 8231,
     "1998": 8340,
     "1999": 8493,
     "2000": 8713,
     "2001": 9015,
     "2002": 9365,
     "2003": 9555,
     "2004": 10114,
     "2005": 10607,
     "2006": 11057,
     "2007": 11484,
     "2008": 11801,
     "2009": 11938,
     "2010": 11743,
     "2011": 11615,
     "2012": 11523,
     "2013": 11488,
     "2014": 11523,
     "2015": 11519,
     "2016": 11529
  },
  "Japan": {
     "1": 0,
     "730": 633,
     "1000": 0,
     "1150": 818,
     "1280": 829,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 829,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 903,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 879,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 990,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 941,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 1009,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 1072,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1160,
     "1871": 1168,
     "1872": 1174,
     "1873": 1182,
     "1874": 1190,
     "1875": 1274,
     "1876": 1235,
     "1877": 1263,
     "1878": 1249,
     "1879": 1315,
     "1880": 1359,
     "1881": 1305,
     "1882": 1328,
     "1883": 1317,
     "1884": 1315,
     "1885": 1354,
     "1886": 1442,
     "1887": 1497,
     "1888": 1416,
     "1889": 1468,
     "1890": 1592,
     "1891": 1505,
     "1892": 1594,
     "1893": 1587,
     "1894": 1762,
     "1895": 1768,
     "1896": 1654,
     "1897": 1671,
     "1898": 1965,
     "1899": 1799,
     "1900": 1856,
     "1901": 1899,
     "1902": 1776,
     "1903": 1877,
     "1904": 1870,
     "1905": 1820,
     "1906": 2041,
     "1907": 2085,
     "1908": 2075,
     "1909": 2047,
     "1910": 2052,
     "1911": 2133,
     "1912": 2178,
     "1913": 2182,
     "1914": 2087,
     "1915": 2250,
     "1916": 2565,
     "1917": 2620,
     "1918": 2625,
     "1919": 2875,
     "1920": 2668,
     "1921": 2926,
     "1922": 2882,
     "1923": 2847,
     "1924": 2890,
     "1925": 2967,
     "1926": 2945,
     "1927": 2942,
     "1928": 3135,
     "1929": 3188,
     "1930": 2912,
     "1931": 2891,
     "1932": 3087,
     "1933": 3340,
     "1934": 3302,
     "1935": 3337,
     "1936": 3532,
     "1937": 3643,
     "1938": 3854,
     "1939": 4431,
     "1940": 4523,
     "1941": 4521,
     "1942": 4435,
     "1943": 4440,
     "1944": 4184,
     "1945": 2118,
     "1946": 2273,
     "1947": 2425,
     "1948": 2715,
     "1949": 2832,
     "1950": 3023,
     "1951": 3345,
     "1952": 3677,
     "1953": 3894,
     "1954": 4063,
     "1955": 4360,
     "1956": 4639,
     "1957": 4935,
     "1958": 5176,
     "1959": 5593,
     "1960": 6273,
     "1961": 6965,
     "1962": 7516,
     "1963": 8071,
     "1964": 8919,
     "1965": 9338,
     "1966": 10237,
     "1967": 11255,
     "1968": 12563,
     "1969": 13965,
     "1970": 15286,
     "1971": 15800,
     "1972": 16891,
     "1973": 17993,
     "1974": 17537,
     "1975": 17851,
     "1976": 18363,
     "1977": 18984,
     "1978": 19804,
     "1979": 20714,
     "1980": 21130,
     "1981": 21644,
     "1982": 22154,
     "1983": 22514,
     "1984": 23247,
     "1985": 24126,
     "1986": 24674,
     "1987": 25574,
     "1988": 27042,
     "1989": 28235,
     "1990": 29567,
     "1991": 30462,
     "1992": 30620,
     "1993": 30582,
     "1994": 30813,
     "1995": 31577,
     "1996": 32485,
     "1997": 32756,
     "1998": 32300,
     "1999": 32158,
     "2000": 32988,
     "2001": 33044,
     "2002": 33013,
     "2003": 33456,
     "2004": 34167,
     "2005": 34730,
     "2006": 35225,
     "2007": 35805,
     "2008": 35431,
     "2009": 33549,
     "2010": 34990,
     "2011": 34979,
     "2012": 35580,
     "2013": 36354,
     "2014": 36537,
     "2015": 37031,
     "2016": 37465
  },
  "Republic of Korea": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 462,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 465,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 628,
     "1912": 649,
     "1913": 669,
     "1914": 695,
     "1915": 807,
     "1916": 784,
     "1917": 861,
     "1918": 921,
     "1919": 974,
     "1920": 841,
     "1921": 900,
     "1922": 820,
     "1923": 871,
     "1924": 869,
     "1925": 862,
     "1926": 887,
     "1927": 917,
     "1928": 916,
     "1929": 861,
     "1930": 808,
     "1931": 806,
     "1932": 800,
     "1933": 960,
     "1934": 952,
     "1935": 1030,
     "1936": 1106,
     "1937": 1202,
     "1938": 1246,
     "1939": 1108,
     "1940": 1232,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 1178,
     "1951": 1085,
     "1952": 1152,
     "1953": 1478,
     "1954": 1550,
     "1955": 1612,
     "1956": 1584,
     "1957": 1663,
     "1958": 1702,
     "1959": 1714,
     "1960": 1691,
     "1961": 1719,
     "1962": 1717,
     "1963": 1814,
     "1964": 1916,
     "1965": 1981,
     "1966": 2164,
     "1967": 2268,
     "1968": 2499,
     "1969": 2813,
     "1970": 2989,
     "1971": 3217,
     "1972": 3388,
     "1973": 3895,
     "1974": 4158,
     "1975": 4360,
     "1976": 4794,
     "1977": 5205,
     "1978": 5604,
     "1979": 5922,
     "1980": 5674,
     "1981": 5933,
     "1982": 6285,
     "1983": 6905,
     "1984": 7412,
     "1985": 7820,
     "1986": 8637,
     "1987": 9538,
     "1988": 10509,
     "1989": 11070,
     "1990": 12004,
     "1991": 13116,
     "1992": 13782,
     "1993": 14577,
     "1994": 15760,
     "1995": 17095,
     "1996": 18219,
     "1997": 19118,
     "1998": 17942,
     "1999": 19830,
     "2000": 21420,
     "2001": 22218,
     "2002": 23731,
     "2003": 24301,
     "2004": 25391,
     "2005": 26331,
     "2006": 27550,
     "2007": 28908,
     "2008": 29501,
     "2009": 29557,
     "2010": 31321,
     "2011": 32225,
     "2012": 32791,
     "2013": 33588,
     "2014": 34493,
     "2015": 35269,
     "2016": 36103
  },
  "Lebanon": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 2186,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 3053,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 4661,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 7807,
     "1951": 10386,
     "1952": 10741,
     "1953": 12035,
     "1954": 13444,
     "1955": 14136,
     "1956": 13437,
     "1957": 13306,
     "1958": 11112,
     "1959": 11730,
     "1960": 11717,
     "1961": 12154,
     "1962": 12279,
     "1963": 12042,
     "1964": 12412,
     "1965": 13253,
     "1966": 13735,
     "1967": 12694,
     "1968": 13866,
     "1969": 13760,
     "1970": 14284,
     "1971": 15189,
     "1972": 16592,
     "1973": 16932,
     "1974": 16904,
     "1975": 11577,
     "1976": 4924,
     "1977": 8907,
     "1978": 8505,
     "1979": 9147,
     "1980": 9292,
     "1981": 9173,
     "1982": 5694,
     "1983": 6860,
     "1984": 9734,
     "1985": 11885,
     "1986": 10890,
     "1987": 12505,
     "1988": 8841,
     "1989": 5016,
     "1990": 6224,
     "1991": 8437,
     "1992": 8709,
     "1993": 9218,
     "1994": 9855,
     "1995": 10400,
     "1996": 10838,
     "1997": 10504,
     "1998": 10797,
     "1999": 10671,
     "2000": 10693,
     "2001": 10944,
     "2002": 11140,
     "2003": 11327,
     "2004": 11991,
     "2005": 12137,
     "2006": 12097,
     "2007": 12888,
     "2008": 13794,
     "2009": 15009,
     "2010": 16153,
     "2011": 16230,
     "2012": 16315,
     "2013": 15055,
     "2014": 13416,
     "2015": 12953,
     "2016": 12972
  },
  "Sri Lanka": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 799,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 819,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1237,
     "1871": 1201,
     "1872": 1140,
     "1873": 1145,
     "1874": 1125,
     "1875": 1134,
     "1876": 1146,
     "1877": 1152,
     "1878": 1042,
     "1879": 1118,
     "1880": 1206,
     "1881": 1288,
     "1882": 1359,
     "1883": 1368,
     "1884": 1314,
     "1885": 1224,
     "1886": 1177,
     "1887": 1373,
     "1888": 1338,
     "1889": 1310,
     "1890": 1518,
     "1891": 1506,
     "1892": 1528,
     "1893": 1529,
     "1894": 1515,
     "1895": 1576,
     "1896": 1580,
     "1897": 1634,
     "1898": 1696,
     "1899": 1792,
     "1900": 1874,
     "1901": 1731,
     "1902": 1707,
     "1903": 1780,
     "1904": 1765,
     "1905": 1712,
     "1906": 1729,
     "1907": 1792,
     "1908": 1774,
     "1909": 1675,
     "1910": 1755,
     "1911": 1685,
     "1912": 1680,
     "1913": 1793,
     "1914": 1757,
     "1915": 1651,
     "1916": 1703,
     "1917": 1769,
     "1918": 1609,
     "1919": 1694,
     "1920": 1586,
     "1921": 1538,
     "1922": 1578,
     "1923": 1548,
     "1924": 1638,
     "1925": 1724,
     "1926": 1840,
     "1927": 1833,
     "1928": 1826,
     "1929": 1940,
     "1930": 1838,
     "1931": 1747,
     "1932": 1657,
     "1933": 1671,
     "1934": 1830,
     "1935": 1720,
     "1936": 1706,
     "1937": 1811,
     "1938": 1780,
     "1939": 1723,
     "1940": 1817,
     "1941": 1854,
     "1942": 1921,
     "1943": 1865,
     "1944": 1677,
     "1945": 1621,
     "1946": 1526,
     "1947": 1559,
     "1948": 1684,
     "1949": 1742,
     "1950": 1820,
     "1951": 1878,
     "1952": 1907,
     "1953": 1887,
     "1954": 1883,
     "1955": 1942,
     "1956": 1906,
     "1957": 1884,
     "1958": 1890,
     "1959": 1866,
     "1960": 1882,
     "1961": 1868,
     "1962": 1884,
     "1963": 1875,
     "1964": 1918,
     "1965": 1931,
     "1966": 1865,
     "1967": 1988,
     "1968": 2087,
     "1969": 2122,
     "1970": 2177,
     "1971": 2117,
     "1972": 2121,
     "1973": 2168,
     "1974": 2203,
     "1975": 2219,
     "1976": 2246,
     "1977": 2353,
     "1978": 2455,
     "1979": 2565,
     "1980": 2658,
     "1981": 2779,
     "1982": 2871,
     "1983": 2979,
     "1984": 3087,
     "1985": 3175,
     "1986": 3287,
     "1987": 3272,
     "1988": 3310,
     "1989": 3351,
     "1990": 3521,
     "1991": 3859,
     "1992": 3697,
     "1993": 3922,
     "1994": 4175,
     "1995": 4381,
     "1996": 4855,
     "1997": 5291,
     "1998": 5136,
     "1999": 5205,
     "2000": 5564,
     "2001": 5378,
     "2002": 5498,
     "2003": 5756,
     "2004": 6003,
     "2005": 6321,
     "2006": 6753,
     "2007": 7156,
     "2008": 7522,
     "2009": 7728,
     "2010": 8283,
     "2011": 8918,
     "2012": 9678,
     "2013": 9928,
     "2014": 10342,
     "2015": 10763,
     "2016": 11149
  },
  "Morocco": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 703,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 921,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 1162,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 2380,
     "1951": 2385,
     "1952": 2387,
     "1953": 2402,
     "1954": 2414,
     "1955": 2426,
     "1956": 2374,
     "1957": 2322,
     "1958": 2272,
     "1959": 2222,
     "1960": 2173,
     "1961": 2194,
     "1962": 2215,
     "1963": 2236,
     "1964": 2258,
     "1965": 2280,
     "1966": 2349,
     "1967": 2420,
     "1968": 2493,
     "1969": 2567,
     "1970": 2643,
     "1971": 2722,
     "1972": 2730,
     "1973": 2771,
     "1974": 2863,
     "1975": 2994,
     "1976": 3259,
     "1977": 3352,
     "1978": 3383,
     "1979": 3470,
     "1980": 3716,
     "1981": 3548,
     "1982": 3822,
     "1983": 3699,
     "1984": 3751,
     "1985": 3882,
     "1986": 4080,
     "1987": 3889,
     "1988": 4183,
     "1989": 4178,
     "1990": 4237,
     "1991": 4462,
     "1992": 4293,
     "1993": 4189,
     "1994": 4555,
     "1995": 4239,
     "1996": 4689,
     "1997": 4546,
     "1998": 4805,
     "1999": 4791,
     "2000": 4818,
     "2001": 5104,
     "2002": 5198,
     "2003": 5440,
     "2004": 5632,
     "2005": 5750,
     "2006": 6114,
     "2007": 6259,
     "2008": 6555,
     "2009": 6758,
     "2010": 6940,
     "2011": 7226,
     "2012": 7365,
     "2013": 7619,
     "2014": 7733,
     "2015": 8001,
     "2016": 8039
  },
  "Mexico": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 683,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 721,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 888,
     "1596": 896,
     "1597": 881,
     "1598": 883,
     "1599": 912,
     "1600": 950,
     "1601": 965,
     "1602": 978,
     "1603": 994,
     "1604": 1015,
     "1605": 1032,
     "1606": 1062,
     "1607": 1079,
     "1608": 1114,
     "1609": 1148,
     "1610": 1161,
     "1611": 1166,
     "1612": 1194,
     "1613": 1201,
     "1614": 1196,
     "1615": 1191,
     "1616": 1183,
     "1617": 1187,
     "1618": 1193,
     "1619": 1175,
     "1620": 1194,
     "1621": 1206,
     "1622": 1218,
     "1623": 1217,
     "1624": 1208,
     "1625": 1217,
     "1626": 1250,
     "1627": 1284,
     "1628": 1283,
     "1629": 1297,
     "1630": 1333,
     "1631": 1333,
     "1632": 1331,
     "1633": 1333,
     "1634": 1331,
     "1635": 1354,
     "1636": 1373,
     "1637": 1343,
     "1638": 1309,
     "1639": 1299,
     "1640": 1298,
     "1641": 1299,
     "1642": 1301,
     "1643": 1298,
     "1644": 1294,
     "1645": 1287,
     "1646": 1284,
     "1647": 1297,
     "1648": 1330,
     "1649": 1365,
     "1650": 1394,
     "1651": 1386,
     "1652": 1390,
     "1653": 1387,
     "1654": 1387,
     "1655": 1391,
     "1656": 1383,
     "1657": 1364,
     "1658": 1366,
     "1659": 1379,
     "1660": 1395,
     "1661": 1407,
     "1662": 1404,
     "1663": 1401,
     "1664": 1449,
     "1665": 1522,
     "1666": 1590,
     "1667": 1641,
     "1668": 1653,
     "1669": 1673,
     "1670": 1700,
     "1671": 1726,
     "1672": 1735,
     "1673": 1746,
     "1674": 1755,
     "1675": 1725,
     "1676": 1663,
     "1677": 1609,
     "1678": 1579,
     "1679": 1569,
     "1680": 1548,
     "1681": 1495,
     "1682": 1457,
     "1683": 1444,
     "1684": 1451,
     "1685": 1497,
     "1686": 1516,
     "1687": 1511,
     "1688": 1513,
     "1689": 1531,
     "1690": 1523,
     "1691": 1508,
     "1692": 1552,
     "1693": 1579,
     "1694": 1638,
     "1695": 1646,
     "1696": 1644,
     "1697": 1659,
     "1698": 1686,
     "1699": 1718,
     "1700": 1758,
     "1701": 1784,
     "1702": 1835,
     "1703": 1862,
     "1704": 1831,
     "1705": 1759,
     "1706": 1744,
     "1707": 1705,
     "1708": 1675,
     "1709": 1716,
     "1710": 1774,
     "1711": 1804,
     "1712": 1833,
     "1713": 1816,
     "1714": 1775,
     "1715": 1798,
     "1716": 1820,
     "1717": 1821,
     "1718": 1830,
     "1719": 1854,
     "1720": 1813,
     "1721": 1728,
     "1722": 1648,
     "1723": 1613,
     "1724": 1608,
     "1725": 1609,
     "1726": 1594,
     "1727": 1589,
     "1728": 1574,
     "1729": 1571,
     "1730": 1582,
     "1731": 1611,
     "1732": 1618,
     "1733": 1612,
     "1734": 1594,
     "1735": 1583,
     "1736": 1558,
     "1737": 1551,
     "1738": 1557,
     "1739": 1571,
     "1740": 1577,
     "1741": 1554,
     "1742": 1520,
     "1743": 1511,
     "1744": 1491,
     "1745": 1478,
     "1746": 1494,
     "1747": 1535,
     "1748": 1563,
     "1749": 1555,
     "1750": 1543,
     "1751": 1548,
     "1752": 1620,
     "1753": 1664,
     "1754": 1686,
     "1755": 1702,
     "1756": 1731,
     "1757": 1741,
     "1758": 1744,
     "1759": 1745,
     "1760": 1758,
     "1761": 1769,
     "1762": 1771,
     "1763": 1688,
     "1764": 1657,
     "1765": 1654,
     "1766": 1672,
     "1767": 1669,
     "1768": 1659,
     "1769": 1646,
     "1770": 1653,
     "1771": 1631,
     "1772": 1646,
     "1773": 1659,
     "1774": 1706,
     "1775": 1714,
     "1776": 1721,
     "1777": 1724,
     "1778": 1750,
     "1779": 1759,
     "1780": 1738,
     "1781": 1695,
     "1782": 1673,
     "1783": 1610,
     "1784": 1563,
     "1785": 1542,
     "1786": 1544,
     "1787": 1551,
     "1788": 1550,
     "1789": 1552,
     "1790": 1544,
     "1791": 1585,
     "1792": 1611,
     "1793": 1635,
     "1794": 1649,
     "1795": 1654,
     "1796": 1645,
     "1797": 1616,
     "1798": 1597,
     "1799": 1575,
     "1800": 1555,
     "1801": 1545,
     "1802": 1516,
     "1803": 1505,
     "1804": 1477,
     "1805": 1456,
     "1806": 1431,
     "1807": 1395,
     "1808": 1360,
     "1809": 1324,
     "1810": 1277,
     "1811": 1244,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1200,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 1255,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 1097,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1246,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 1868,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 2092,
     "1896": 2126,
     "1897": 2236,
     "1898": 2332,
     "1899": 2184,
     "1900": 2169,
     "1901": 2331,
     "1902": 2140,
     "1903": 2356,
     "1904": 2372,
     "1905": 2593,
     "1906": 2535,
     "1907": 2656,
     "1908": 2623,
     "1909": 2672,
     "1910": 2667,
     "1911": 2560,
     "1912": 2539,
     "1913": 2385,
     "1914": 2390,
     "1915": 2355,
     "1916": 2536,
     "1917": 2645,
     "1918": 2748,
     "1919": 2857,
     "1920": 3039,
     "1921": 3028,
     "1922": 3050,
     "1923": 3105,
     "1924": 3005,
     "1925": 3143,
     "1926": 3281,
     "1927": 3084,
     "1928": 3053,
     "1929": 2886,
     "1930": 2659,
     "1931": 2701,
     "1932": 2251,
     "1933": 2466,
     "1934": 2590,
     "1935": 2738,
     "1936": 2909,
     "1937": 2955,
     "1938": 2951,
     "1939": 3059,
     "1940": 3048,
     "1941": 3261,
     "1942": 3354,
     "1943": 3386,
     "1944": 3569,
     "1945": 3583,
     "1946": 3720,
     "1947": 3746,
     "1948": 3797,
     "1949": 3900,
     "1950": 4179,
     "1951": 4373,
     "1952": 4413,
     "1953": 4289,
     "1954": 4586,
     "1955": 4834,
     "1956": 5016,
     "1957": 5241,
     "1958": 5359,
     "1959": 5354,
     "1960": 5624,
     "1961": 5724,
     "1962": 5812,
     "1963": 6094,
     "1964": 6614,
     "1965": 6832,
     "1966": 7085,
     "1967": 7301,
     "1968": 7658,
     "1969": 7894,
     "1970": 8184,
     "1971": 8230,
     "1972": 8639,
     "1973": 9046,
     "1974": 9289,
     "1975": 9539,
     "1976": 9681,
     "1977": 9737,
     "1978": 10348,
     "1979": 11081,
     "1980": 11823,
     "1981": 12547,
     "1982": 12193,
     "1983": 11498,
     "1984": 11643,
     "1985": 11652,
     "1986": 11054,
     "1987": 11022,
     "1988": 10946,
     "1989": 11183,
     "1990": 11549,
     "1991": 11818,
     "1992": 12017,
     "1993": 12030,
     "1994": 12348,
     "1995": 11360,
     "1996": 11766,
     "1997": 12391,
     "1998": 12824,
     "1999": 13142,
     "2000": 13821,
     "2001": 13655,
     "2002": 13628,
     "2003": 13674,
     "2004": 14084,
     "2005": 14354,
     "2006": 14894,
     "2007": 15181,
     "2008": 15182,
     "2009": 14262,
     "2010": 14820,
     "2011": 15210,
     "2012": 15639,
     "2013": 15680,
     "2014": 15856,
     "2015": 16096,
     "2016": 16133
  },
  "Myanmar": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 594,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 593,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 823,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 667,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 714,
     "1912": 0,
     "1913": 807,
     "1914": 0,
     "1915": 0,
     "1916": 969,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 837,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 958,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 1063,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 987,
     "1937": 0,
     "1938": 871,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 466,
     "1951": 526,
     "1952": 529,
     "1953": 535,
     "1954": 494,
     "1955": 549,
     "1956": 577,
     "1957": 601,
     "1958": 575,
     "1959": 653,
     "1960": 664,
     "1961": 668,
     "1962": 714,
     "1963": 721,
     "1964": 721,
     "1965": 726,
     "1966": 683,
     "1967": 690,
     "1968": 721,
     "1969": 737,
     "1970": 756,
     "1971": 765,
     "1972": 756,
     "1973": 739,
     "1974": 764,
     "1975": 781,
     "1976": 814,
     "1977": 848,
     "1978": 890,
     "1979": 917,
     "1980": 975,
     "1981": 1012,
     "1982": 1047,
     "1983": 1071,
     "1984": 1103,
     "1985": 1113,
     "1986": 1082,
     "1987": 1021,
     "1988": 892,
     "1989": 912,
     "1990": 925,
     "1991": 903,
     "1992": 976,
     "1993": 1019,
     "1994": 1076,
     "1995": 1130,
     "1996": 1183,
     "1997": 1231,
     "1998": 1283,
     "1999": 1403,
     "2000": 1573,
     "2001": 1728,
     "2002": 1911,
     "2003": 2148,
     "2004": 2409,
     "2005": 2703,
     "2006": 3021,
     "2007": 3344,
     "2008": 3434,
     "2009": 3569,
     "2010": 3719,
     "2011": 3884,
     "2012": 4124,
     "2013": 4424,
     "2014": 4728,
     "2015": 5021,
     "2016": 5284
  },
  "Malaysia": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1120,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1230,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 1487,
     "1912": 1526,
     "1913": 1672,
     "1914": 1709,
     "1915": 1741,
     "1916": 1850,
     "1917": 1920,
     "1918": 1801,
     "1919": 2150,
     "1920": 2062,
     "1921": 1996,
     "1922": 2139,
     "1923": 2061,
     "1924": 1969,
     "1925": 2231,
     "1926": 2445,
     "1927": 2324,
     "1928": 2581,
     "1929": 3125,
     "1930": 3038,
     "1931": 2876,
     "1932": 2594,
     "1933": 2674,
     "1934": 2860,
     "1935": 2534,
     "1936": 2745,
     "1937": 2430,
     "1938": 2529,
     "1939": 2989,
     "1940": 2374,
     "1941": 2300,
     "1942": 3107,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 1986,
     "1948": 2201,
     "1949": 2843,
     "1950": 2896,
     "1951": 2675,
     "1952": 2733,
     "1953": 2674,
     "1954": 2768,
     "1955": 2712,
     "1956": 2796,
     "1957": 2701,
     "1958": 2624,
     "1959": 2725,
     "1960": 2842,
     "1961": 2957,
     "1962": 3040,
     "1963": 3100,
     "1964": 3209,
     "1965": 3351,
     "1966": 3429,
     "1967": 3399,
     "1968": 3607,
     "1969": 3725,
     "1970": 3862,
     "1971": 4050,
     "1972": 4252,
     "1973": 4755,
     "1974": 4993,
     "1975": 4919,
     "1976": 5405,
     "1977": 5713,
     "1978": 6074,
     "1979": 6420,
     "1980": 6792,
     "1981": 7102,
     "1982": 7344,
     "1983": 7607,
     "1984": 8000,
     "1985": 7720,
     "1986": 7623,
     "1987": 7835,
     "1988": 8323,
     "1989": 8895,
     "1990": 9529,
     "1991": 10158,
     "1992": 10776,
     "1993": 11551,
     "1994": 12299,
     "1995": 13173,
     "1996": 14131,
     "1997": 14776,
     "1998": 13334,
     "1999": 13790,
     "2000": 14598,
     "2001": 14312,
     "2002": 14745,
     "2003": 15223,
     "2004": 15876,
     "2005": 16350,
     "2006": 16960,
     "2007": 17718,
     "2008": 18260,
     "2009": 17686,
     "2010": 18710,
     "2011": 19390,
     "2012": 20134,
     "2013": 20760,
     "2014": 21683,
     "2015": 22432,
     "2016": 23053
  },
  "Netherlands": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 3334,
     "1808": 2971,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 3400,
     "1816": 3239,
     "1817": 3271,
     "1818": 3322,
     "1819": 3169,
     "1820": 3395,
     "1821": 3327,
     "1822": 3358,
     "1823": 3524,
     "1824": 3499,
     "1825": 3411,
     "1826": 3465,
     "1827": 3669,
     "1828": 3747,
     "1829": 3753,
     "1830": 3429,
     "1831": 3685,
     "1832": 3857,
     "1833": 3771,
     "1834": 3805,
     "1835": 3794,
     "1836": 3919,
     "1837": 4011,
     "1838": 4053,
     "1839": 4051,
     "1840": 4090,
     "1841": 4130,
     "1842": 4029,
     "1843": 3959,
     "1844": 3997,
     "1845": 3968,
     "1846": 3974,
     "1847": 4001,
     "1848": 4067,
     "1849": 4188,
     "1850": 4266,
     "1851": 4328,
     "1852": 4278,
     "1853": 4182,
     "1854": 4437,
     "1855": 4316,
     "1856": 4489,
     "1857": 4410,
     "1858": 4289,
     "1859": 4136,
     "1860": 4334,
     "1861": 4216,
     "1862": 4382,
     "1863": 4463,
     "1864": 4640,
     "1865": 4649,
     "1866": 4823,
     "1867": 4659,
     "1868": 4687,
     "1869": 4839,
     "1870": 4992,
     "1871": 4928,
     "1872": 4982,
     "1873": 5084,
     "1874": 4896,
     "1875": 5176,
     "1876": 5189,
     "1877": 5250,
     "1878": 5235,
     "1879": 4983,
     "1880": 5268,
     "1881": 5299,
     "1882": 5372,
     "1883": 5704,
     "1884": 5741,
     "1885": 5799,
     "1886": 5820,
     "1887": 5897,
     "1888": 5906,
     "1889": 6040,
     "1890": 5733,
     "1891": 5684,
     "1892": 5792,
     "1893": 5667,
     "1894": 5924,
     "1895": 5885,
     "1896": 6050,
     "1897": 6119,
     "1898": 6131,
     "1899": 6137,
     "1900": 5990,
     "1901": 6191,
     "1902": 6356,
     "1903": 6258,
     "1904": 6255,
     "1905": 6474,
     "1906": 6514,
     "1907": 6313,
     "1908": 6354,
     "1909": 6606,
     "1910": 6807,
     "1911": 6951,
     "1912": 7050,
     "1913": 7285,
     "1914": 6961,
     "1915": 7064,
     "1916": 7119,
     "1917": 6526,
     "1918": 6032,
     "1919": 7417,
     "1920": 7593,
     "1921": 7974,
     "1922": 8276,
     "1923": 8340,
     "1924": 8809,
     "1925": 9053,
     "1926": 9641,
     "1927": 9905,
     "1928": 10292,
     "1929": 10237,
     "1930": 10081,
     "1931": 9330,
     "1932": 9061,
     "1933": 8919,
     "1934": 8646,
     "1935": 8870,
     "1936": 9339,
     "1937": 9776,
     "1938": 9446,
     "1939": 9976,
     "1940": 8694,
     "1941": 8154,
     "1942": 7390,
     "1943": 7163,
     "1944": 4767,
     "1945": 4834,
     "1946": 8019,
     "1947": 9084,
     "1948": 9879,
     "1949": 10582,
     "1950": 10790,
     "1951": 10854,
     "1952": 10947,
     "1953": 11773,
     "1954": 12429,
     "1955": 13182,
     "1956": 13493,
     "1957": 13700,
     "1958": 13464,
     "1959": 13922,
     "1960": 14911,
     "1961": 14758,
     "1962": 15546,
     "1963": 15893,
     "1964": 16982,
     "1965": 17631,
     "1966": 17880,
     "1967": 18609,
     "1968": 19604,
     "1969": 20625,
     "1970": 21534,
     "1971": 22168,
     "1972": 22668,
     "1973": 23539,
     "1974": 24288,
     "1975": 24065,
     "1976": 24985,
     "1977": 25510,
     "1978": 25955,
     "1979": 26357,
     "1980": 26460,
     "1981": 26137,
     "1982": 25717,
     "1983": 26062,
     "1984": 26811,
     "1985": 27501,
     "1986": 28102,
     "1987": 28318,
     "1988": 28869,
     "1989": 30042,
     "1990": 31062,
     "1991": 31570,
     "1992": 31867,
     "1993": 32043,
     "1994": 32794,
     "1995": 33649,
     "1996": 34689,
     "1997": 35995,
     "1998": 37392,
     "1999": 39021,
     "2000": 40385,
     "2001": 40933,
     "2002": 40715,
     "2003": 40638,
     "2004": 41319,
     "2005": 42113,
     "2006": 43525,
     "2007": 45037,
     "2008": 45624,
     "2009": 43680,
     "2010": 44066,
     "2011": 44591,
     "2012": 43957,
     "2013": 43744,
     "2014": 44205,
     "2015": 44869,
     "2016": 45600
  },
  "Norway": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 2614,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 2724,
     "1831": 2560,
     "1832": 2633,
     "1833": 2850,
     "1834": 2886,
     "1835": 2840,
     "1836": 2773,
     "1837": 2766,
     "1838": 2804,
     "1839": 2825,
     "1840": 3038,
     "1841": 3011,
     "1842": 2987,
     "1843": 2874,
     "1844": 2963,
     "1845": 3082,
     "1846": 3061,
     "1847": 3157,
     "1848": 2899,
     "1849": 2947,
     "1850": 3068,
     "1851": 3143,
     "1852": 3189,
     "1853": 3356,
     "1854": 3514,
     "1855": 3585,
     "1856": 3515,
     "1857": 3332,
     "1858": 3526,
     "1859": 3434,
     "1860": 3569,
     "1861": 3553,
     "1862": 3794,
     "1863": 3703,
     "1864": 3755,
     "1865": 3928,
     "1866": 3735,
     "1867": 3764,
     "1868": 3734,
     "1869": 3929,
     "1870": 4117,
     "1871": 4303,
     "1872": 4425,
     "1873": 4577,
     "1874": 4440,
     "1875": 4555,
     "1876": 4684,
     "1877": 4632,
     "1878": 4416,
     "1879": 4592,
     "1880": 4729,
     "1881": 4611,
     "1882": 4779,
     "1883": 4881,
     "1884": 4761,
     "1885": 4723,
     "1886": 4778,
     "1887": 4858,
     "1888": 5089,
     "1889": 5245,
     "1890": 5233,
     "1891": 5221,
     "1892": 5194,
     "1893": 5330,
     "1894": 5548,
     "1895": 5770,
     "1896": 5862,
     "1897": 6083,
     "1898": 6174,
     "1899": 6132,
     "1900": 6141,
     "1901": 6184,
     "1902": 6176,
     "1903": 6218,
     "1904": 6217,
     "1905": 6280,
     "1906": 6607,
     "1907": 6687,
     "1908": 6827,
     "1909": 6885,
     "1910": 7231,
     "1911": 7460,
     "1912": 7656,
     "1913": 8345,
     "1914": 8196,
     "1915": 8333,
     "1916": 9080,
     "1917": 8103,
     "1918": 7604,
     "1919": 8704,
     "1920": 8958,
     "1921": 7981,
     "1922": 8639,
     "1923": 9205,
     "1924": 9048,
     "1925": 8945,
     "1926": 8978,
     "1927": 9437,
     "1928": 9907,
     "1929": 10548,
     "1930": 10929,
     "1931": 10014,
     "1932": 10445,
     "1933": 10647,
     "1934": 10962,
     "1935": 11449,
     "1936": 12158,
     "1937": 12634,
     "1938": 12842,
     "1939": 13380,
     "1940": 12167,
     "1941": 12391,
     "1942": 11835,
     "1943": 11511,
     "1944": 10810,
     "1945": 11992,
     "1946": 13063,
     "1947": 14656,
     "1948": 15499,
     "1949": 15750,
     "1950": 16360,
     "1951": 17085,
     "1952": 17518,
     "1953": 18034,
     "1954": 18758,
     "1955": 18987,
     "1956": 19812,
     "1957": 20222,
     "1958": 20044,
     "1959": 20713,
     "1960": 21705,
     "1961": 22883,
     "1962": 23340,
     "1963": 24050,
     "1964": 25057,
     "1965": 26183,
     "1966": 26953,
     "1967": 28392,
     "1968": 28779,
     "1969": 29828,
     "1970": 30213,
     "1971": 31554,
     "1972": 32909,
     "1973": 34122,
     "1974": 35332,
     "1975": 36975,
     "1976": 38959,
     "1977": 40451,
     "1978": 41702,
     "1979": 43422,
     "1980": 45426,
     "1981": 45707,
     "1982": 45634,
     "1983": 47112,
     "1984": 49754,
     "1985": 52186,
     "1986": 53880,
     "1987": 54731,
     "1988": 54414,
     "1989": 54708,
     "1990": 55640,
     "1991": 57084,
     "1992": 58784,
     "1993": 60097,
     "1994": 62777,
     "1995": 65046,
     "1996": 67972,
     "1997": 71177,
     "1998": 72611,
     "1999": 73567,
     "2000": 75434,
     "2001": 76619,
     "2002": 77302,
     "2003": 77557,
     "2004": 80152,
     "2005": 81698,
     "2006": 82984,
     "2007": 84535,
     "2008": 83809,
     "2009": 81416,
     "2010": 80892,
     "2011": 80623,
     "2012": 81759,
     "2013": 81583,
     "2014": 82216,
     "2015": 82713,
     "2016": 82814
  },
  "New Zealand": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 1885,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 3643,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 5104,
     "1871": 5195,
     "1872": 5801,
     "1873": 6308,
     "1874": 6329,
     "1875": 6073,
     "1876": 5966,
     "1877": 6558,
     "1878": 7033,
     "1879": 5877,
     "1880": 6169,
     "1881": 6199,
     "1882": 6003,
     "1883": 5755,
     "1884": 6097,
     "1885": 5907,
     "1886": 5931,
     "1887": 5935,
     "1888": 5853,
     "1889": 6095,
     "1890": 6184,
     "1891": 6144,
     "1892": 6258,
     "1893": 6238,
     "1894": 5894,
     "1895": 5997,
     "1896": 6567,
     "1897": 6504,
     "1898": 6562,
     "1899": 6654,
     "1900": 7078,
     "1901": 6955,
     "1902": 7309,
     "1903": 7785,
     "1904": 7526,
     "1905": 7986,
     "1906": 8493,
     "1907": 8793,
     "1908": 7962,
     "1909": 7855,
     "1910": 8754,
     "1911": 9047,
     "1912": 8578,
     "1913": 8484,
     "1914": 8545,
     "1915": 8520,
     "1916": 8432,
     "1917": 8247,
     "1918": 8087,
     "1919": 8699,
     "1920": 9289,
     "1921": 8444,
     "1922": 7972,
     "1923": 8471,
     "1924": 8469,
     "1925": 8714,
     "1926": 8077,
     "1927": 7711,
     "1928": 8465,
     "1929": 8665,
     "1930": 8168,
     "1931": 7369,
     "1932": 7126,
     "1933": 7535,
     "1934": 7851,
     "1935": 8167,
     "1936": 9616,
     "1937": 10047,
     "1938": 10641,
     "1939": 10637,
     "1940": 10375,
     "1941": 10092,
     "1942": 11134,
     "1943": 11408,
     "1944": 11309,
     "1945": 11409,
     "1946": 11792,
     "1947": 12920,
     "1948": 11410,
     "1949": 12384,
     "1950": 13924,
     "1951": 12603,
     "1952": 12838,
     "1953": 12936,
     "1954": 14398,
     "1955": 14368,
     "1956": 14820,
     "1957": 14894,
     "1958": 15126,
     "1959": 15858,
     "1960": 15586,
     "1961": 16047,
     "1962": 16023,
     "1963": 16684,
     "1964": 17156,
     "1965": 17914,
     "1966": 18710,
     "1967": 17590,
     "1968": 17365,
     "1969": 18955,
     "1970": 18425,
     "1971": 19062,
     "1972": 19514,
     "1973": 20459,
     "1974": 21208,
     "1975": 20565,
     "1976": 20828,
     "1977": 19742,
     "1978": 19816,
     "1979": 20228,
     "1980": 20331,
     "1981": 21216,
     "1982": 21401,
     "1983": 21711,
     "1984": 22504,
     "1985": 22501,
     "1986": 22855,
     "1987": 22875,
     "1988": 22750,
     "1989": 22838,
     "1990": 22539,
     "1991": 21791,
     "1992": 21723,
     "1993": 22517,
     "1994": 23693,
     "1995": 24348,
     "1996": 24893,
     "1997": 25346,
     "1998": 25367,
     "1999": 26353,
     "2000": 27284,
     "2001": 27591,
     "2002": 28460,
     "2003": 29236,
     "2004": 30132,
     "2005": 30553,
     "2006": 31038,
     "2007": 31982,
     "2008": 31591,
     "2009": 31330,
     "2010": 31635,
     "2011": 32057,
     "2012": 32674,
     "2013": 32989,
     "2014": 33339,
     "2015": 33696,
     "2016": 34295
  },
  "Panama": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 0,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 2412,
     "1907": 2518,
     "1908": 2653,
     "1909": 2805,
     "1910": 3006,
     "1911": 3089,
     "1912": 3104,
     "1913": 3101,
     "1914": 3268,
     "1915": 3246,
     "1916": 3060,
     "1917": 2895,
     "1918": 2787,
     "1919": 2685,
     "1920": 2591,
     "1921": 2511,
     "1922": 2544,
     "1923": 2566,
     "1924": 2640,
     "1925": 2663,
     "1926": 2665,
     "1927": 2679,
     "1928": 2711,
     "1929": 2760,
     "1930": 2614,
     "1931": 2525,
     "1932": 2503,
     "1933": 2506,
     "1934": 2533,
     "1935": 2724,
     "1936": 2657,
     "1937": 2868,
     "1938": 2782,
     "1939": 2842,
     "1940": 2809,
     "1941": 3084,
     "1942": 3069,
     "1943": 3174,
     "1944": 3344,
     "1945": 3572,
     "1946": 3072,
     "1947": 3136,
     "1948": 2886,
     "1949": 2895,
     "1950": 2731,
     "1951": 2639,
     "1952": 2710,
     "1953": 2808,
     "1954": 2841,
     "1955": 2930,
     "1956": 3005,
     "1957": 3236,
     "1958": 3195,
     "1959": 3310,
     "1960": 3408,
     "1961": 3669,
     "1962": 3864,
     "1963": 4108,
     "1964": 4163,
     "1965": 4398,
     "1966": 4589,
     "1967": 4830,
     "1968": 5034,
     "1969": 5273,
     "1970": 5437,
     "1971": 5797,
     "1972": 5903,
     "1973": 6060,
     "1974": 6035,
     "1975": 5985,
     "1976": 5939,
     "1977": 5841,
     "1978": 6301,
     "1979": 6437,
     "1980": 7257,
     "1981": 7749,
     "1982": 7888,
     "1983": 7613,
     "1984": 7621,
     "1985": 7845,
     "1986": 8030,
     "1987": 8109,
     "1988": 7145,
     "1989": 7116,
     "1990": 7543,
     "1991": 8094,
     "1992": 8587,
     "1993": 8880,
     "1994": 8959,
     "1995": 8942,
     "1996": 9017,
     "1997": 9411,
     "1998": 9909,
     "1999": 10114,
     "2000": 10204,
     "2001": 10079,
     "2002": 10128,
     "2003": 10380,
     "2004": 10979,
     "2005": 11579,
     "2006": 12365,
     "2007": 13642,
     "2008": 14587,
     "2009": 14596,
     "2010": 15211,
     "2011": 16762,
     "2012": 18051,
     "2013": 18979,
     "2014": 19855,
     "2015": 20723,
     "2016": 21449
  },
  "Peru": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 930,
     "1596": 958,
     "1597": 964,
     "1598": 1034,
     "1599": 989,
     "1600": 975,
     "1601": 1014,
     "1602": 1019,
     "1603": 971,
     "1604": 962,
     "1605": 963,
     "1606": 966,
     "1607": 984,
     "1608": 990,
     "1609": 1097,
     "1610": 1138,
     "1611": 1068,
     "1612": 1117,
     "1613": 1092,
     "1614": 1117,
     "1615": 1086,
     "1616": 1040,
     "1617": 1056,
     "1618": 1053,
     "1619": 1057,
     "1620": 1052,
     "1621": 1055,
     "1622": 1031,
     "1623": 1022,
     "1624": 1056,
     "1625": 1054,
     "1626": 1100,
     "1627": 1117,
     "1628": 1127,
     "1629": 1123,
     "1630": 1106,
     "1631": 1131,
     "1632": 1150,
     "1633": 1172,
     "1634": 1162,
     "1635": 1271,
     "1636": 1271,
     "1637": 1235,
     "1638": 1208,
     "1639": 1197,
     "1640": 1134,
     "1641": 1256,
     "1642": 1239,
     "1643": 1246,
     "1644": 1167,
     "1645": 1156,
     "1646": 1161,
     "1647": 1092,
     "1648": 1180,
     "1649": 1150,
     "1650": 1123,
     "1651": 1065,
     "1652": 1081,
     "1653": 1098,
     "1654": 1111,
     "1655": 1067,
     "1656": 1080,
     "1657": 1091,
     "1658": 1113,
     "1659": 1006,
     "1660": 950,
     "1661": 970,
     "1662": 1000,
     "1663": 1049,
     "1664": 1007,
     "1665": 1072,
     "1666": 1257,
     "1667": 1369,
     "1668": 1322,
     "1669": 981,
     "1670": 1149,
     "1671": 1089,
     "1672": 1112,
     "1673": 1123,
     "1674": 1082,
     "1675": 1078,
     "1676": 1175,
     "1677": 1224,
     "1678": 1159,
     "1679": 1321,
     "1680": 1140,
     "1681": 1187,
     "1682": 1158,
     "1683": 1204,
     "1684": 1219,
     "1685": 1118,
     "1686": 1093,
     "1687": 981,
     "1688": 998,
     "1689": 981,
     "1690": 999,
     "1691": 1048,
     "1692": 1051,
     "1693": 993,
     "1694": 1174,
     "1695": 1200,
     "1696": 1151,
     "1697": 1096,
     "1698": 1181,
     "1699": 1224,
     "1700": 1224,
     "1701": 1153,
     "1702": 1103,
     "1703": 1191,
     "1704": 1149,
     "1705": 1257,
     "1706": 1114,
     "1707": 1231,
     "1708": 1376,
     "1709": 1376,
     "1710": 1244,
     "1711": 1285,
     "1712": 1223,
     "1713": 1223,
     "1714": 1198,
     "1715": 1120,
     "1716": 1209,
     "1717": 1234,
     "1718": 1191,
     "1719": 1165,
     "1720": 1163,
     "1721": 1115,
     "1722": 1166,
     "1723": 1282,
     "1724": 1211,
     "1725": 1290,
     "1726": 1132,
     "1727": 1297,
     "1728": 1235,
     "1729": 1244,
     "1730": 1209,
     "1731": 1228,
     "1732": 1034,
     "1733": 1172,
     "1734": 1179,
     "1735": 1284,
     "1736": 1306,
     "1737": 1226,
     "1738": 1266,
     "1739": 1250,
     "1740": 1305,
     "1741": 1360,
     "1742": 1300,
     "1743": 1297,
     "1744": 1196,
     "1745": 1128,
     "1746": 1180,
     "1747": 1119,
     "1748": 1109,
     "1749": 1179,
     "1750": 1168,
     "1751": 1195,
     "1752": 1070,
     "1753": 1100,
     "1754": 1039,
     "1755": 1034,
     "1756": 1073,
     "1757": 1168,
     "1758": 1238,
     "1759": 1310,
     "1760": 1254,
     "1761": 1281,
     "1762": 1284,
     "1763": 1280,
     "1764": 1351,
     "1765": 1438,
     "1766": 1421,
     "1767": 1420,
     "1768": 1411,
     "1769": 1394,
     "1770": 1384,
     "1771": 1382,
     "1772": 1381,
     "1773": 1247,
     "1774": 1364,
     "1775": 1305,
     "1776": 1351,
     "1777": 1271,
     "1778": 1256,
     "1779": 1258,
     "1780": 1237,
     "1781": 1094,
     "1782": 1113,
     "1783": 1199,
     "1784": 1197,
     "1785": 1098,
     "1786": 1178,
     "1787": 1190,
     "1788": 1350,
     "1789": 1295,
     "1790": 1215,
     "1791": 1318,
     "1792": 1381,
     "1793": 1343,
     "1794": 1434,
     "1795": 1183,
     "1796": 1095,
     "1797": 1115,
     "1798": 1117,
     "1799": 1121,
     "1800": 1120,
     "1801": 1060,
     "1802": 1062,
     "1803": 1041,
     "1804": 1035,
     "1805": 1065,
     "1806": 1100,
     "1807": 1110,
     "1808": 1090,
     "1809": 1080,
     "1810": 1087,
     "1811": 1015,
     "1812": 1023,
     "1813": 989,
     "1814": 968,
     "1815": 941,
     "1816": 959,
     "1817": 938,
     "1818": 940,
     "1819": 918,
     "1820": 883,
     "1821": 727,
     "1822": 693,
     "1823": 717,
     "1824": 690,
     "1825": 709,
     "1826": 675,
     "1827": 719,
     "1828": 713,
     "1829": 698,
     "1830": 707,
     "1831": 711,
     "1832": 693,
     "1833": 724,
     "1834": 744,
     "1835": 768,
     "1836": 808,
     "1837": 850,
     "1838": 858,
     "1839": 841,
     "1840": 876,
     "1841": 940,
     "1842": 924,
     "1843": 891,
     "1844": 871,
     "1845": 937,
     "1846": 901,
     "1847": 993,
     "1848": 1031,
     "1849": 1050,
     "1850": 999,
     "1851": 1150,
     "1852": 1073,
     "1853": 1227,
     "1854": 1296,
     "1855": 1377,
     "1856": 1315,
     "1857": 1523,
     "1858": 1417,
     "1859": 1384,
     "1860": 1388,
     "1861": 1307,
     "1862": 1291,
     "1863": 1416,
     "1864": 1351,
     "1865": 1340,
     "1866": 1395,
     "1867": 1391,
     "1868": 1386,
     "1869": 1556,
     "1870": 1772,
     "1871": 1847,
     "1872": 1872,
     "1873": 1858,
     "1874": 1879,
     "1875": 1721,
     "1876": 1894,
     "1877": 1731,
     "1878": 1496,
     "1879": 1262,
     "1880": 909,
     "1881": 829,
     "1882": 878,
     "1883": 804,
     "1884": 965,
     "1885": 903,
     "1886": 945,
     "1887": 873,
     "1888": 967,
     "1889": 952,
     "1890": 894,
     "1891": 952,
     "1892": 978,
     "1893": 913,
     "1894": 838,
     "1895": 887,
     "1896": 970,
     "1897": 1043,
     "1898": 1093,
     "1899": 1135,
     "1900": 1147,
     "1901": 1215,
     "1902": 1276,
     "1903": 1345,
     "1904": 1373,
     "1905": 1455,
     "1906": 1552,
     "1907": 1629,
     "1908": 1639,
     "1909": 1646,
     "1910": 1653,
     "1911": 1668,
     "1912": 1705,
     "1913": 1740,
     "1914": 1707,
     "1915": 1831,
     "1916": 1996,
     "1917": 2044,
     "1918": 2031,
     "1919": 2072,
     "1920": 2067,
     "1921": 2119,
     "1922": 2266,
     "1923": 2385,
     "1924": 2568,
     "1925": 2596,
     "1926": 2815,
     "1927": 2821,
     "1928": 2957,
     "1929": 3215,
     "1930": 2803,
     "1931": 2535,
     "1932": 2398,
     "1933": 2626,
     "1934": 2933,
     "1935": 3157,
     "1936": 3255,
     "1937": 3246,
     "1938": 3244,
     "1939": 3210,
     "1940": 3220,
     "1941": 3169,
     "1942": 3045,
     "1943": 3041,
     "1944": 3246,
     "1945": 3310,
     "1946": 3379,
     "1947": 3416,
     "1948": 3469,
     "1949": 3668,
     "1950": 3889,
     "1951": 4088,
     "1952": 4192,
     "1953": 4346,
     "1954": 4353,
     "1955": 4555,
     "1956": 4637,
     "1957": 4735,
     "1958": 4623,
     "1959": 4521,
     "1960": 5004,
     "1961": 5295,
     "1962": 5529,
     "1963": 5620,
     "1964": 5840,
     "1965": 6057,
     "1966": 6385,
     "1967": 6456,
     "1968": 6211,
     "1969": 6196,
     "1970": 6496,
     "1971": 6599,
     "1972": 6623,
     "1973": 6780,
     "1974": 6942,
     "1975": 7291,
     "1976": 7197,
     "1977": 7006,
     "1978": 6824,
     "1979": 7046,
     "1980": 7184,
     "1981": 7318,
     "1982": 7158,
     "1983": 6057,
     "1984": 6195,
     "1985": 6178,
     "1986": 6650,
     "1987": 7065,
     "1988": 6348,
     "1989": 5455,
     "1990": 5070,
     "1991": 5074,
     "1992": 4942,
     "1993": 5094,
     "1994": 5609,
     "1995": 5914,
     "1996": 5973,
     "1997": 6256,
     "1998": 6135,
     "1999": 6137,
     "2000": 6217,
     "2001": 6172,
     "2002": 6426,
     "2003": 6611,
     "2004": 6857,
     "2005": 7205,
     "2006": 7662,
     "2007": 8225,
     "2008": 8883,
     "2009": 8883,
     "2010": 9533,
     "2011": 10044,
     "2012": 10533,
     "2013": 11034,
     "2014": 11186,
     "2015": 11445,
     "2016": 11776
  },
  "Poland": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 1050,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 982,
     "1410": 984,
     "1411": 985,
     "1412": 987,
     "1413": 987,
     "1414": 991,
     "1415": 1018,
     "1416": 1030,
     "1417": 1042,
     "1418": 1053,
     "1419": 1064,
     "1420": 1076,
     "1421": 1086,
     "1422": 1096,
     "1423": 1107,
     "1424": 1121,
     "1425": 1130,
     "1426": 1118,
     "1427": 1120,
     "1428": 1121,
     "1429": 1119,
     "1430": 1118,
     "1431": 1116,
     "1432": 1112,
     "1433": 1108,
     "1434": 1104,
     "1435": 1095,
     "1436": 1086,
     "1437": 1076,
     "1438": 1063,
     "1439": 1051,
     "1440": 1042,
     "1441": 1032,
     "1442": 1023,
     "1443": 1016,
     "1444": 1009,
     "1445": 1002,
     "1446": 998,
     "1447": 993,
     "1448": 993,
     "1449": 994,
     "1450": 995,
     "1451": 996,
     "1452": 997,
     "1453": 998,
     "1454": 999,
     "1455": 999,
     "1456": 988,
     "1457": 977,
     "1458": 965,
     "1459": 953,
     "1460": 941,
     "1461": 929,
     "1462": 916,
     "1463": 904,
     "1464": 891,
     "1465": 878,
     "1466": 866,
     "1467": 867,
     "1468": 867,
     "1469": 868,
     "1470": 868,
     "1471": 867,
     "1472": 870,
     "1473": 879,
     "1474": 889,
     "1475": 898,
     "1476": 906,
     "1477": 919,
     "1478": 927,
     "1479": 932,
     "1480": 940,
     "1481": 951,
     "1482": 970,
     "1483": 965,
     "1484": 953,
     "1485": 945,
     "1486": 932,
     "1487": 928,
     "1488": 928,
     "1489": 924,
     "1490": 928,
     "1491": 928,
     "1492": 933,
     "1493": 929,
     "1494": 951,
     "1495": 971,
     "1496": 986,
     "1497": 1009,
     "1498": 1022,
     "1499": 1023,
     "1500": 1036,
     "1501": 1162,
     "1502": 1171,
     "1503": 1158,
     "1504": 1133,
     "1505": 1135,
     "1506": 1139,
     "1507": 1141,
     "1508": 1145,
     "1509": 1149,
     "1510": 1157,
     "1511": 1162,
     "1512": 1161,
     "1513": 1163,
     "1514": 1184,
     "1515": 1186,
     "1516": 1188,
     "1517": 1191,
     "1518": 1191,
     "1519": 1191,
     "1520": 1199,
     "1521": 1199,
     "1522": 1198,
     "1523": 1197,
     "1524": 1194,
     "1525": 1190,
     "1526": 1183,
     "1527": 1181,
     "1528": 1182,
     "1529": 1182,
     "1530": 1174,
     "1531": 1168,
     "1532": 1157,
     "1533": 1154,
     "1534": 1150,
     "1535": 1146,
     "1536": 1160,
     "1537": 1175,
     "1538": 1182,
     "1539": 1185,
     "1540": 1198,
     "1541": 1214,
     "1542": 1230,
     "1543": 1252,
     "1544": 1270,
     "1545": 1286,
     "1546": 1298,
     "1547": 1289,
     "1548": 1280,
     "1549": 1279,
     "1550": 1271,
     "1551": 1266,
     "1552": 1272,
     "1553": 1274,
     "1554": 1279,
     "1555": 1284,
     "1556": 1293,
     "1557": 1316,
     "1558": 1345,
     "1559": 1373,
     "1560": 1398,
     "1561": 1417,
     "1562": 1427,
     "1563": 1431,
     "1564": 1434,
     "1565": 1436,
     "1566": 1429,
     "1567": 1426,
     "1568": 1428,
     "1569": 1434,
     "1570": 1441,
     "1571": 1440,
     "1572": 1453,
     "1573": 1470,
     "1574": 1483,
     "1575": 1506,
     "1576": 1530,
     "1577": 1554,
     "1578": 1576,
     "1579": 1573,
     "1580": 1571,
     "1581": 1565,
     "1582": 1562,
     "1583": 1554,
     "1584": 1545,
     "1585": 1538,
     "1586": 1516,
     "1587": 1495,
     "1588": 1491,
     "1589": 1465,
     "1590": 1447,
     "1591": 1416,
     "1592": 1394,
     "1593": 1375,
     "1594": 1359,
     "1595": 1343,
     "1596": 1315,
     "1597": 1306,
     "1598": 1292,
     "1599": 1267,
     "1600": 1266,
     "1601": 1253,
     "1602": 1246,
     "1603": 1235,
     "1604": 1228,
     "1605": 1226,
     "1606": 1215,
     "1607": 1219,
     "1608": 1217,
     "1609": 1216,
     "1610": 1214,
     "1611": 1208,
     "1612": 1204,
     "1613": 1196,
     "1614": 1191,
     "1615": 1172,
     "1616": 1147,
     "1617": 1127,
     "1618": 1112,
     "1619": 1086,
     "1620": 1064,
     "1621": 1042,
     "1622": 1018,
     "1623": 1010,
     "1624": 1007,
     "1625": 1006,
     "1626": 1015,
     "1627": 1020,
     "1628": 1035,
     "1629": 1048,
     "1630": 1064,
     "1631": 1077,
     "1632": 1089,
     "1633": 1105,
     "1634": 1113,
     "1635": 1125,
     "1636": 1128,
     "1637": 1132,
     "1638": 1137,
     "1639": 1140,
     "1640": 1134,
     "1641": 1125,
     "1642": 1124,
     "1643": 1118,
     "1644": 1114,
     "1645": 1099,
     "1646": 1095,
     "1647": 1094,
     "1648": 1092,
     "1649": 1099,
     "1650": 1100,
     "1651": 1109,
     "1652": 1119,
     "1653": 1121,
     "1654": 1132,
     "1655": 1140,
     "1656": 1155,
     "1657": 1156,
     "1658": 1150,
     "1659": 1140,
     "1660": 1126,
     "1661": 1113,
     "1662": 1099,
     "1663": 1088,
     "1664": 1074,
     "1665": 1055,
     "1666": 1031,
     "1667": 1009,
     "1668": 991,
     "1669": 983,
     "1670": 976,
     "1671": 966,
     "1672": 958,
     "1673": 977,
     "1674": 988,
     "1675": 1005,
     "1676": 1034,
     "1677": 1074,
     "1678": 1110,
     "1679": 1162,
     "1680": 1207,
     "1681": 1234,
     "1682": 1259,
     "1683": 1274,
     "1684": 1271,
     "1685": 1278,
     "1686": 1288,
     "1687": 1294,
     "1688": 1289,
     "1689": 1283,
     "1690": 1255,
     "1691": 1230,
     "1692": 1219,
     "1693": 1213,
     "1694": 1215,
     "1695": 1218,
     "1696": 1214,
     "1697": 1203,
     "1698": 1193,
     "1699": 1196,
     "1700": 1202,
     "1701": 1211,
     "1702": 1217,
     "1703": 1211,
     "1704": 1198,
     "1705": 1201,
     "1706": 1207,
     "1707": 1205,
     "1708": 1198,
     "1709": 1188,
     "1710": 1164,
     "1711": 1134,
     "1712": 1111,
     "1713": 1094,
     "1714": 1112,
     "1715": 1132,
     "1716": 1144,
     "1717": 1157,
     "1718": 1163,
     "1719": 1177,
     "1720": 1186,
     "1721": 1197,
     "1722": 1213,
     "1723": 1228,
     "1724": 1239,
     "1725": 1228,
     "1726": 1226,
     "1727": 1217,
     "1728": 1201,
     "1729": 1192,
     "1730": 1185,
     "1731": 1169,
     "1732": 1164,
     "1733": 1163,
     "1734": 1166,
     "1735": 1164,
     "1736": 1156,
     "1737": 1148,
     "1738": 1146,
     "1739": 1140,
     "1740": 1135,
     "1741": 1137,
     "1742": 1155,
     "1743": 1220,
     "1744": 1229,
     "1745": 1240,
     "1746": 1249,
     "1747": 1267,
     "1748": 1285,
     "1749": 1300,
     "1750": 1317,
     "1751": 1336,
     "1752": 1349,
     "1753": 1355,
     "1754": 1306,
     "1755": 1305,
     "1756": 1286,
     "1757": 1272,
     "1758": 1264,
     "1759": 1254,
     "1760": 1243,
     "1761": 1233,
     "1762": 1226,
     "1763": 1211,
     "1764": 1202,
     "1765": 1195,
     "1766": 1189,
     "1767": 1191,
     "1768": 1197,
     "1769": 1199,
     "1770": 1198,
     "1771": 1191,
     "1772": 1188,
     "1773": 1189,
     "1774": 1198,
     "1775": 1210,
     "1776": 1215,
     "1777": 1204,
     "1778": 1208,
     "1779": 1218,
     "1780": 1225,
     "1781": 1224,
     "1782": 1222,
     "1783": 1209,
     "1784": 1195,
     "1785": 1181,
     "1786": 1166,
     "1787": 1169,
     "1788": 1177,
     "1789": 1171,
     "1790": 1150,
     "1791": 1133,
     "1792": 1126,
     "1793": 1123,
     "1794": 1136,
     "1795": 1148,
     "1796": 1162,
     "1797": 1169,
     "1798": 1143,
     "1799": 1127,
     "1800": 1102,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 843,
     "1812": 809,
     "1813": 829,
     "1814": 879,
     "1815": 895,
     "1816": 899,
     "1817": 910,
     "1818": 929,
     "1819": 966,
     "1820": 998,
     "1821": 1017,
     "1822": 1037,
     "1823": 1061,
     "1824": 1083,
     "1825": 1095,
     "1826": 1100,
     "1827": 1125,
     "1828": 1151,
     "1829": 1162,
     "1830": 1150,
     "1831": 1134,
     "1832": 1126,
     "1833": 1123,
     "1834": 1127,
     "1835": 1117,
     "1836": 1112,
     "1837": 1126,
     "1838": 1124,
     "1839": 1115,
     "1840": 1106,
     "1841": 1125,
     "1842": 1118,
     "1843": 1114,
     "1844": 1116,
     "1845": 1138,
     "1846": 1151,
     "1847": 1166,
     "1848": 1170,
     "1849": 1172,
     "1850": 1202,
     "1851": 1267,
     "1852": 1296,
     "1853": 1351,
     "1854": 1383,
     "1855": 1419,
     "1856": 1415,
     "1857": 1444,
     "1858": 1510,
     "1859": 1582,
     "1860": 1657,
     "1861": 1692,
     "1862": 1679,
     "1863": 1692,
     "1864": 1734,
     "1865": 1765,
     "1866": 1793,
     "1867": 1849,
     "1868": 1899,
     "1869": 1902,
     "1870": 1921,
     "1871": 1924,
     "1872": 1952,
     "1873": 2017,
     "1874": 2053,
     "1875": 2059,
     "1876": 2115,
     "1877": 2150,
     "1878": 2197,
     "1879": 2238,
     "1880": 2301,
     "1881": 2353,
     "1882": 2414,
     "1883": 2472,
     "1884": 2509,
     "1885": 2542,
     "1886": 2552,
     "1887": 2547,
     "1888": 2588,
     "1889": 2638,
     "1890": 2690,
     "1891": 2726,
     "1892": 2777,
     "1893": 2821,
     "1894": 2856,
     "1895": 2886,
     "1896": 2946,
     "1897": 3023,
     "1898": 3106,
     "1899": 3210,
     "1900": 3295,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 3287,
     "1911": 0,
     "1912": 0,
     "1913": 3383,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 4119,
     "1930": 3879,
     "1931": 3547,
     "1932": 3224,
     "1933": 3092,
     "1934": 3100,
     "1935": 3106,
     "1936": 3160,
     "1937": 3726,
     "1938": 4245,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 4760,
     "1951": 4883,
     "1952": 4904,
     "1953": 5092,
     "1954": 5281,
     "1955": 5434,
     "1956": 5570,
     "1957": 5762,
     "1958": 5946,
     "1959": 6023,
     "1960": 6253,
     "1961": 6665,
     "1962": 6498,
     "1963": 6813,
     "1964": 7045,
     "1965": 7366,
     "1966": 7764,
     "1967": 7981,
     "1968": 8398,
     "1969": 8250,
     "1970": 8613,
     "1971": 9156,
     "1972": 9745,
     "1973": 10386,
     "1974": 10894,
     "1975": 11297,
     "1976": 11468,
     "1977": 11573,
     "1978": 11887,
     "1979": 11559,
     "1980": 11165,
     "1981": 10475,
     "1982": 10287,
     "1983": 10694,
     "1984": 10991,
     "1985": 11011,
     "1986": 11275,
     "1987": 11054,
     "1988": 11261,
     "1989": 11056,
     "1990": 9947,
     "1991": 9218,
     "1992": 9428,
     "1993": 9762,
     "1994": 10248,
     "1995": 10950,
     "1996": 11604,
     "1997": 12346,
     "1998": 12911,
     "1999": 13512,
     "2000": 14132,
     "2001": 14312,
     "2002": 14611,
     "2003": 15142,
     "2004": 15929,
     "2005": 16493,
     "2006": 17523,
     "2007": 18766,
     "2008": 19560,
     "2009": 20096,
     "2010": 20805,
     "2011": 21837,
     "2012": 22188,
     "2013": 22510,
     "2014": 23266,
     "2015": 24177,
     "2016": 24838
  },
  "D.P.R. of Korea": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 245,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 247,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 355,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 624,
     "1951": 518,
     "1952": 550,
     "1953": 706,
     "1954": 740,
     "1955": 770,
     "1956": 757,
     "1957": 795,
     "1958": 813,
     "1959": 819,
     "1960": 808,
     "1961": 822,
     "1962": 820,
     "1963": 867,
     "1964": 916,
     "1965": 947,
     "1966": 1034,
     "1967": 1084,
     "1968": 1194,
     "1969": 1344,
     "1970": 1428,
     "1971": 1526,
     "1972": 1637,
     "1973": 1761,
     "1974": 1900,
     "1975": 2057,
     "1976": 2104,
     "1977": 2155,
     "1978": 2208,
     "1979": 2262,
     "1980": 2315,
     "1981": 2362,
     "1982": 2412,
     "1983": 2462,
     "1984": 2513,
     "1985": 2564,
     "1986": 2559,
     "1987": 2553,
     "1988": 2547,
     "1989": 2539,
     "1990": 2389,
     "1991": 2244,
     "1992": 2048,
     "1993": 1922,
     "1994": 1857,
     "1995": 1755,
     "1996": 1680,
     "1997": 1562,
     "1998": 1545,
     "1999": 1631,
     "2000": 1624,
     "2001": 1671,
     "2002": 1678,
     "2003": 1696,
     "2004": 1720,
     "2005": 1774,
     "2006": 1745,
     "2007": 1714,
     "2008": 1757,
     "2009": 1731,
     "2010": 1713,
     "2011": 1718,
     "2012": 1731,
     "2013": 1740,
     "2014": 1749,
     "2015": 1720,
     "2016": 0
  },
  "Portugal": {
     "1": 1050,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 1284,
     "1531": 1249,
     "1532": 1196,
     "1533": 1473,
     "1534": 1335,
     "1535": 1366,
     "1536": 1484,
     "1537": 1238,
     "1538": 1227,
     "1539": 1239,
     "1540": 1211,
     "1541": 1197,
     "1542": 1168,
     "1543": 1138,
     "1544": 1098,
     "1545": 1068,
     "1546": 1074,
     "1547": 1037,
     "1548": 1027,
     "1549": 1016,
     "1550": 1000,
     "1551": 1018,
     "1552": 1024,
     "1553": 1151,
     "1554": 1170,
     "1555": 1148,
     "1556": 1065,
     "1557": 1142,
     "1558": 1232,
     "1559": 1254,
     "1560": 1349,
     "1561": 1416,
     "1562": 1433,
     "1563": 1405,
     "1564": 1531,
     "1565": 1420,
     "1566": 1465,
     "1567": 1496,
     "1568": 1415,
     "1569": 1381,
     "1570": 1409,
     "1571": 1405,
     "1572": 1443,
     "1573": 1442,
     "1574": 1484,
     "1575": 1413,
     "1576": 1334,
     "1577": 1313,
     "1578": 1474,
     "1579": 1362,
     "1580": 1245,
     "1581": 1423,
     "1582": 1525,
     "1583": 1540,
     "1584": 1464,
     "1585": 1510,
     "1586": 1538,
     "1587": 1524,
     "1588": 1459,
     "1589": 1274,
     "1590": 1430,
     "1591": 1350,
     "1592": 1352,
     "1593": 1570,
     "1594": 1536,
     "1595": 1576,
     "1596": 1417,
     "1597": 1318,
     "1598": 1326,
     "1599": 1283,
     "1600": 1615,
     "1601": 1602,
     "1602": 1818,
     "1603": 1408,
     "1604": 1577,
     "1605": 1496,
     "1606": 1530,
     "1607": 1538,
     "1608": 1482,
     "1609": 1478,
     "1610": 1535,
     "1611": 1582,
     "1612": 1519,
     "1613": 1457,
     "1614": 1492,
     "1615": 1549,
     "1616": 1615,
     "1617": 1468,
     "1618": 1527,
     "1619": 1477,
     "1620": 1647,
     "1621": 1682,
     "1622": 1664,
     "1623": 1579,
     "1624": 1596,
     "1625": 1629,
     "1626": 1635,
     "1627": 1624,
     "1628": 1565,
     "1629": 1684,
     "1630": 1630,
     "1631": 1540,
     "1632": 1529,
     "1633": 1611,
     "1634": 1752,
     "1635": 1755,
     "1636": 1522,
     "1637": 1598,
     "1638": 1669,
     "1639": 1720,
     "1640": 1713,
     "1641": 1828,
     "1642": 1653,
     "1643": 1898,
     "1644": 1815,
     "1645": 1793,
     "1646": 1803,
     "1647": 1790,
     "1648": 1779,
     "1649": 1791,
     "1650": 1833,
     "1651": 1717,
     "1652": 1764,
     "1653": 1767,
     "1654": 1813,
     "1655": 1862,
     "1656": 1678,
     "1657": 1905,
     "1658": 1831,
     "1659": 1726,
     "1660": 1706,
     "1661": 1855,
     "1662": 1570,
     "1663": 1646,
     "1664": 1798,
     "1665": 1745,
     "1666": 1589,
     "1667": 1571,
     "1668": 1752,
     "1669": 1821,
     "1670": 1721,
     "1671": 1726,
     "1672": 1688,
     "1673": 1702,
     "1674": 1753,
     "1675": 1668,
     "1676": 1672,
     "1677": 1717,
     "1678": 1704,
     "1679": 1546,
     "1680": 1679,
     "1681": 1686,
     "1682": 1711,
     "1683": 1703,
     "1684": 1630,
     "1685": 1562,
     "1686": 1642,
     "1687": 1639,
     "1688": 1686,
     "1689": 1809,
     "1690": 1699,
     "1691": 1664,
     "1692": 1678,
     "1693": 1640,
     "1694": 1637,
     "1695": 1566,
     "1696": 1553,
     "1697": 1588,
     "1698": 1604,
     "1699": 1600,
     "1700": 1554,
     "1701": 1553,
     "1702": 1534,
     "1703": 1520,
     "1704": 1595,
     "1705": 1755,
     "1706": 1641,
     "1707": 1668,
     "1708": 1687,
     "1709": 1772,
     "1710": 1533,
     "1711": 1622,
     "1712": 1714,
     "1713": 1819,
     "1714": 1916,
     "1715": 1820,
     "1716": 1741,
     "1717": 1806,
     "1718": 1912,
     "1719": 1823,
     "1720": 2095,
     "1721": 1837,
     "1722": 1926,
     "1723": 2007,
     "1724": 1876,
     "1725": 1869,
     "1726": 1837,
     "1727": 1921,
     "1728": 1949,
     "1729": 1863,
     "1730": 2066,
     "1731": 1983,
     "1732": 2067,
     "1733": 1932,
     "1734": 2017,
     "1735": 1940,
     "1736": 2002,
     "1737": 1913,
     "1738": 2115,
     "1739": 2035,
     "1740": 1986,
     "1741": 1965,
     "1742": 2014,
     "1743": 2137,
     "1744": 2009,
     "1745": 1999,
     "1746": 2030,
     "1747": 2117,
     "1748": 1939,
     "1749": 2150,
     "1750": 2104,
     "1751": 2100,
     "1752": 2032,
     "1753": 2034,
     "1754": 2154,
     "1755": 2168,
     "1756": 2025,
     "1757": 1897,
     "1758": 1869,
     "1759": 2057,
     "1760": 1969,
     "1761": 1948,
     "1762": 1922,
     "1763": 1970,
     "1764": 1913,
     "1765": 2036,
     "1766": 1938,
     "1767": 1976,
     "1768": 1936,
     "1769": 1921,
     "1770": 1926,
     "1771": 1930,
     "1772": 1959,
     "1773": 1911,
     "1774": 1878,
     "1775": 1951,
     "1776": 1873,
     "1777": 1968,
     "1778": 1887,
     "1779": 1998,
     "1780": 1963,
     "1781": 1925,
     "1782": 1873,
     "1783": 1805,
     "1784": 1894,
     "1785": 1895,
     "1786": 2018,
     "1787": 1862,
     "1788": 1966,
     "1789": 1900,
     "1790": 1843,
     "1791": 1803,
     "1792": 1836,
     "1793": 1856,
     "1794": 1784,
     "1795": 1723,
     "1796": 1816,
     "1797": 1810,
     "1798": 1751,
     "1799": 1722,
     "1800": 1733,
     "1801": 1783,
     "1802": 1828,
     "1803": 1872,
     "1804": 1958,
     "1805": 1867,
     "1806": 1738,
     "1807": 1777,
     "1808": 1724,
     "1809": 1737,
     "1810": 1515,
     "1811": 1531,
     "1812": 1457,
     "1813": 1578,
     "1814": 1636,
     "1815": 1609,
     "1816": 1598,
     "1817": 1633,
     "1818": 1763,
     "1819": 1877,
     "1820": 1849,
     "1821": 1924,
     "1822": 1949,
     "1823": 1893,
     "1824": 1877,
     "1825": 1827,
     "1826": 1808,
     "1827": 1694,
     "1828": 1747,
     "1829": 1729,
     "1830": 1707,
     "1831": 1650,
     "1832": 1644,
     "1833": 1624,
     "1834": 1615,
     "1835": 1562,
     "1836": 1545,
     "1837": 1611,
     "1838": 1669,
     "1839": 1668,
     "1840": 1512,
     "1841": 1575,
     "1842": 1572,
     "1843": 1569,
     "1844": 1667,
     "1845": 1657,
     "1846": 1640,
     "1847": 1620,
     "1848": 1633,
     "1849": 1604,
     "1850": 1597,
     "1851": 1715,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 1590,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 1528,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 1543,
     "1866": 1592,
     "1867": 1628,
     "1868": 1636,
     "1869": 1669,
     "1870": 1687,
     "1871": 1614,
     "1872": 1651,
     "1873": 1710,
     "1874": 1672,
     "1875": 1660,
     "1876": 1612,
     "1877": 1677,
     "1878": 1668,
     "1879": 1660,
     "1880": 1639,
     "1881": 1679,
     "1882": 1717,
     "1883": 1744,
     "1884": 1789,
     "1885": 1820,
     "1886": 1904,
     "1887": 1928,
     "1888": 1934,
     "1889": 1883,
     "1890": 1952,
     "1891": 1903,
     "1892": 1881,
     "1893": 1905,
     "1894": 1867,
     "1895": 1934,
     "1896": 1947,
     "1897": 2045,
     "1898": 2101,
     "1899": 2161,
     "1900": 2253,
     "1901": 2197,
     "1902": 2190,
     "1903": 2203,
     "1904": 2213,
     "1905": 2134,
     "1906": 2129,
     "1907": 2162,
     "1908": 2110,
     "1909": 2091,
     "1910": 2125,
     "1911": 2149,
     "1912": 2174,
     "1913": 2164,
     "1914": 2176,
     "1915": 2125,
     "1916": 2135,
     "1917": 2098,
     "1918": 1989,
     "1919": 2030,
     "1920": 2127,
     "1921": 2232,
     "1922": 2474,
     "1923": 2550,
     "1924": 2425,
     "1925": 2502,
     "1926": 2456,
     "1927": 2851,
     "1928": 2544,
     "1929": 2786,
     "1930": 2718,
     "1931": 2823,
     "1932": 2842,
     "1933": 2997,
     "1934": 3087,
     "1935": 2888,
     "1936": 2635,
     "1937": 3041,
     "1938": 3024,
     "1939": 3027,
     "1940": 2795,
     "1941": 3023,
     "1942": 2956,
     "1943": 3126,
     "1944": 3275,
     "1945": 3121,
     "1946": 3336,
     "1947": 3583,
     "1948": 3541,
     "1949": 3559,
     "1950": 3610,
     "1951": 3751,
     "1952": 3740,
     "1953": 3977,
     "1954": 4142,
     "1955": 4282,
     "1956": 4437,
     "1957": 4601,
     "1958": 4624,
     "1959": 4835,
     "1960": 5115,
     "1961": 5398,
     "1962": 5763,
     "1963": 6064,
     "1964": 6435,
     "1965": 6909,
     "1966": 7206,
     "1967": 7754,
     "1968": 8433,
     "1969": 8629,
     "1970": 9471,
     "1971": 10159,
     "1972": 10998,
     "1973": 12222,
     "1974": 12196,
     "1975": 11278,
     "1976": 11792,
     "1977": 12400,
     "1978": 12702,
     "1979": 13382,
     "1980": 13920,
     "1981": 14042,
     "1982": 14328,
     "1983": 14284,
     "1984": 13997,
     "1985": 14373,
     "1986": 14953,
     "1987": 15894,
     "1988": 17076,
     "1989": 17948,
     "1990": 18735,
     "1991": 19411,
     "1992": 20034,
     "1993": 19872,
     "1994": 20113,
     "1995": 20507,
     "1996": 21144,
     "1997": 21981,
     "1998": 22919,
     "1999": 23675,
     "2000": 24400,
     "2001": 24699,
     "2002": 24753,
     "2003": 24430,
     "2004": 24813,
     "2005": 24957,
     "2006": 25299,
     "2007": 25879,
     "2008": 25893,
     "2009": 25098,
     "2010": 25563,
     "2011": 25133,
     "2012": 24218,
     "2013": 24076,
     "2014": 24423,
     "2015": 24915,
     "2016": 25346
  },
  "Romania": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 671,
     "1863": 730,
     "1864": 750,
     "1865": 575,
     "1866": 575,
     "1867": 696,
     "1868": 752,
     "1869": 680,
     "1870": 703,
     "1871": 728,
     "1872": 727,
     "1873": 772,
     "1874": 760,
     "1875": 876,
     "1876": 838,
     "1877": 857,
     "1878": 883,
     "1879": 896,
     "1880": 1116,
     "1881": 831,
     "1882": 1091,
     "1883": 1005,
     "1884": 855,
     "1885": 1028,
     "1886": 1138,
     "1887": 1059,
     "1888": 1098,
     "1889": 1100,
     "1890": 1103,
     "1891": 1129,
     "1892": 1221,
     "1893": 1179,
     "1894": 1261,
     "1895": 1250,
     "1896": 1278,
     "1897": 1060,
     "1898": 1263,
     "1899": 879,
     "1900": 1198,
     "1901": 1246,
     "1902": 1209,
     "1903": 1234,
     "1904": 936,
     "1905": 1316,
     "1906": 1414,
     "1907": 1141,
     "1908": 1225,
     "1909": 1202,
     "1910": 1527,
     "1911": 1483,
     "1912": 1494,
     "1913": 1492,
     "1914": 1255,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 905,
     "1921": 981,
     "1922": 1091,
     "1923": 1179,
     "1924": 1179,
     "1925": 1189,
     "1926": 1299,
     "1927": 1296,
     "1928": 1258,
     "1929": 1326,
     "1930": 1306,
     "1931": 1328,
     "1932": 1203,
     "1933": 1211,
     "1934": 1222,
     "1935": 1312,
     "1936": 1348,
     "1937": 1397,
     "1938": 1366,
     "1939": 1385,
     "1940": 1345,
     "1941": 1385,
     "1942": 1281,
     "1943": 1451,
     "1944": 1100,
     "1945": 746,
     "1946": 727,
     "1947": 926,
     "1948": 2533,
     "1949": 0,
     "1950": 1603,
     "1951": 2034,
     "1952": 2008,
     "1953": 2233,
     "1954": 2267,
     "1955": 2762,
     "1956": 2381,
     "1957": 2777,
     "1958": 2574,
     "1959": 2876,
     "1960": 3123,
     "1961": 3357,
     "1962": 3455,
     "1963": 3742,
     "1964": 4084,
     "1965": 4399,
     "1966": 4864,
     "1967": 5295,
     "1968": 5567,
     "1969": 5820,
     "1970": 6341,
     "1971": 6738,
     "1972": 7528,
     "1973": 8405,
     "1974": 8402,
     "1975": 8505,
     "1976": 9360,
     "1977": 9825,
     "1978": 10637,
     "1979": 10691,
     "1980": 10669,
     "1981": 10605,
     "1982": 10973,
     "1983": 11601,
     "1984": 12251,
     "1985": 12185,
     "1986": 12414,
     "1987": 12456,
     "1988": 12338,
     "1989": 11567,
     "1990": 10893,
     "1991": 9492,
     "1992": 8803,
     "1993": 8948,
     "1994": 9306,
     "1995": 9991,
     "1996": 10422,
     "1997": 10050,
     "1998": 9677,
     "1999": 9677,
     "2000": 10050,
     "2001": 10794,
     "2002": 11539,
     "2003": 12075,
     "2004": 13149,
     "2005": 13685,
     "2006": 15027,
     "2007": 16369,
     "2008": 17979,
     "2009": 16905,
     "2010": 16905,
     "2011": 17174,
     "2012": 17174,
     "2013": 17979,
     "2014": 18515,
     "2015": 19320,
     "2016": 17782
  },
  "Saudi Arabia": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 2399,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 2495,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 2878,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 10703,
     "1951": 11389,
     "1952": 11849,
     "1953": 12782,
     "1954": 13969,
     "1955": 14018,
     "1956": 14752,
     "1957": 14963,
     "1958": 15373,
     "1959": 16590,
     "1960": 17842,
     "1961": 19508,
     "1962": 21326,
     "1963": 22622,
     "1964": 24009,
     "1965": 26238,
     "1966": 29273,
     "1967": 31005,
     "1968": 32853,
     "1969": 34396,
     "1970": 36574,
     "1971": 40660,
     "1972": 45559,
     "1973": 52962,
     "1974": 59164,
     "1975": 56547,
     "1976": 58076,
     "1977": 62673,
     "1978": 61979,
     "1979": 61611,
     "1980": 63407,
     "1981": 64386,
     "1982": 61803,
     "1983": 52116,
     "1984": 49187,
     "1985": 43405,
     "1986": 38816,
     "1987": 38884,
     "1988": 38511,
     "1989": 38399,
     "1990": 43144,
     "1991": 48065,
     "1992": 48522,
     "1993": 46544,
     "1994": 45574,
     "1995": 44507,
     "1996": 44553,
     "1997": 43957,
     "1998": 44131,
     "1999": 41402,
     "2000": 42574,
     "2001": 40882,
     "2002": 38572,
     "2003": 41641,
     "2004": 43651,
     "2005": 44800,
     "2006": 44827,
     "2007": 44493,
     "2008": 46109,
     "2009": 44061,
     "2010": 45040,
     "2011": 48470,
     "2012": 49867,
     "2013": 50005,
     "2014": 50693,
     "2015": 51681,
     "2016": 51397
  },
  "Singapore": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1794,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 2780,
     "1901": 3062,
     "1902": 2895,
     "1903": 2490,
     "1904": 2407,
     "1905": 2664,
     "1906": 2994,
     "1907": 2730,
     "1908": 3136,
     "1909": 3171,
     "1910": 3445,
     "1911": 3086,
     "1912": 2640,
     "1913": 2284,
     "1914": 2730,
     "1915": 2785,
     "1916": 2302,
     "1917": 3190,
     "1918": 2494,
     "1919": 2829,
     "1920": 2437,
     "1921": 2752,
     "1922": 3635,
     "1923": 3723,
     "1924": 3642,
     "1925": 4103,
     "1926": 3387,
     "1927": 2505,
     "1928": 2976,
     "1929": 4190,
     "1930": 3845,
     "1931": 2832,
     "1932": 2465,
     "1933": 3486,
     "1934": 4113,
     "1935": 4634,
     "1936": 4604,
     "1937": 5001,
     "1938": 4240,
     "1939": 4932,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 4504,
     "1951": 4247,
     "1952": 2951,
     "1953": 3522,
     "1954": 4036,
     "1955": 3713,
     "1956": 4085,
     "1957": 3624,
     "1958": 3632,
     "1959": 4083,
     "1960": 4367,
     "1961": 4567,
     "1962": 4759,
     "1963": 5104,
     "1964": 4791,
     "1965": 5032,
     "1966": 5442,
     "1967": 5977,
     "1968": 6674,
     "1969": 7477,
     "1970": 8384,
     "1971": 9226,
     "1972": 10282,
     "1973": 11214,
     "1974": 11743,
     "1975": 12106,
     "1976": 12832,
     "1977": 13603,
     "1978": 14609,
     "1979": 15784,
     "1980": 17148,
     "1981": 18071,
     "1982": 18516,
     "1983": 19818,
     "1984": 21138,
     "1985": 20942,
     "1986": 21219,
     "1987": 23127,
     "1988": 25029,
     "1989": 26752,
     "1990": 28576,
     "1991": 29472,
     "1992": 30553,
     "1993": 33021,
     "1994": 35530,
     "1995": 36934,
     "1996": 38614,
     "1997": 40692,
     "1998": 38771,
     "1999": 40132,
     "2000": 42646,
     "2001": 41165,
     "2002": 41779,
     "2003": 42545,
     "2004": 45492,
     "2005": 47758,
     "2006": 50800,
     "2007": 54183,
     "2008": 53936,
     "2009": 52490,
     "2010": 59263,
     "2011": 61672,
     "2012": 62783,
     "2013": 64633,
     "2014": 65655,
     "2015": 65660,
     "2016": 65729
  },
  "Former USSR": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 0,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 0,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 1860,
     "1886": 1788,
     "1887": 2091,
     "1888": 2011,
     "1889": 1870,
     "1890": 1862,
     "1891": 1706,
     "1892": 1867,
     "1893": 2116,
     "1894": 2406,
     "1895": 2227,
     "1896": 2452,
     "1897": 2411,
     "1898": 2475,
     "1899": 2627,
     "1900": 2570,
     "1901": 2634,
     "1902": 2863,
     "1903": 2665,
     "1904": 2947,
     "1905": 2609,
     "1906": 2493,
     "1907": 2396,
     "1908": 2616,
     "1909": 2716,
     "1910": 2899,
     "1911": 2678,
     "1912": 2893,
     "1913": 3040,
     "1914": 2903,
     "1915": 2987,
     "1916": 2656,
     "1917": 2332,
     "1918": 1428,
     "1919": 1231,
     "1920": 1236,
     "1921": 1131,
     "1922": 1313,
     "1923": 1512,
     "1924": 1916,
     "1925": 2394,
     "1926": 2688,
     "1927": 2800,
     "1928": 2945,
     "1929": 2979,
     "1930": 3114,
     "1931": 3142,
     "1932": 3093,
     "1933": 3210,
     "1934": 3504,
     "1935": 4007,
     "1936": 4279,
     "1937": 4634,
     "1938": 4621,
     "1939": 4809,
     "1940": 4608,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 4113,
     "1947": 4570,
     "1948": 5163,
     "1949": 5639,
     "1950": 6108,
     "1951": 6032,
     "1952": 6313,
     "1953": 6476,
     "1954": 6677,
     "1955": 7122,
     "1956": 7667,
     "1957": 7687,
     "1958": 8120,
     "1959": 7888,
     "1960": 8481,
     "1961": 8809,
     "1962": 8900,
     "1963": 8567,
     "1964": 9542,
     "1965": 9961,
     "1966": 10327,
     "1967": 10669,
     "1968": 11183,
     "1969": 11233,
     "1970": 11985,
     "1971": 12183,
     "1972": 12131,
     "1973": 13026,
     "1974": 13276,
     "1975": 13188,
     "1976": 13679,
     "1977": 13875,
     "1978": 14099,
     "1979": 13914,
     "1980": 13817,
     "1981": 13828,
     "1982": 14051,
     "1983": 14376,
     "1984": 14423,
     "1985": 14421,
     "1986": 14882,
     "1987": 14945,
     "1988": 15140,
     "1989": 15288,
     "1990": 14820,
     "1991": 13812,
     "1992": 11767,
     "1993": 10596,
     "1994": 9129,
     "1995": 8651,
     "1996": 8404,
     "1997": 8583,
     "1998": 8394,
     "1999": 8802,
     "2000": 9583,
     "2001": 10205,
     "2002": 10785,
     "2003": 11645,
     "2004": 12627,
     "2005": 13535,
     "2006": 14765,
     "2007": 16108,
     "2008": 16935,
     "2009": 15862,
     "2010": 16579,
     "2011": 17391,
     "2012": 18005,
     "2013": 18447,
     "2014": 18730,
     "2015": 18479,
     "2016": 18635
  },
  "Sweden": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 1201,
     "1301": 1203,
     "1302": 1462,
     "1303": 1583,
     "1304": 1550,
     "1305": 1537,
     "1306": 1515,
     "1307": 1504,
     "1308": 1487,
     "1309": 1470,
     "1310": 1441,
     "1311": 1433,
     "1312": 1414,
     "1313": 1378,
     "1314": 1347,
     "1315": 1312,
     "1316": 1419,
     "1317": 1566,
     "1318": 1723,
     "1319": 1537,
     "1320": 1383,
     "1321": 1371,
     "1322": 1365,
     "1323": 1378,
     "1324": 1394,
     "1325": 1518,
     "1326": 1655,
     "1327": 1834,
     "1328": 2033,
     "1329": 2163,
     "1330": 1784,
     "1331": 1574,
     "1332": 1525,
     "1333": 1423,
     "1334": 1453,
     "1335": 1482,
     "1336": 1516,
     "1337": 1552,
     "1338": 1585,
     "1339": 1614,
     "1340": 1579,
     "1341": 1547,
     "1342": 1515,
     "1343": 1467,
     "1344": 1424,
     "1345": 1376,
     "1346": 1332,
     "1347": 1347,
     "1348": 1366,
     "1349": 1383,
     "1350": 1402,
     "1351": 1351,
     "1352": 1300,
     "1353": 1252,
     "1354": 1218,
     "1355": 1165,
     "1356": 1139,
     "1357": 1191,
     "1358": 1257,
     "1359": 1322,
     "1360": 1397,
     "1361": 1477,
     "1362": 1559,
     "1363": 1600,
     "1364": 1627,
     "1365": 1658,
     "1366": 1701,
     "1367": 1750,
     "1368": 1789,
     "1369": 1823,
     "1370": 1811,
     "1371": 1755,
     "1372": 1714,
     "1373": 1690,
     "1374": 1670,
     "1375": 1651,
     "1376": 1634,
     "1377": 1622,
     "1378": 1610,
     "1379": 1598,
     "1380": 1593,
     "1381": 1581,
     "1382": 1656,
     "1383": 1653,
     "1384": 1644,
     "1385": 1639,
     "1386": 1557,
     "1387": 1549,
     "1388": 1542,
     "1389": 1554,
     "1390": 1571,
     "1391": 1583,
     "1392": 1597,
     "1393": 1610,
     "1394": 1626,
     "1395": 1637,
     "1396": 1658,
     "1397": 1615,
     "1398": 1629,
     "1399": 1641,
     "1400": 1636,
     "1401": 1632,
     "1402": 1629,
     "1403": 1622,
     "1404": 1615,
     "1405": 1605,
     "1406": 1600,
     "1407": 1598,
     "1408": 1593,
     "1409": 1588,
     "1410": 1588,
     "1411": 1590,
     "1412": 1593,
     "1413": 1602,
     "1414": 1610,
     "1415": 1608,
     "1416": 1615,
     "1417": 1714,
     "1418": 1440,
     "1419": 1474,
     "1420": 1441,
     "1421": 1445,
     "1422": 1477,
     "1423": 1539,
     "1424": 1443,
     "1425": 1617,
     "1426": 1540,
     "1427": 1528,
     "1428": 1501,
     "1429": 1527,
     "1430": 1574,
     "1431": 1458,
     "1432": 1433,
     "1433": 1407,
     "1434": 1405,
     "1435": 1317,
     "1436": 1281,
     "1437": 1284,
     "1438": 1347,
     "1439": 1347,
     "1440": 1324,
     "1441": 1354,
     "1442": 1385,
     "1443": 1376,
     "1444": 1419,
     "1445": 1417,
     "1446": 1395,
     "1447": 1286,
     "1448": 1243,
     "1449": 1728,
     "1450": 1651,
     "1451": 1472,
     "1452": 1510,
     "1453": 1438,
     "1454": 1279,
     "1455": 1521,
     "1456": 2315,
     "1457": 2287,
     "1458": 1869,
     "1459": 1893,
     "1460": 1791,
     "1461": 1810,
     "1462": 1864,
     "1463": 2055,
     "1464": 1982,
     "1465": 1912,
     "1466": 1985,
     "1467": 2088,
     "1468": 2398,
     "1469": 1521,
     "1470": 1620,
     "1471": 1921,
     "1472": 1806,
     "1473": 1639,
     "1474": 1404,
     "1475": 1448,
     "1476": 1378,
     "1477": 1511,
     "1478": 1491,
     "1479": 1433,
     "1480": 1448,
     "1481": 1684,
     "1482": 1774,
     "1483": 1760,
     "1484": 1760,
     "1485": 1762,
     "1486": 1658,
     "1487": 1678,
     "1488": 1678,
     "1489": 1699,
     "1490": 1593,
     "1491": 1827,
     "1492": 1883,
     "1493": 1912,
     "1494": 1591,
     "1495": 1547,
     "1496": 1515,
     "1497": 1477,
     "1498": 1457,
     "1499": 1503,
     "1500": 1864,
     "1501": 1938,
     "1502": 1742,
     "1503": 1566,
     "1504": 2272,
     "1505": 1927,
     "1506": 2098,
     "1507": 1892,
     "1508": 2402,
     "1509": 2724,
     "1510": 2391,
     "1511": 2359,
     "1512": 2105,
     "1513": 2159,
     "1514": 1832,
     "1515": 1885,
     "1516": 1813,
     "1517": 2062,
     "1518": 1927,
     "1519": 2402,
     "1520": 2564,
     "1521": 2323,
     "1522": 2386,
     "1523": 2311,
     "1524": 1394,
     "1525": 1624,
     "1526": 1610,
     "1527": 1921,
     "1528": 2054,
     "1529": 1869,
     "1530": 1655,
     "1531": 1788,
     "1532": 1905,
     "1533": 1568,
     "1534": 1579,
     "1535": 1487,
     "1536": 1602,
     "1537": 1644,
     "1538": 1678,
     "1539": 1380,
     "1540": 1498,
     "1541": 1344,
     "1542": 1474,
     "1543": 1610,
     "1544": 1622,
     "1545": 1666,
     "1546": 1687,
     "1547": 1498,
     "1548": 1655,
     "1549": 1731,
     "1550": 1753,
     "1551": 1827,
     "1552": 1784,
     "1553": 1655,
     "1554": 1653,
     "1555": 1622,
     "1556": 1675,
     "1557": 1704,
     "1558": 1695,
     "1559": 1777,
     "1560": 1706,
     "1561": 1576,
     "1562": 1581,
     "1563": 1659,
     "1564": 1788,
     "1565": 1729,
     "1566": 1966,
     "1567": 1999,
     "1568": 1940,
     "1569": 1977,
     "1570": 1859,
     "1571": 1587,
     "1572": 1196,
     "1573": 1403,
     "1574": 1493,
     "1575": 1624,
     "1576": 1662,
     "1577": 1598,
     "1578": 1589,
     "1579": 1602,
     "1580": 1565,
     "1581": 1428,
     "1582": 1673,
     "1583": 1663,
     "1584": 1620,
     "1585": 1648,
     "1586": 1591,
     "1587": 1504,
     "1588": 1495,
     "1589": 1424,
     "1590": 1600,
     "1591": 1651,
     "1592": 1383,
     "1593": 1585,
     "1594": 1509,
     "1595": 1726,
     "1596": 1548,
     "1597": 1274,
     "1598": 1197,
     "1599": 1346,
     "1600": 1333,
     "1601": 1522,
     "1602": 1316,
     "1603": 1341,
     "1604": 1458,
     "1605": 1408,
     "1606": 1445,
     "1607": 1512,
     "1608": 1496,
     "1609": 1610,
     "1610": 1575,
     "1611": 1604,
     "1612": 1788,
     "1613": 1658,
     "1614": 1510,
     "1615": 1583,
     "1616": 1550,
     "1617": 1534,
     "1618": 1764,
     "1619": 1610,
     "1620": 1719,
     "1621": 1977,
     "1622": 1844,
     "1623": 1541,
     "1624": 1542,
     "1625": 1535,
     "1626": 1619,
     "1627": 1595,
     "1628": 1505,
     "1629": 1468,
     "1630": 1509,
     "1631": 1692,
     "1632": 1701,
     "1633": 1648,
     "1634": 1594,
     "1635": 1727,
     "1636": 1888,
     "1637": 1879,
     "1638": 1803,
     "1639": 1777,
     "1640": 1802,
     "1641": 1698,
     "1642": 1712,
     "1643": 1770,
     "1644": 1865,
     "1645": 1853,
     "1646": 1758,
     "1647": 1818,
     "1648": 1693,
     "1649": 1567,
     "1650": 1471,
     "1651": 1482,
     "1652": 1553,
     "1653": 1991,
     "1654": 2095,
     "1655": 2205,
     "1656": 1976,
     "1657": 1817,
     "1658": 1928,
     "1659": 1859,
     "1660": 1830,
     "1661": 1744,
     "1662": 1814,
     "1663": 1737,
     "1664": 1749,
     "1665": 1820,
     "1666": 1819,
     "1667": 1838,
     "1668": 1867,
     "1669": 1944,
     "1670": 1948,
     "1671": 1941,
     "1672": 1763,
     "1673": 1735,
     "1674": 1854,
     "1675": 1858,
     "1676": 1929,
     "1677": 1864,
     "1678": 1849,
     "1679": 1885,
     "1680": 1989,
     "1681": 1964,
     "1682": 1979,
     "1683": 1906,
     "1684": 1699,
     "1685": 1857,
     "1686": 1999,
     "1687": 1919,
     "1688": 1911,
     "1689": 1983,
     "1690": 1994,
     "1691": 2099,
     "1692": 2054,
     "1693": 1786,
     "1694": 1847,
     "1695": 1792,
     "1696": 1765,
     "1697": 1626,
     "1698": 1680,
     "1699": 1762,
     "1700": 2122,
     "1701": 2167,
     "1702": 2167,
     "1703": 2004,
     "1704": 2011,
     "1705": 1992,
     "1706": 1997,
     "1707": 1956,
     "1708": 1855,
     "1709": 1933,
     "1710": 2028,
     "1711": 2146,
     "1712": 2110,
     "1713": 2161,
     "1714": 2119,
     "1715": 2098,
     "1716": 1854,
     "1717": 1689,
     "1718": 1519,
     "1719": 1783,
     "1720": 2036,
     "1721": 2109,
     "1722": 1792,
     "1723": 1635,
     "1724": 1659,
     "1725": 1640,
     "1726": 1529,
     "1727": 1657,
     "1728": 1914,
     "1729": 1978,
     "1730": 1893,
     "1731": 1847,
     "1732": 1826,
     "1733": 1778,
     "1734": 1805,
     "1735": 1740,
     "1736": 1734,
     "1737": 1851,
     "1738": 1889,
     "1739": 1670,
     "1740": 1590,
     "1741": 1534,
     "1742": 1561,
     "1743": 1548,
     "1744": 1685,
     "1745": 1539,
     "1746": 1502,
     "1747": 1515,
     "1748": 1453,
     "1749": 1531,
     "1750": 1648,
     "1751": 1596,
     "1752": 1584,
     "1753": 1622,
     "1754": 1614,
     "1755": 1541,
     "1756": 1470,
     "1757": 1446,
     "1758": 1693,
     "1759": 1683,
     "1760": 1768,
     "1761": 1705,
     "1762": 1530,
     "1763": 1667,
     "1764": 1508,
     "1765": 1573,
     "1766": 1625,
     "1767": 1650,
     "1768": 1561,
     "1769": 1560,
     "1770": 1541,
     "1771": 1422,
     "1772": 1360,
     "1773": 1555,
     "1774": 1673,
     "1775": 1617,
     "1776": 1656,
     "1777": 1650,
     "1778": 1683,
     "1779": 1702,
     "1780": 1690,
     "1781": 1569,
     "1782": 1617,
     "1783": 1545,
     "1784": 1626,
     "1785": 1586,
     "1786": 1589,
     "1787": 1642,
     "1788": 1566,
     "1789": 1712,
     "1790": 1697,
     "1791": 1761,
     "1792": 1672,
     "1793": 1571,
     "1794": 1581,
     "1795": 1596,
     "1796": 1684,
     "1797": 1582,
     "1798": 1518,
     "1799": 1502,
     "1800": 1447,
     "1801": 1435,
     "1802": 1502,
     "1803": 1487,
     "1804": 1390,
     "1805": 1479,
     "1806": 1495,
     "1807": 1433,
     "1808": 1357,
     "1809": 1295,
     "1810": 1399,
     "1811": 1503,
     "1812": 1407,
     "1813": 1433,
     "1814": 1444,
     "1815": 1495,
     "1816": 1524,
     "1817": 1480,
     "1818": 1492,
     "1819": 1479,
     "1820": 1500,
     "1821": 1545,
     "1822": 1552,
     "1823": 1535,
     "1824": 1577,
     "1825": 1619,
     "1826": 1623,
     "1827": 1547,
     "1828": 1574,
     "1829": 1596,
     "1830": 1557,
     "1831": 1587,
     "1832": 1545,
     "1833": 1593,
     "1834": 1614,
     "1835": 1608,
     "1836": 1660,
     "1837": 1647,
     "1838": 1621,
     "1839": 1639,
     "1840": 1662,
     "1841": 1641,
     "1842": 1564,
     "1843": 1598,
     "1844": 1667,
     "1845": 1706,
     "1846": 1647,
     "1847": 1690,
     "1848": 1708,
     "1849": 1771,
     "1850": 1818,
     "1851": 1782,
     "1852": 1744,
     "1853": 1767,
     "1854": 1785,
     "1855": 1896,
     "1856": 1876,
     "1857": 1928,
     "1858": 1951,
     "1859": 2021,
     "1860": 2058,
     "1861": 1977,
     "1862": 1916,
     "1863": 1999,
     "1864": 2035,
     "1865": 2070,
     "1866": 2036,
     "1867": 2066,
     "1868": 1881,
     "1869": 2015,
     "1870": 2272,
     "1871": 2351,
     "1872": 2393,
     "1873": 2421,
     "1874": 2512,
     "1875": 2423,
     "1876": 2576,
     "1877": 2518,
     "1878": 2428,
     "1879": 2562,
     "1880": 2501,
     "1881": 2591,
     "1882": 2495,
     "1883": 2684,
     "1884": 2626,
     "1885": 2673,
     "1886": 2699,
     "1887": 2619,
     "1888": 2662,
     "1889": 2701,
     "1890": 2763,
     "1891": 2913,
     "1892": 2875,
     "1893": 2946,
     "1894": 2958,
     "1895": 3102,
     "1896": 3183,
     "1897": 3308,
     "1898": 3421,
     "1899": 3505,
     "1900": 3519,
     "1901": 3610,
     "1902": 3563,
     "1903": 3750,
     "1904": 3814,
     "1905": 3760,
     "1906": 4060,
     "1907": 4331,
     "1908": 4197,
     "1909": 4219,
     "1910": 4296,
     "1911": 4403,
     "1912": 4570,
     "1913": 4856,
     "1914": 4959,
     "1915": 5131,
     "1916": 5415,
     "1917": 5086,
     "1918": 4700,
     "1919": 4772,
     "1920": 5075,
     "1921": 4814,
     "1922": 5159,
     "1923": 5324,
     "1924": 5687,
     "1925": 5817,
     "1926": 6100,
     "1927": 6298,
     "1928": 6478,
     "1929": 6864,
     "1930": 7159,
     "1931": 6976,
     "1932": 6703,
     "1933": 6814,
     "1934": 7255,
     "1935": 7588,
     "1936": 7840,
     "1937": 8033,
     "1938": 8280,
     "1939": 8871,
     "1940": 8202,
     "1941": 7960,
     "1942": 8009,
     "1943": 8245,
     "1944": 8466,
     "1945": 8692,
     "1946": 9538,
     "1947": 10291,
     "1948": 10410,
     "1949": 10733,
     "1950": 11385,
     "1951": 11740,
     "1952": 11819,
     "1953": 12070,
     "1954": 12505,
     "1955": 12781,
     "1956": 13173,
     "1957": 13672,
     "1958": 13655,
     "1959": 14002,
     "1960": 14677,
     "1961": 15436,
     "1962": 15997,
     "1963": 16754,
     "1964": 17765,
     "1965": 18272,
     "1966": 18475,
     "1967": 18954,
     "1968": 19532,
     "1969": 20366,
     "1970": 21483,
     "1971": 21538,
     "1972": 21966,
     "1973": 22796,
     "1974": 23457,
     "1975": 23962,
     "1976": 24128,
     "1977": 23658,
     "1978": 24002,
     "1979": 24870,
     "1980": 25234,
     "1981": 25200,
     "1982": 25439,
     "1983": 25873,
     "1984": 26876,
     "1985": 27349,
     "1986": 27884,
     "1987": 28634,
     "1988": 29113,
     "1989": 29606,
     "1990": 29749,
     "1991": 29187,
     "1992": 28668,
     "1993": 27917,
     "1994": 28832,
     "1995": 29811,
     "1996": 30243,
     "1997": 31043,
     "1998": 32330,
     "1999": 33810,
     "2000": 35261,
     "2001": 35640,
     "2002": 36467,
     "2003": 37252,
     "2004": 38760,
     "2005": 39917,
     "2006": 41564,
     "2007": 42872,
     "2008": 42542,
     "2009": 40339,
     "2010": 42753,
     "2011": 42079,
     "2012": 41650,
     "2013": 41811,
     "2014": 42476,
     "2015": 43746,
     "2016": 44659
  },
  "Syrian Arab Republic": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 504,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 652,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 963,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 1784,
     "1951": 1677,
     "1952": 2064,
     "1953": 2285,
     "1954": 2558,
     "1955": 2252,
     "1956": 2599,
     "1957": 2687,
     "1958": 2252,
     "1959": 2269,
     "1960": 2240,
     "1961": 2347,
     "1962": 2812,
     "1963": 2721,
     "1964": 2694,
     "1965": 2602,
     "1966": 2325,
     "1967": 2438,
     "1968": 2449,
     "1969": 2816,
     "1970": 2623,
     "1971": 2785,
     "1972": 3366,
     "1973": 2976,
     "1974": 3572,
     "1975": 4127,
     "1976": 4427,
     "1977": 4226,
     "1978": 4443,
     "1979": 4452,
     "1980": 4821,
     "1981": 5105,
     "1982": 5027,
     "1983": 4917,
     "1984": 4551,
     "1985": 4660,
     "1986": 4276,
     "1987": 4208,
     "1988": 4607,
     "1989": 4060,
     "1990": 4223,
     "1991": 4543,
     "1992": 5001,
     "1993": 5223,
     "1994": 5362,
     "1995": 5498,
     "1996": 5506,
     "1997": 5296,
     "1998": 5441,
     "1999": 5138,
     "2000": 5133,
     "2001": 5214,
     "2002": 5422,
     "2003": 5218,
     "2004": 5462,
     "2005": 5654,
     "2006": 5751,
     "2007": 5859,
     "2008": 5916,
     "2009": 6123,
     "2010": 6287,
     "2011": 5979,
     "2012": 4829,
     "2013": 4124,
     "2014": 3536,
     "2015": 3049,
     "2016": 3091
  },
  "Thailand": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 795,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 848,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 1093,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 1173,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 1107,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 1153,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 1140,
     "1951": 1184,
     "1952": 1212,
     "1953": 1305,
     "1954": 1253,
     "1955": 1318,
     "1956": 1297,
     "1957": 1270,
     "1958": 1275,
     "1959": 1384,
     "1960": 1504,
     "1961": 1534,
     "1962": 1603,
     "1963": 1681,
     "1964": 1742,
     "1965": 1824,
     "1966": 1970,
     "1967": 2072,
     "1968": 2178,
     "1969": 2282,
     "1970": 2363,
     "1971": 2406,
     "1972": 2439,
     "1973": 2613,
     "1974": 2664,
     "1975": 2732,
     "1976": 2917,
     "1977": 3137,
     "1978": 3378,
     "1979": 3482,
     "1980": 3563,
     "1981": 3702,
     "1982": 3829,
     "1983": 3972,
     "1984": 4130,
     "1985": 4254,
     "1986": 4422,
     "1987": 4771,
     "1988": 5328,
     "1989": 5893,
     "1990": 6462,
     "1991": 6913,
     "1992": 7452,
     "1993": 7996,
     "1994": 8527,
     "1995": 9106,
     "1996": 9508,
     "1997": 9145,
     "1998": 8358,
     "1999": 8661,
     "2000": 8977,
     "2001": 9214,
     "2002": 9705,
     "2003": 10330,
     "2004": 10912,
     "2005": 11303,
     "2006": 11800,
     "2007": 12376,
     "2008": 12527,
     "2009": 12385,
     "2010": 13259,
     "2011": 13318,
     "2012": 14227,
     "2013": 14562,
     "2014": 14642,
     "2015": 15020,
     "2016": 15454
  },
  "Tunisia": {
     "1": 1225,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 718,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1057,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 1476,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 1863,
     "1951": 1849,
     "1952": 2039,
     "1953": 2079,
     "1954": 2087,
     "1955": 1946,
     "1956": 2045,
     "1957": 1937,
     "1958": 2158,
     "1959": 2034,
     "1960": 2244,
     "1961": 2400,
     "1962": 2305,
     "1963": 2600,
     "1964": 2656,
     "1965": 2763,
     "1966": 2765,
     "1967": 2683,
     "1968": 2900,
     "1969": 2942,
     "1970": 3053,
     "1971": 3313,
     "1972": 3823,
     "1973": 3712,
     "1974": 3916,
     "1975": 4088,
     "1976": 4294,
     "1977": 4333,
     "1978": 4514,
     "1979": 4699,
     "1980": 4920,
     "1981": 5064,
     "1982": 4943,
     "1983": 5080,
     "1984": 5127,
     "1985": 5284,
     "1986": 5076,
     "1987": 5288,
     "1988": 5180,
     "1989": 5266,
     "1990": 5575,
     "1991": 5699,
     "1992": 6044,
     "1993": 6085,
     "1994": 6202,
     "1995": 6272,
     "1996": 6608,
     "1997": 6895,
     "1998": 7151,
     "1999": 7498,
     "2000": 7738,
     "2001": 8027,
     "2002": 8079,
     "2003": 8433,
     "2004": 8844,
     "2005": 9106,
     "2006": 9527,
     "2007": 10023,
     "2008": 10365,
     "2009": 10581,
     "2010": 10746,
     "2011": 10437,
     "2012": 10739,
     "2013": 10898,
     "2014": 11040,
     "2015": 11060,
     "2016": 11073
  },
  "Turkey": {
     "1": 984,
     "730": 0,
     "1000": 844,
     "1150": 816,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 816,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 858,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 844,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 1035,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 1114,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 1146,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1210,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 1449,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 1831,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 1035,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 1242,
     "1924": 1347,
     "1925": 1490,
     "1926": 1718,
     "1927": 1551,
     "1928": 1692,
     "1929": 1931,
     "1930": 1989,
     "1931": 2073,
     "1932": 1918,
     "1933": 2136,
     "1934": 2151,
     "1935": 2160,
     "1936": 2527,
     "1937": 2566,
     "1938": 2745,
     "1939": 2888,
     "1940": 2667,
     "1941": 2400,
     "1942": 2478,
     "1943": 2226,
     "1944": 2108,
     "1945": 1804,
     "1946": 2277,
     "1947": 2350,
     "1948": 2606,
     "1949": 2418,
     "1950": 2583,
     "1951": 2856,
     "1952": 3134,
     "1953": 3411,
     "1954": 3242,
     "1955": 3424,
     "1956": 3450,
     "1957": 3629,
     "1958": 3695,
     "1959": 3765,
     "1960": 3779,
     "1961": 3755,
     "1962": 3904,
     "1963": 4194,
     "1964": 4290,
     "1965": 4327,
     "1966": 4751,
     "1967": 4882,
     "1968": 5123,
     "1969": 5302,
     "1970": 5466,
     "1971": 5859,
     "1972": 6126,
     "1973": 6275,
     "1974": 6653,
     "1975": 7108,
     "1976": 7593,
     "1977": 7794,
     "1978": 7893,
     "1979": 7709,
     "1980": 7543,
     "1981": 7706,
     "1982": 7930,
     "1983": 8064,
     "1984": 8371,
     "1985": 8646,
     "1986": 9100,
     "1987": 9739,
     "1988": 9965,
     "1989": 9932,
     "1990": 10696,
     "1991": 10649,
     "1992": 11139,
     "1993": 11884,
     "1994": 11100,
     "1995": 11756,
     "1996": 12432,
     "1997": 13215,
     "1998": 13468,
     "1999": 12626,
     "2000": 13339,
     "2001": 12145,
     "2002": 12901,
     "2003": 13449,
     "2004": 14432,
     "2005": 15416,
     "2006": 16244,
     "2007": 16771,
     "2008": 16693,
     "2009": 15506,
     "2010": 16700,
     "2011": 17919,
     "2012": 18170,
     "2013": 18788,
     "2014": 19277,
     "2015": 19836,
     "2016": 17906
  },
  "Taiwan, Province of China": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 897,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 897,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 1001,
     "1902": 1001,
     "1903": 1187,
     "1904": 1130,
     "1905": 914,
     "1906": 964,
     "1907": 1067,
     "1908": 1101,
     "1909": 1228,
     "1910": 1249,
     "1911": 1168,
     "1912": 1098,
     "1913": 1194,
     "1914": 1151,
     "1915": 1199,
     "1916": 1292,
     "1917": 1449,
     "1918": 1384,
     "1919": 1422,
     "1920": 1404,
     "1921": 1448,
     "1922": 1604,
     "1923": 1633,
     "1924": 1753,
     "1925": 1819,
     "1926": 1814,
     "1927": 1781,
     "1928": 1794,
     "1929": 1848,
     "1930": 1876,
     "1931": 1853,
     "1932": 1917,
     "1933": 1817,
     "1934": 1867,
     "1935": 2014,
     "1936": 2085,
     "1937": 2053,
     "1938": 2131,
     "1939": 2041,
     "1940": 1849,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 1355,
     "1951": 1404,
     "1952": 1521,
     "1953": 1604,
     "1954": 1694,
     "1955": 1759,
     "1956": 1787,
     "1957": 1854,
     "1958": 1908,
     "1959": 1966,
     "1960": 2002,
     "1961": 2068,
     "1962": 2159,
     "1963": 2285,
     "1964": 2484,
     "1965": 2678,
     "1966": 2834,
     "1967": 3063,
     "1968": 3234,
     "1969": 3453,
     "1970": 3753,
     "1971": 4148,
     "1972": 4606,
     "1973": 5101,
     "1974": 5063,
     "1975": 5210,
     "1976": 5797,
     "1977": 6267,
     "1978": 6978,
     "1979": 7394,
     "1980": 7782,
     "1981": 8120,
     "1982": 8271,
     "1983": 8845,
     "1984": 9648,
     "1985": 10004,
     "1986": 11062,
     "1987": 12720,
     "1988": 13164,
     "1989": 14111,
     "1990": 14703,
     "1991": 15774,
     "1992": 16920,
     "1993": 17906,
     "1994": 19082,
     "1995": 20152,
     "1996": 21229,
     "1997": 22301,
     "1998": 23043,
     "1999": 24409,
     "2000": 25761,
     "2001": 25290,
     "2002": 26563,
     "2003": 27555,
     "2004": 29239,
     "2005": 30713,
     "2006": 32289,
     "2007": 34271,
     "2008": 34394,
     "2009": 33734,
     "2010": 37253,
     "2011": 38565,
     "2012": 39207,
     "2013": 39971,
     "2014": 41472,
     "2015": 41668,
     "2016": 42165
  },
  "Uruguay": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 1807,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1949,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 2425,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 2948,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 3613,
     "1871": 3613,
     "1872": 4231,
     "1873": 4326,
     "1874": 3851,
     "1875": 3185,
     "1876": 3471,
     "1877": 3376,
     "1878": 3613,
     "1879": 3090,
     "1880": 3376,
     "1881": 3043,
     "1882": 3328,
     "1883": 3899,
     "1884": 3803,
     "1885": 4279,
     "1886": 4422,
     "1887": 3613,
     "1888": 4517,
     "1889": 4041,
     "1890": 3566,
     "1891": 3613,
     "1892": 3613,
     "1893": 3803,
     "1894": 4089,
     "1895": 3994,
     "1896": 4041,
     "1897": 3899,
     "1898": 3376,
     "1899": 3471,
     "1900": 3328,
     "1901": 3471,
     "1902": 3946,
     "1903": 3946,
     "1904": 3946,
     "1905": 3328,
     "1906": 3756,
     "1907": 3994,
     "1908": 4279,
     "1909": 4279,
     "1910": 4422,
     "1911": 4422,
     "1912": 5087,
     "1913": 4754,
     "1914": 3708,
     "1915": 3471,
     "1916": 3376,
     "1917": 3851,
     "1918": 3851,
     "1919": 4517,
     "1920": 3518,
     "1921": 3471,
     "1922": 3803,
     "1923": 4041,
     "1924": 4184,
     "1925": 4136,
     "1926": 4469,
     "1927": 4992,
     "1928": 5087,
     "1929": 5040,
     "1930": 5848,
     "1931": 4754,
     "1932": 4374,
     "1933": 4041,
     "1934": 4612,
     "1935": 4707,
     "1936": 4707,
     "1937": 4849,
     "1938": 5182,
     "1939": 5230,
     "1940": 4897,
     "1941": 5135,
     "1942": 4754,
     "1943": 4612,
     "1944": 5230,
     "1945": 5325,
     "1946": 5800,
     "1947": 5658,
     "1948": 5753,
     "1949": 6418,
     "1950": 6894,
     "1951": 7417,
     "1952": 7227,
     "1953": 7892,
     "1954": 8130,
     "1955": 8035,
     "1956": 8082,
     "1957": 8035,
     "1958": 7654,
     "1959": 7322,
     "1960": 7512,
     "1961": 7607,
     "1962": 7369,
     "1963": 7322,
     "1964": 7369,
     "1965": 7369,
     "1966": 7559,
     "1967": 7179,
     "1968": 7227,
     "1969": 7607,
     "1970": 7892,
     "1971": 7797,
     "1972": 7512,
     "1973": 7750,
     "1974": 8035,
     "1975": 8415,
     "1976": 8700,
     "1977": 8795,
     "1978": 9318,
     "1979": 9794,
     "1980": 10364,
     "1981": 10459,
     "1982": 9414,
     "1983": 8795,
     "1984": 8653,
     "1985": 8748,
     "1986": 9461,
     "1987": 10127,
     "1988": 10079,
     "1989": 10127,
     "1990": 10127,
     "1991": 10412,
     "1992": 11173,
     "1993": 11410,
     "1994": 12171,
     "1995": 11933,
     "1996": 12504,
     "1997": 13074,
     "1998": 13597,
     "1999": 13217,
     "2000": 12932,
     "2001": 12361,
     "2002": 11315,
     "2003": 11363,
     "2004": 11886,
     "2005": 12694,
     "2006": 13169,
     "2007": 13930,
     "2008": 14833,
     "2009": 15119,
     "2010": 16402,
     "2011": 17211,
     "2012": 17876,
     "2013": 18589,
     "2014": 19160,
     "2015": 19244,
     "2016": 19468
  },
  "United States": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 897,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 1375,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 1883,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 1980,
     "1801": 2017,
     "1802": 2050,
     "1803": 2015,
     "1804": 2014,
     "1805": 2056,
     "1806": 2094,
     "1807": 2114,
     "1808": 1965,
     "1809": 2062,
     "1810": 2120,
     "1811": 2163,
     "1812": 2125,
     "1813": 2149,
     "1814": 2190,
     "1815": 2180,
     "1816": 2092,
     "1817": 2091,
     "1818": 2097,
     "1819": 2092,
     "1820": 2080,
     "1821": 2113,
     "1822": 2171,
     "1823": 2147,
     "1824": 2205,
     "1825": 2254,
     "1826": 2270,
     "1827": 2279,
     "1828": 2279,
     "1829": 2204,
     "1830": 2364,
     "1831": 2509,
     "1832": 2600,
     "1833": 2691,
     "1834": 2562,
     "1835": 2660,
     "1836": 2699,
     "1837": 2614,
     "1838": 2591,
     "1839": 2717,
     "1840": 2583,
     "1841": 2529,
     "1842": 2507,
     "1843": 2547,
     "1844": 2685,
     "1845": 2728,
     "1846": 2743,
     "1847": 2816,
     "1848": 2888,
     "1849": 2814,
     "1850": 2825,
     "1851": 2940,
     "1852": 3089,
     "1853": 3300,
     "1854": 3310,
     "1855": 3237,
     "1856": 3305,
     "1857": 3233,
     "1858": 3248,
     "1859": 3336,
     "1860": 3425,
     "1861": 3354,
     "1862": 3459,
     "1863": 3683,
     "1864": 3804,
     "1865": 3607,
     "1866": 3579,
     "1867": 3697,
     "1868": 3745,
     "1869": 3845,
     "1870": 3736,
     "1871": 3825,
     "1872": 3883,
     "1873": 3980,
     "1874": 3862,
     "1875": 3971,
     "1876": 3928,
     "1877": 3966,
     "1878": 4044,
     "1879": 4446,
     "1880": 4866,
     "1881": 4914,
     "1882": 5101,
     "1883": 5102,
     "1884": 5074,
     "1885": 4997,
     "1886": 5034,
     "1887": 5147,
     "1888": 5015,
     "1889": 5216,
     "1890": 5184,
     "1891": 5299,
     "1892": 5697,
     "1893": 5316,
     "1894": 5064,
     "1895": 5569,
     "1896": 5356,
     "1897": 5761,
     "1898": 5776,
     "1899": 6191,
     "1900": 6252,
     "1901": 6822,
     "1902": 6756,
     "1903": 6955,
     "1904": 6739,
     "1905": 7094,
     "1906": 7762,
     "1907": 7740,
     "1908": 6970,
     "1909": 7668,
     "1910": 7586,
     "1911": 7711,
     "1912": 7948,
     "1913": 8101,
     "1914": 7334,
     "1915": 7434,
     "1916": 8342,
     "1917": 8020,
     "1918": 8648,
     "1919": 8681,
     "1920": 8485,
     "1921": 8134,
     "1922": 8466,
     "1923": 9420,
     "1924": 9525,
     "1925": 9601,
     "1926": 10090,
     "1927": 10050,
     "1928": 10040,
     "1929": 10543,
     "1930": 9490,
     "1931": 8864,
     "1932": 7525,
     "1933": 7270,
     "1934": 7876,
     "1935": 8850,
     "1936": 9718,
     "1937": 10450,
     "1938": 9797,
     "1939": 10459,
     "1940": 11307,
     "1941": 12844,
     "1942": 14175,
     "1943": 15392,
     "1944": 16401,
     "1945": 15992,
     "1946": 14471,
     "1947": 14057,
     "1948": 14559,
     "1949": 14112,
     "1950": 15241,
     "1951": 16126,
     "1952": 16443,
     "1953": 16917,
     "1954": 16513,
     "1955": 17370,
     "1956": 17398,
     "1957": 17407,
     "1958": 16945,
     "1959": 17901,
     "1960": 18058,
     "1961": 18175,
     "1962": 18977,
     "1963": 19515,
     "1964": 20360,
     "1965": 21390,
     "1966": 22529,
     "1967": 22842,
     "1968": 23692,
     "1969": 24196,
     "1970": 23958,
     "1971": 24395,
     "1972": 25415,
     "1973": 26603,
     "1974": 26287,
     "1975": 25956,
     "1976": 27059,
     "1977": 28001,
     "1978": 29287,
     "1979": 29951,
     "1980": 29613,
     "1981": 30056,
     "1982": 29211,
     "1983": 30159,
     "1984": 32076,
     "1985": 33024,
     "1986": 33851,
     "1987": 34730,
     "1988": 35865,
     "1989": 36757,
     "1990": 36982,
     "1991": 36464,
     "1992": 37241,
     "1993": 37762,
     "1994": 38808,
     "1995": 39391,
     "1996": 40414,
     "1997": 41723,
     "1998": 43073,
     "1999": 44576,
     "2000": 45887,
     "2001": 45878,
     "2002": 46267,
     "2003": 47158,
     "2004": 48493,
     "2005": 49655,
     "2006": 50490,
     "2007": 50902,
     "2008": 50276,
     "2009": 48453,
     "2010": 49267,
     "2011": 49675,
     "2012": 50394,
     "2013": 50863,
     "2014": 51664,
     "2015": 52591,
     "2016": 53015
  },
  "Venezuela (Bolivarian Republic of)": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 1210,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 1092,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 2168,
     "1831": 2139,
     "1832": 2416,
     "1833": 2451,
     "1834": 2476,
     "1835": 2430,
     "1836": 2259,
     "1837": 2126,
     "1838": 1909,
     "1839": 1779,
     "1840": 1714,
     "1841": 1909,
     "1842": 2126,
     "1843": 1974,
     "1844": 1974,
     "1845": 1996,
     "1846": 2018,
     "1847": 1931,
     "1848": 1931,
     "1849": 1779,
     "1850": 2126,
     "1851": 2039,
     "1852": 2126,
     "1853": 2256,
     "1854": 2300,
     "1855": 2386,
     "1856": 1844,
     "1857": 2126,
     "1858": 2083,
     "1859": 2104,
     "1860": 2235,
     "1861": 2408,
     "1862": 2126,
     "1863": 1953,
     "1864": 1909,
     "1865": 2018,
     "1866": 1996,
     "1867": 1801,
     "1868": 1822,
     "1869": 2018,
     "1870": 1996,
     "1871": 1953,
     "1872": 1974,
     "1873": 2126,
     "1874": 2343,
     "1875": 2083,
     "1876": 2169,
     "1877": 2191,
     "1878": 2343,
     "1879": 2300,
     "1880": 2430,
     "1881": 2430,
     "1882": 2386,
     "1883": 2495,
     "1884": 2647,
     "1885": 2451,
     "1886": 2603,
     "1887": 2690,
     "1888": 2690,
     "1889": 2799,
     "1890": 3016,
     "1891": 2842,
     "1892": 2560,
     "1893": 2560,
     "1894": 2517,
     "1895": 2734,
     "1896": 2408,
     "1897": 2473,
     "1898": 2408,
     "1899": 2039,
     "1900": 2083,
     "1901": 1996,
     "1902": 1866,
     "1903": 1996,
     "1904": 2018,
     "1905": 2039,
     "1906": 2018,
     "1907": 1953,
     "1908": 1909,
     "1909": 1909,
     "1910": 1887,
     "1911": 1801,
     "1912": 1931,
     "1913": 1974,
     "1914": 2278,
     "1915": 2278,
     "1916": 2126,
     "1917": 2213,
     "1918": 2300,
     "1919": 2213,
     "1920": 2148,
     "1921": 2083,
     "1922": 2256,
     "1923": 2365,
     "1924": 2451,
     "1925": 2582,
     "1926": 2734,
     "1927": 2777,
     "1928": 3232,
     "1929": 3319,
     "1930": 3298,
     "1931": 3276,
     "1932": 2972,
     "1933": 3298,
     "1934": 3428,
     "1935": 4079,
     "1936": 4404,
     "1937": 4382,
     "1938": 4838,
     "1939": 5120,
     "1940": 5380,
     "1941": 5228,
     "1942": 4512,
     "1943": 4990,
     "1944": 5380,
     "1945": 6552,
     "1946": 7506,
     "1947": 7333,
     "1948": 8700,
     "1949": 8830,
     "1950": 9546,
     "1951": 10196,
     "1952": 10500,
     "1953": 10717,
     "1954": 11303,
     "1955": 11845,
     "1956": 12561,
     "1957": 13451,
     "1958": 13104,
     "1959": 13624,
     "1960": 13668,
     "1961": 13798,
     "1962": 14492,
     "1963": 14926,
     "1964": 15815,
     "1965": 16184,
     "1966": 15989,
     "1967": 16076,
     "1968": 16379,
     "1969": 16531,
     "1970": 17247,
     "1971": 17139,
     "1972": 17074,
     "1973": 17529,
     "1974": 17985,
     "1975": 18484,
     "1976": 19373,
     "1977": 19959,
     "1978": 19720,
     "1979": 19330,
     "1980": 18354,
     "1981": 17768,
     "1982": 17399,
     "1983": 15967,
     "1984": 15338,
     "1985": 14969,
     "1986": 15512,
     "1987": 15642,
     "1988": 16141,
     "1989": 14384,
     "1990": 14948,
     "1991": 16011,
     "1992": 16618,
     "1993": 16293,
     "1994": 15577,
     "1995": 15837,
     "1996": 15490,
     "1997": 16141,
     "1998": 15880,
     "1999": 14644,
     "2000": 14904,
     "2001": 15143,
     "2002": 13537,
     "2003": 12279,
     "2004": 14275,
     "2005": 15468,
     "2006": 16727,
     "2007": 17790,
     "2008": 18332,
     "2009": 17442,
     "2010": 17204,
     "2011": 17746,
     "2012": 18549,
     "2013": 21429,
     "2014": 20317,
     "2015": 18802,
     "2016": 15219
  },
  "Viet Nam": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 0,
     "1701": 0,
     "1702": 0,
     "1703": 0,
     "1704": 0,
     "1705": 0,
     "1706": 0,
     "1707": 0,
     "1708": 0,
     "1709": 0,
     "1710": 0,
     "1711": 0,
     "1712": 0,
     "1713": 0,
     "1714": 0,
     "1715": 0,
     "1716": 0,
     "1717": 0,
     "1718": 0,
     "1719": 0,
     "1720": 0,
     "1721": 0,
     "1722": 0,
     "1723": 0,
     "1724": 0,
     "1725": 0,
     "1726": 0,
     "1727": 0,
     "1728": 0,
     "1729": 0,
     "1730": 0,
     "1731": 0,
     "1732": 0,
     "1733": 0,
     "1734": 0,
     "1735": 0,
     "1736": 0,
     "1737": 0,
     "1738": 0,
     "1739": 0,
     "1740": 0,
     "1741": 0,
     "1742": 0,
     "1743": 0,
     "1744": 0,
     "1745": 0,
     "1746": 0,
     "1747": 0,
     "1748": 0,
     "1749": 0,
     "1750": 0,
     "1751": 0,
     "1752": 0,
     "1753": 0,
     "1754": 0,
     "1755": 0,
     "1756": 0,
     "1757": 0,
     "1758": 0,
     "1759": 0,
     "1760": 0,
     "1761": 0,
     "1762": 0,
     "1763": 0,
     "1764": 0,
     "1765": 0,
     "1766": 0,
     "1767": 0,
     "1768": 0,
     "1769": 0,
     "1770": 0,
     "1771": 0,
     "1772": 0,
     "1773": 0,
     "1774": 0,
     "1775": 0,
     "1776": 0,
     "1777": 0,
     "1778": 0,
     "1779": 0,
     "1780": 0,
     "1781": 0,
     "1782": 0,
     "1783": 0,
     "1784": 0,
     "1785": 0,
     "1786": 0,
     "1787": 0,
     "1788": 0,
     "1789": 0,
     "1790": 0,
     "1791": 0,
     "1792": 0,
     "1793": 0,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 0,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 0,
     "1805": 0,
     "1806": 0,
     "1807": 0,
     "1808": 0,
     "1809": 0,
     "1810": 0,
     "1811": 0,
     "1812": 0,
     "1813": 0,
     "1814": 0,
     "1815": 0,
     "1816": 0,
     "1817": 0,
     "1818": 0,
     "1819": 0,
     "1820": 778,
     "1821": 0,
     "1822": 0,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 0,
     "1827": 0,
     "1828": 0,
     "1829": 0,
     "1830": 0,
     "1831": 0,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 0,
     "1841": 0,
     "1842": 0,
     "1843": 0,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 0,
     "1848": 0,
     "1849": 0,
     "1850": 0,
     "1851": 0,
     "1852": 0,
     "1853": 0,
     "1854": 0,
     "1855": 0,
     "1856": 0,
     "1857": 0,
     "1858": 0,
     "1859": 0,
     "1860": 0,
     "1861": 0,
     "1862": 0,
     "1863": 0,
     "1864": 0,
     "1865": 0,
     "1866": 0,
     "1867": 0,
     "1868": 0,
     "1869": 0,
     "1870": 746,
     "1871": 0,
     "1872": 0,
     "1873": 0,
     "1874": 0,
     "1875": 0,
     "1876": 0,
     "1877": 0,
     "1878": 0,
     "1879": 0,
     "1880": 0,
     "1881": 0,
     "1882": 0,
     "1883": 0,
     "1884": 0,
     "1885": 0,
     "1886": 0,
     "1887": 0,
     "1888": 0,
     "1889": 0,
     "1890": 0,
     "1891": 0,
     "1892": 0,
     "1893": 0,
     "1894": 0,
     "1895": 0,
     "1896": 0,
     "1897": 0,
     "1898": 0,
     "1899": 0,
     "1900": 0,
     "1901": 0,
     "1902": 0,
     "1903": 0,
     "1904": 0,
     "1905": 0,
     "1906": 0,
     "1907": 0,
     "1908": 0,
     "1909": 0,
     "1910": 0,
     "1911": 0,
     "1912": 0,
     "1913": 1073,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 0,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 0,
     "1925": 0,
     "1926": 0,
     "1927": 0,
     "1928": 0,
     "1929": 0,
     "1930": 0,
     "1931": 0,
     "1932": 0,
     "1933": 0,
     "1934": 0,
     "1935": 0,
     "1936": 0,
     "1937": 0,
     "1938": 0,
     "1939": 0,
     "1940": 0,
     "1941": 0,
     "1942": 0,
     "1943": 0,
     "1944": 0,
     "1945": 0,
     "1946": 0,
     "1947": 0,
     "1948": 0,
     "1949": 0,
     "1950": 971,
     "1951": 998,
     "1952": 1023,
     "1953": 1051,
     "1954": 1080,
     "1955": 1106,
     "1956": 1126,
     "1957": 1144,
     "1958": 1158,
     "1959": 1168,
     "1960": 1179,
     "1961": 1198,
     "1962": 1306,
     "1963": 1302,
     "1964": 1321,
     "1965": 1293,
     "1966": 1267,
     "1967": 1078,
     "1968": 1032,
     "1969": 1090,
     "1970": 1084,
     "1971": 1112,
     "1972": 1183,
     "1973": 1233,
     "1974": 1156,
     "1975": 1047,
     "1976": 1194,
     "1977": 1207,
     "1978": 1188,
     "1979": 1173,
     "1980": 1117,
     "1981": 1131,
     "1982": 1196,
     "1983": 1234,
     "1984": 1314,
     "1985": 1362,
     "1986": 1370,
     "1987": 1388,
     "1988": 1440,
     "1989": 1469,
     "1990": 1512,
     "1991": 1568,
     "1992": 1671,
     "1993": 1773,
     "1994": 1896,
     "1995": 2042,
     "1996": 2198,
     "1997": 2343,
     "1998": 2444,
     "1999": 2527,
     "2000": 2663,
     "2001": 2810,
     "2002": 2970,
     "2003": 3148,
     "2004": 3350,
     "2005": 3558,
     "2006": 3759,
     "2007": 3978,
     "2008": 4154,
     "2009": 4328,
     "2010": 4555,
     "2011": 4786,
     "2012": 4984,
     "2013": 5200,
     "2014": 5455,
     "2015": 5763,
     "2016": 6062
  },
  "South Africa": {
     "1": 0,
     "730": 0,
     "1000": 0,
     "1150": 0,
     "1280": 0,
     "1281": 0,
     "1282": 0,
     "1283": 0,
     "1284": 0,
     "1285": 0,
     "1286": 0,
     "1287": 0,
     "1288": 0,
     "1289": 0,
     "1290": 0,
     "1291": 0,
     "1292": 0,
     "1293": 0,
     "1294": 0,
     "1295": 0,
     "1296": 0,
     "1297": 0,
     "1298": 0,
     "1299": 0,
     "1300": 0,
     "1301": 0,
     "1302": 0,
     "1303": 0,
     "1304": 0,
     "1305": 0,
     "1306": 0,
     "1307": 0,
     "1308": 0,
     "1309": 0,
     "1310": 0,
     "1311": 0,
     "1312": 0,
     "1313": 0,
     "1314": 0,
     "1315": 0,
     "1316": 0,
     "1317": 0,
     "1318": 0,
     "1319": 0,
     "1320": 0,
     "1321": 0,
     "1322": 0,
     "1323": 0,
     "1324": 0,
     "1325": 0,
     "1326": 0,
     "1327": 0,
     "1328": 0,
     "1329": 0,
     "1330": 0,
     "1331": 0,
     "1332": 0,
     "1333": 0,
     "1334": 0,
     "1335": 0,
     "1336": 0,
     "1337": 0,
     "1338": 0,
     "1339": 0,
     "1340": 0,
     "1341": 0,
     "1342": 0,
     "1343": 0,
     "1344": 0,
     "1345": 0,
     "1346": 0,
     "1347": 0,
     "1348": 0,
     "1349": 0,
     "1350": 0,
     "1351": 0,
     "1352": 0,
     "1353": 0,
     "1354": 0,
     "1355": 0,
     "1356": 0,
     "1357": 0,
     "1358": 0,
     "1359": 0,
     "1360": 0,
     "1361": 0,
     "1362": 0,
     "1363": 0,
     "1364": 0,
     "1365": 0,
     "1366": 0,
     "1367": 0,
     "1368": 0,
     "1369": 0,
     "1370": 0,
     "1371": 0,
     "1372": 0,
     "1373": 0,
     "1374": 0,
     "1375": 0,
     "1376": 0,
     "1377": 0,
     "1378": 0,
     "1379": 0,
     "1380": 0,
     "1381": 0,
     "1382": 0,
     "1383": 0,
     "1384": 0,
     "1385": 0,
     "1386": 0,
     "1387": 0,
     "1388": 0,
     "1389": 0,
     "1390": 0,
     "1391": 0,
     "1392": 0,
     "1393": 0,
     "1394": 0,
     "1395": 0,
     "1396": 0,
     "1397": 0,
     "1398": 0,
     "1399": 0,
     "1400": 0,
     "1401": 0,
     "1402": 0,
     "1403": 0,
     "1404": 0,
     "1405": 0,
     "1406": 0,
     "1407": 0,
     "1408": 0,
     "1409": 0,
     "1410": 0,
     "1411": 0,
     "1412": 0,
     "1413": 0,
     "1414": 0,
     "1415": 0,
     "1416": 0,
     "1417": 0,
     "1418": 0,
     "1419": 0,
     "1420": 0,
     "1421": 0,
     "1422": 0,
     "1423": 0,
     "1424": 0,
     "1425": 0,
     "1426": 0,
     "1427": 0,
     "1428": 0,
     "1429": 0,
     "1430": 0,
     "1431": 0,
     "1432": 0,
     "1433": 0,
     "1434": 0,
     "1435": 0,
     "1436": 0,
     "1437": 0,
     "1438": 0,
     "1439": 0,
     "1440": 0,
     "1441": 0,
     "1442": 0,
     "1443": 0,
     "1444": 0,
     "1445": 0,
     "1446": 0,
     "1447": 0,
     "1448": 0,
     "1449": 0,
     "1450": 0,
     "1451": 0,
     "1452": 0,
     "1453": 0,
     "1454": 0,
     "1455": 0,
     "1456": 0,
     "1457": 0,
     "1458": 0,
     "1459": 0,
     "1460": 0,
     "1461": 0,
     "1462": 0,
     "1463": 0,
     "1464": 0,
     "1465": 0,
     "1466": 0,
     "1467": 0,
     "1468": 0,
     "1469": 0,
     "1470": 0,
     "1471": 0,
     "1472": 0,
     "1473": 0,
     "1474": 0,
     "1475": 0,
     "1476": 0,
     "1477": 0,
     "1478": 0,
     "1479": 0,
     "1480": 0,
     "1481": 0,
     "1482": 0,
     "1483": 0,
     "1484": 0,
     "1485": 0,
     "1486": 0,
     "1487": 0,
     "1488": 0,
     "1489": 0,
     "1490": 0,
     "1491": 0,
     "1492": 0,
     "1493": 0,
     "1494": 0,
     "1495": 0,
     "1496": 0,
     "1497": 0,
     "1498": 0,
     "1499": 0,
     "1500": 0,
     "1501": 0,
     "1502": 0,
     "1503": 0,
     "1504": 0,
     "1505": 0,
     "1506": 0,
     "1507": 0,
     "1508": 0,
     "1509": 0,
     "1510": 0,
     "1511": 0,
     "1512": 0,
     "1513": 0,
     "1514": 0,
     "1515": 0,
     "1516": 0,
     "1517": 0,
     "1518": 0,
     "1519": 0,
     "1520": 0,
     "1521": 0,
     "1522": 0,
     "1523": 0,
     "1524": 0,
     "1525": 0,
     "1526": 0,
     "1527": 0,
     "1528": 0,
     "1529": 0,
     "1530": 0,
     "1531": 0,
     "1532": 0,
     "1533": 0,
     "1534": 0,
     "1535": 0,
     "1536": 0,
     "1537": 0,
     "1538": 0,
     "1539": 0,
     "1540": 0,
     "1541": 0,
     "1542": 0,
     "1543": 0,
     "1544": 0,
     "1545": 0,
     "1546": 0,
     "1547": 0,
     "1548": 0,
     "1549": 0,
     "1550": 0,
     "1551": 0,
     "1552": 0,
     "1553": 0,
     "1554": 0,
     "1555": 0,
     "1556": 0,
     "1557": 0,
     "1558": 0,
     "1559": 0,
     "1560": 0,
     "1561": 0,
     "1562": 0,
     "1563": 0,
     "1564": 0,
     "1565": 0,
     "1566": 0,
     "1567": 0,
     "1568": 0,
     "1569": 0,
     "1570": 0,
     "1571": 0,
     "1572": 0,
     "1573": 0,
     "1574": 0,
     "1575": 0,
     "1576": 0,
     "1577": 0,
     "1578": 0,
     "1579": 0,
     "1580": 0,
     "1581": 0,
     "1582": 0,
     "1583": 0,
     "1584": 0,
     "1585": 0,
     "1586": 0,
     "1587": 0,
     "1588": 0,
     "1589": 0,
     "1590": 0,
     "1591": 0,
     "1592": 0,
     "1593": 0,
     "1594": 0,
     "1595": 0,
     "1596": 0,
     "1597": 0,
     "1598": 0,
     "1599": 0,
     "1600": 0,
     "1601": 0,
     "1602": 0,
     "1603": 0,
     "1604": 0,
     "1605": 0,
     "1606": 0,
     "1607": 0,
     "1608": 0,
     "1609": 0,
     "1610": 0,
     "1611": 0,
     "1612": 0,
     "1613": 0,
     "1614": 0,
     "1615": 0,
     "1616": 0,
     "1617": 0,
     "1618": 0,
     "1619": 0,
     "1620": 0,
     "1621": 0,
     "1622": 0,
     "1623": 0,
     "1624": 0,
     "1625": 0,
     "1626": 0,
     "1627": 0,
     "1628": 0,
     "1629": 0,
     "1630": 0,
     "1631": 0,
     "1632": 0,
     "1633": 0,
     "1634": 0,
     "1635": 0,
     "1636": 0,
     "1637": 0,
     "1638": 0,
     "1639": 0,
     "1640": 0,
     "1641": 0,
     "1642": 0,
     "1643": 0,
     "1644": 0,
     "1645": 0,
     "1646": 0,
     "1647": 0,
     "1648": 0,
     "1649": 0,
     "1650": 0,
     "1651": 0,
     "1652": 0,
     "1653": 0,
     "1654": 0,
     "1655": 0,
     "1656": 0,
     "1657": 0,
     "1658": 0,
     "1659": 0,
     "1660": 0,
     "1661": 0,
     "1662": 0,
     "1663": 0,
     "1664": 0,
     "1665": 0,
     "1666": 0,
     "1667": 0,
     "1668": 0,
     "1669": 0,
     "1670": 0,
     "1671": 0,
     "1672": 0,
     "1673": 0,
     "1674": 0,
     "1675": 0,
     "1676": 0,
     "1677": 0,
     "1678": 0,
     "1679": 0,
     "1680": 0,
     "1681": 0,
     "1682": 0,
     "1683": 0,
     "1684": 0,
     "1685": 0,
     "1686": 0,
     "1687": 0,
     "1688": 0,
     "1689": 0,
     "1690": 0,
     "1691": 0,
     "1692": 0,
     "1693": 0,
     "1694": 0,
     "1695": 0,
     "1696": 0,
     "1697": 0,
     "1698": 0,
     "1699": 0,
     "1700": 4041,
     "1701": 4040,
     "1702": 4032,
     "1703": 3840,
     "1704": 3472,
     "1705": 3349,
     "1706": 4232,
     "1707": 4682,
     "1708": 5011,
     "1709": 4554,
     "1710": 4347,
     "1711": 3197,
     "1712": 2731,
     "1713": 3069,
     "1714": 3445,
     "1715": 3399,
     "1716": 3474,
     "1717": 3611,
     "1718": 4152,
     "1719": 3308,
     "1720": 3870,
     "1721": 3885,
     "1722": 3980,
     "1723": 4212,
     "1724": 4187,
     "1725": 3436,
     "1726": 3164,
     "1727": 3304,
     "1728": 3250,
     "1729": 3499,
     "1730": 3436,
     "1731": 3663,
     "1732": 3538,
     "1733": 3256,
     "1734": 2938,
     "1735": 2947,
     "1736": 2728,
     "1737": 2962,
     "1738": 3046,
     "1739": 2879,
     "1740": 3011,
     "1741": 3330,
     "1742": 3291,
     "1743": 3640,
     "1744": 3486,
     "1745": 3623,
     "1746": 3641,
     "1747": 3917,
     "1748": 3533,
     "1749": 3576,
     "1750": 4015,
     "1751": 3936,
     "1752": 3908,
     "1753": 3812,
     "1754": 3485,
     "1755": 3653,
     "1756": 3714,
     "1757": 3223,
     "1758": 3170,
     "1759": 3037,
     "1760": 2986,
     "1761": 3083,
     "1762": 2757,
     "1763": 3216,
     "1764": 3252,
     "1765": 3313,
     "1766": 3299,
     "1767": 3240,
     "1768": 3313,
     "1769": 3343,
     "1770": 3413,
     "1771": 3398,
     "1772": 3168,
     "1773": 3812,
     "1774": 3770,
     "1775": 3617,
     "1776": 3285,
     "1777": 2715,
     "1778": 2484,
     "1779": 2421,
     "1780": 2463,
     "1781": 1857,
     "1782": 2213,
     "1783": 2342,
     "1784": 2690,
     "1785": 2891,
     "1786": 2267,
     "1787": 3158,
     "1788": 2936,
     "1789": 2480,
     "1790": 2399,
     "1791": 2423,
     "1792": 2064,
     "1793": 2275,
     "1794": 0,
     "1795": 0,
     "1796": 0,
     "1797": 0,
     "1798": 0,
     "1799": 0,
     "1800": 2276,
     "1801": 0,
     "1802": 0,
     "1803": 0,
     "1804": 2176,
     "1805": 2062,
     "1806": 2063,
     "1807": 2236,
     "1808": 1958,
     "1809": 1913,
     "1810": 2115,
     "1811": 1677,
     "1812": 1699,
     "1813": 2139,
     "1814": 2166,
     "1815": 1800,
     "1816": 1877,
     "1817": 1951,
     "1818": 2070,
     "1819": 1790,
     "1820": 1769,
     "1821": 2087,
     "1822": 1948,
     "1823": 0,
     "1824": 0,
     "1825": 0,
     "1826": 2015,
     "1827": 0,
     "1828": 0,
     "1829": 2042,
     "1830": 0,
     "1831": 1852,
     "1832": 0,
     "1833": 0,
     "1834": 0,
     "1835": 0,
     "1836": 0,
     "1837": 0,
     "1838": 0,
     "1839": 0,
     "1840": 1745,
     "1841": 0,
     "1842": 0,
     "1843": 1625,
     "1844": 0,
     "1845": 0,
     "1846": 0,
     "1847": 1396,
     "1848": 0,
     "1849": 0,
     "1850": 1552,
     "1851": 1837,
     "1852": 1927,
     "1853": 2069,
     "1854": 2011,
     "1855": 2160,
     "1856": 1740,
     "1857": 2059,
     "1858": 1802,
     "1859": 1860,
     "1860": 1927,
     "1861": 1814,
     "1862": 1336,
     "1863": 1427,
     "1864": 1992,
     "1865": 1467,
     "1866": 1525,
     "1867": 1806,
     "1868": 1826,
     "1869": 1804,
     "1870": 1916,
     "1871": 1921,
     "1872": 2830,
     "1873": 2439,
     "1874": 2324,
     "1875": 2591,
     "1876": 2897,
     "1877": 2731,
     "1878": 2666,
     "1879": 2955,
     "1880": 3414,
     "1881": 3226,
     "1882": 3056,
     "1883": 2350,
     "1884": 2129,
     "1885": 1799,
     "1886": 2124,
     "1887": 2424,
     "1888": 2733,
     "1889": 2705,
     "1890": 2725,
     "1891": 2838,
     "1892": 2769,
     "1893": 2743,
     "1894": 2665,
     "1895": 3069,
     "1896": 3205,
     "1897": 2998,
     "1898": 3599,
     "1899": 3161,
     "1900": 2224,
     "1901": 2393,
     "1902": 2857,
     "1903": 3828,
     "1904": 3827,
     "1905": 4085,
     "1906": 4411,
     "1907": 4329,
     "1908": 3398,
     "1909": 3559,
     "1910": 2731,
     "1911": 0,
     "1912": 0,
     "1913": 0,
     "1914": 0,
     "1915": 0,
     "1916": 0,
     "1917": 0,
     "1918": 2759,
     "1919": 0,
     "1920": 0,
     "1921": 0,
     "1922": 0,
     "1923": 0,
     "1924": 3032,
     "1925": 3232,
     "1926": 3316,
     "1927": 3381,
     "1928": 3638,
     "1929": 3553,
     "1930": 3353,
     "1931": 3167,
     "1932": 3047,
     "1933": 3376,
     "1934": 3742,
     "1935": 4144,
     "1936": 4537,
     "1937": 4836,
     "1938": 4642,
     "1939": 4872,
     "1940": 5089,
     "1941": 5225,
     "1942": 5282,
     "1943": 5295,
     "1944": 5375,
     "1945": 5406,
     "1946": 5484,
     "1947": 5428,
     "1948": 5728,
     "1949": 5685,
     "1950": 6015,
     "1951": 6148,
     "1952": 6214,
     "1953": 6347,
     "1954": 6557,
     "1955": 6715,
     "1956": 6913,
     "1957": 7002,
     "1958": 6973,
     "1959": 7106,
     "1960": 7216,
     "1961": 7335,
     "1962": 7542,
     "1963": 7880,
     "1964": 8186,
     "1965": 8445,
     "1966": 8577,
     "1967": 8922,
     "1968": 9061,
     "1969": 9363,
     "1970": 9598,
     "1971": 9811,
     "1972": 9750,
     "1973": 9906,
     "1974": 10201,
     "1975": 10133,
     "1976": 10124,
     "1977": 9859,
     "1978": 9904,
     "1979": 10040,
     "1980": 10416,
     "1981": 10631,
     "1982": 10257,
     "1983": 9756,
     "1984": 9932,
     "1985": 9506,
     "1986": 9281,
     "1987": 9247,
     "1988": 9405,
     "1989": 9386,
     "1990": 9096,
     "1991": 8815,
     "1992": 8459,
     "1993": 8390,
     "1994": 8519,
     "1995": 8674,
     "1996": 8937,
     "1997": 9061,
     "1998": 8986,
     "1999": 9081,
     "2000": 9340,
     "2001": 9475,
     "2002": 9690,
     "2003": 9834,
     "2004": 10137,
     "2005": 10524,
     "2006": 10970,
     "2007": 11409,
     "2008": 11619,
     "2009": 11293,
     "2010": 11535,
     "2011": 11838,
     "2012": 11992,
     "2013": 12171,
     "2014": 12242,
     "2015": 12246,
     "2016": 12139
  }
}
